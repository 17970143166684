import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getVideos } from "../utils/queries";
import { Link } from "react-router-dom";
import logo from "../assets/images/tlp-logo-white.png";
import { capitalizeFirstLetter } from "../helpers/capitalize";

const Search = () => {
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [enabled, setEnabled] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["videos_search", searchTerm],
    queryFn: () => getVideos(searchTerm),
    enabled,
  });

  return (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-search">
                <div className="flq-fancybox-body pb-6">
                  <div className="container-small">
                    <div className="flq-search-content">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setSearchTerm(search);
                          setEnabled(true);
                        }}
                      >
                        <input
                          className="form-control form-control-lg flq-form-glass flq-search-input"
                          type="text"
                          placeholder="Type to Search"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        {isLoading ? (
                          <button
                            className="btn btn-link btn-icon-md flq-search-btn"
                            type="button"
                            disabled
                          >
                            <svg
                              width={22}
                              height={22}
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19.25 19.25L15.2625 15.2625"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        ) : (
                          <button
                            className="btn btn-link btn-icon-md flq-search-btn"
                            type="submit"
                          >
                            <svg
                              width={22}
                              height={22}
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M19.25 19.25L15.2625 15.2625"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </form>
                    </div>

                    {isLoading && <div className="loader" />}
                    {data && (
                      <>
                        {data.videos.map((video) => (
                          <div
                            className="col-12 pt-1"
                            data-sr-item="account-favorites"
                            key={video._id}
                          >
                            <div className="flq-account-favorite">
                              <div className="flq-media">
                                <Link
                                  to={`/video/${video.videoId}`}
                                  className="flq-media-image"
                                >
                                  <span className="flq-image">
                                    <img src={video.poster} alt="poster" />
                                  </span>
                                </Link>
                                <div className="flq-media-meta">
                                  <h5 className="flq-media-title">
                                    <Link
                                      to={`/video/${video.videoId}`}
                                      style={{ fontSize: "14px" }}
                                    >
                                      {video.title}
                                    </Link>
                                  </h5>
                                  <div className="flq-meta">
                                    <ul style={{ fontSize: "12px" }}>
                                      <li>
                                        {capitalizeFirstLetter(video.category)}
                                      </li>
                                      <li>{video.duration}m</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
