import { Link } from "react-router-dom";
import logo from "../assets/images/tlp-logo-white.png";

const NavbarUser = ({ page }: { page: string }) => {
  return (
    <nav className="flq-navbar-mobile fancybox-content" id="flq_popup_user">
      <div className="flq-fancybox-head">
        <div className="container-fluid">
          <Link to="/" className="flq-fancybox-brand me-auto">
            <img src={logo} className="flq-logo" alt="logo" />
          </Link>
          <button
            className="flq-fancybox-close btn btn-link ms-4"
            data-fancybox-close
          >
            <span />
          </button>
        </div>
      </div>
      <div className="container pt-4 pb-6">
        <div className="flq-fancybox-body row gy-6 gx-6">
          <div className="col-12 col-lg">
            <ul className="nav flex-column flq-navbar-nav accordion gy-3">
              <li
                className={`${
                  page === "Profile" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/account-profile" className="nav-link">
                  <span className="nav-link-name">Profile</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Favorites" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/account-favorites" className="nav-link">
                  <span className="nav-link-name">Favorites</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Questions" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/account-questions" className="nav-link">
                  <span className="nav-link-name">Questions &amp; Reviews</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Account Edit" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/account-edit" className="nav-link">
                  <span className="nav-link-name">Account Edit</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Subscription" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/account-subscription" className="nav-link">
                  <span className="nav-link-name">Subscription</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarUser;
