import logo from "../assets/images/tlp-logo-white.png";

const LectureTapes = () => {
  return (
    <div
      className="container my-7"
      data-sr="online-streaming"
      data-sr-interval={100}
      data-sr-duration={1000}
      data-sr-distance={10}
    >
      <div className="text-center mb-5">
        <span className="flq-subtitle badge" data-sr-item="online-streaming">
          Lecture Tapes
        </span>
        <h2 data-sr-item="online-streaming">Grab your copy</h2>
      </div>
      <div className="row gy-5 justify-content-center">
        <div
          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          data-sr-item="online-streaming"
        >
          <div className="card flq-card-blog">
            <div className="card-img-wrap">
              <a href="https://paystack.com/pay/1z8av2-3a1">
                <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                  <img src={logo} alt="" style={{ objectFit: "contain" }} />
                </span>
                <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">
                  SALE
                </span>
              </a>
            </div>
            <div className="card-body">
              <h5 className="card-title h6">
                <a href="https://paystack.com/pay/1z8av2-3a1">
                  Concept of Superimposition
                </a>
              </h5>
              <div className="flq-meta flq-meta-sm">
                <ul>
                  {/* <li>
                        <a href="#/" className="card-year">
                          $25
                        </a>
                      </li> */}
                  <li>
                    <a href="#/" className="card-category">
                      &#8358;20,000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          data-sr-item="online-streaming"
        >
          <div className="card flq-card-blog">
            <div className="card-img-wrap">
              <a href="https://paystack.com/pay/pehjo-ve4-">
                <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                  <img src={logo} alt="" style={{ objectFit: "contain" }} />
                </span>
                <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">
                  SALE
                </span>
              </a>
            </div>
            <div className="card-body">
              <h5 className="card-title h6">
                <a href="https://paystack.com/pay/pehjo-ve4-">
                  Understanding upper structures
                </a>
              </h5>
              <div className="flq-meta flq-meta-sm">
                <ul>
                  {/* <li>
                        <a href="#/" className="card-year">
                          $25
                        </a>
                      </li> */}
                  <li>
                    <a href="#/" className="card-category">
                      &#8358;20,000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          data-sr-item="online-streaming"
        >
          <div className="card flq-card-blog">
            <div className="card-img-wrap">
              <a href="https://paystack.com/pay/nw79e9lysa">
                <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                  <img src={logo} alt="" style={{ objectFit: "contain" }} />
                </span>
                <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">
                  SALE
                </span>
              </a>
            </div>
            <div className="card-body">
              <h5 className="card-title h6">
                <a href="https://paystack.com/pay/nw79e9lysa">
                  Turning melodies into voicings
                </a>
              </h5>
              <div className="flq-meta flq-meta-sm">
                <ul>
                  {/* <li>
                        <a href="#/" className="card-year">
                          $20
                        </a>
                      </li> */}
                  <li>
                    <a href="#/" className="card-category">
                      &#8358;15,000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          data-sr-item="online-streaming"
        >
          <div className="card flq-card-blog">
            <div className="card-img-wrap">
              <a href="https://paystack.com/pay/hgbrrbke-j">
                <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                  <img src={logo} alt="" style={{ objectFit: "contain" }} />
                </span>
                <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">
                  SALE
                </span>
              </a>
            </div>
            <div className="card-body">
              <h5 className="card-title h6">
                <a href="https://paystack.com/pay/hgbrrbke-j">
                  Understanding chord substitutions
                </a>
              </h5>
              <div className="flq-meta flq-meta-sm">
                <ul>
                  {/* <li>
                        <a href="#/" className="card-year">
                          $20
                        </a>
                      </li> */}
                  <li>
                    <a href="#/" className="card-category">
                      &#8358;15,000
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LectureTapes;
