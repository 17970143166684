import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistor, store } from "./store/redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
          <App />
          <ToastContainer
            autoClose={3800}
            closeButton={false}
            theme="colored"
            hideProgressBar
            position="bottom-right"
          />
        </QueryClientProvider>
      </Router>
    </PersistGate>
  </Provider>
);
