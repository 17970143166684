const subscriptionCategories = {
  FREE: "free",
  BEGINNER: "beginner",
  INTERMEDIATE: "intermediate",
  INTERMEDIATE_PRO: "intermediate-pro",
  PROFESSIONAL: "professional",
  PROFESSIONAL_PRO: "professional-pro",
};

const accessLevels = {
  [subscriptionCategories.FREE]: [],
  [subscriptionCategories.BEGINNER]: ["beginner", "special"],
  [subscriptionCategories.INTERMEDIATE]: ["intermediate", "special"],
  [subscriptionCategories.INTERMEDIATE_PRO]: [
    "beginner",
    "intermediate",
    "special",
  ],
  [subscriptionCategories.PROFESSIONAL]: ["professional", "special"],
  [subscriptionCategories.PROFESSIONAL_PRO]: [
    "beginner",
    "intermediate",
    "professional",
    "special",
  ],
};

export const hasAccessToCategory = (
  userSubscription: string,
  videoCategory: string
) => {
  const allowedCategories = accessLevels[userSubscription] || [];
  return allowedCategories.includes(videoCategory);
};
