import Footer from "../components/Footer";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarUser from "../components/NavbarUser";
import NavbarTop from "../components/NavbarTop";

const PrivacyPolicy = () => {
  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Privacy Policy" />
      <div className="py-7 flq-background-color-100">
        <div className="container pt-navbar">
          <h1 className="display-3 mb-0">Privacy Policy</h1>
        </div>
      </div>
      <div className="content-wrap">
        <div className="container py-7">
          <p className="mb-2">
            This privacy policy ("policy") will help you understand how ‘Think
            Like a Pianist’ (TLP, we, us, our) uses and protects the data you
            provide to us when you visit and use Thinklikeapianist website
            ("website", "service").
          </p>
          <p className="mb-2">
            We reserve the right to change this policy at any given time, of
            which you will be promptly updated. In order to make sure that you
            are up to date with the latest changes, we advise you to frequently
            visit this page.
          </p>
          <p className="mb-5">
            PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE
            HANDLE YOUR INFORMATION. IF YOU DO NOT AGREE WITH THIS POLICY,
            PLEASE DON’T USE THE SERVICES.
          </p>

          <h2 className="mb-3">WHAT USER DATA WE COLLECT</h2>
          <p className="mb-0">
            When you visit the website, we may collect the following data:
          </p>
          <ul className="mb-5">
            <li>Name and passwords etc.</li>
            <li>Your contact information and email address.</li>
          </ul>

          <h2 className="mb-3">WHY WE COLLECT YOUR DATA</h2>
          <p className="mb-0">
            We collect your data for the following reasons:
          </p>
          <ul className="mb-5">
            <li>To better understand your needs.</li>
            <li>To improve our services and products.</li>
            <li>
              To send you promotional emails containing the information we think
              you will find interesting.
            </li>
            <li>
              To contact you to fill out surveys and participate in other types
              of market research.
            </li>
            <li>
              To customize our website and user experience according to your
              online behavior and personal preferences.
            </li>
          </ul>

          <h2 className="mb-3">SAFEGUARDING AND SECURING YOUR DATA</h2>
          <p className="mb-5">
            Thinklikeapianist is committed to securing your data and keeping it
            confidential. Thinklikeapianist has done and will continue to do all
            in its power to prevent data theft, unauthorized access, and
            disclosure by implementing the latest technologies and software
            which help us safeguard all the information we collect online. A
            user’s data is saved in the user’s browser storage to fetch details
            and improve user experience.
          </p>

          <h2 className="mb-3">
            RESTRICTING THE COLLECTION OF YOUR PERSONAL DATA
          </h2>
          <p className="mb-0">
            At some point, you might wish to restrict the use and collection of
            your personal data. You can achieve this by doing the following:
          </p>
          <ul className="mb-3">
            <li>
              While filling forms on the website, be sure to check if there is a
              box which you can leave unchecked if you don't wish to disclose
              your personal information.
            </li>
            <li>
              If you have already agreed to share your information with us, feel
              free to contact us via email and we will be more than happy to
              change this for you.
            </li>
            <li>
              To send you promotional emails containing the information we think
              you will find interesting.
            </li>
            <li>
              To contact you to fill out surveys and participate in other types
              of market research.
            </li>
            <li>
              To customize our website and user experience according to your
              online behavior and personal preferences.
            </li>
          </ul>
          <p className="mb-5">
            Thinklikeapianist will not lease, sell or distribute your personal
            information to any third parties, unless we have your permission. We
            might do so if the law forces us to. Your personal information will
            be used when we need to send you promotional materials if you agree
            to this privacy policy.
          </p>

          <h2 className="mb-3">HOW WE SHARE AND DISCLOSE YOUR INFORMATION</h2>
          <p className="mb-5">
            We provide access to or share your information with select third
            parties who perform services on our behalf. They have access to
            perform these services but are prohibited from using your
            information for other purposes. They provide a variety of services
            to us, including billing, sales, marketing, product content and
            features, advertising, analytics, research, customer service, data
            storage, security, fraud prevention, payment processing, and legal
            services.
          </p>

          <h2 className="mb-3">PROTECTION OF THINKLIKEAPIANIST AND OTHERS</h2>
          <p className="mb-0">
            By using the services, you acknowledge and agree that we may access,
            retain and disclose the information we collect and maintain about
            you if required to do so by law or in a good faith belief that such
            access, retention or disclosure is reasonably necessary to:
          </p>
          <ul className="mb-5">
            <li>comply with legal process (e.g. a court order)</li>
            <li>
              enforce our Terms of Service, this Privacy Policy or other
              contracts with you, including investigation of potential
              violations thereof.
            </li>
            <li>
              respond to claims that any content violates the rights of third
              parties.
            </li>
            <li>respond to your requests for customer service</li>
            <li>
              protect the rights, property or personal safety of
              Thinklikeapianist, its agents and affiliates, its users and/or the
              public. This includes exchanging information with other companies
              and organizations for fraud protection, spam/malware prevention
              and similar purposes.
            </li>
          </ul>

          <h2 className="mb-3">PUBLIC FORUMS</h2>
          <p className="mb-5">
            Certain features of our Services make it possible for you to share
            comments publicly with other users. Any information that you submit
            through such features is not confidential and we may use it for any
            purpose (including in testimonials or other marketing materials).
            Any information you post openly in these ways will be available to
            the public at large and potentially accessible through third-party
            search engines. Accordingly, please take care when using these
            features.
          </p>

          <h2 className="mb-3">RETENTION OF YOUR INFORMATION</h2>
          <p className="mb-5">
            We keep your information for no longer than necessary for the
            purposes for which it is processed. The length of time for which we
            retain information depends on the purposes for which we collected
            and use it and/or as required to comply with applicable laws.
          </p>

          <h2 className="mb-3">HOW WE PROTECT YOUR INFORMATION</h2>
          <p className="mb-5">
            Thinklikeapianist takes technical and organizational security
            measures to protect the information provided via the services from
            loss, misuse and unauthorized access, disclosure, alteration or
            destruction. However, no internet or email transmission is ever
            fully secure or error free. Please keep this in mind when disclosing
            any information to Thinklikeapianist via the internet.
          </p>

          <h2 className="mb-3">THIRD PARTY LINKS AND ANALYTICS</h2>
          <p className="mb-5">
            The services contain links to third-party websites such as social
            media sites and also contain third-party plug-ins. We are not
            responsible for the content or practices of those websites or
            services. The collection, use and disclosure of your information
            will be subject to the privacy policies of the third party websites
            or services, and not this Privacy Policy. We urge you to read the
            privacy and security policies of these third-parties. We also use
            third-party analytics to track web traffic, user conversions etc. to
            help us serve our users better and provide good customized user
            experiences.
          </p>

          <h2 className="mb-3">DATA SUBJECT RIGHTS AND CHOICES</h2>
          <p className="mb-2">
            Depending on your jurisdiction of residence, you may have certain
            rights with respect to your information as further described in this
            section.
          </p>
          <p className="mb-0">1. Your legal rights</p>
          <p className="mb-0">
            If you would like further information in relation to your legal
            rights under applicable law or would like to exercise any of them,
            please contact us using the information in the “Contact Information"
            section below at any time. Your local laws may permit you to request
            that we:
          </p>
          <ul>
            <li>
              provide access to and/or a copy of certain information we hold
              about you.
            </li>
            <li>
              prevent the processing of your information for direct marketing
              purposes (including any direct marketing process based on
              profiling).
            </li>
            <li>update information which is outdated or incorrect.</li>
            <li>delete certain information we hold about you.</li>
            <li>
              restrict the way we process and disclose some of your information.
            </li>
            <li>
              transfer your information to a third-party provider of services.
            </li>
            <li>revoke your consent for the processing of your information.</li>
          </ul>
          <p className="mb-3">
            We will consider all requests and provide our response within the
            time period stated by applicable law. Please note, however, that
            certain information may be exempt from such requests in some
            circumstances, which may include if we need to keep processing your
            information for our legitimate interests or to comply with a legal
            obligation. We may request you provide us with information necessary
            to confirm your identity before responding to your request.
          </p>

          <p className="mb-0">2. Marketing communication and sharing</p>
          <p className="mb-5">
            You may instruct us not to use your contact information to contact
            you by email, postal mail, or phone regarding products, services,
            promotions and special events that might appeal to your interests by
            contacting us using the information in the “Contact Information"
            section below. In commercial email messages, you can also opt out by
            following the instructions located at the bottom of such emails.
            Please note that, regardless of your request, we may still use and
            share certain information as permitted by applicable law. For
            example, you may not opt out of certain operational emails, such as
            those reflecting our relationship or transactions with you or
            important notifications regarding classes in which you are enrolled.
          </p>

          <h2 className="mb-3">LEGAL BASES FOR USE OF YOUR INFORMATION</h2>
          <p className="mb-0">
            The legal bases for using your information as set out in this
            Privacy Policy are as follows:
          </p>
          <ul className="mb-5">
            <li>
              Where use of your information is necessary to perform our
              obligations under a contract with you (for example, to comply
              with: the Terms of Service of our website which you accept by
              browsing the websites/registering and/or our contract to provide
              our services to you).
            </li>
            <li>
              Where use of your information is necessary for our legitimate
              interests or the legitimate interests of others (for example, to
              provide security for our website and applications, operate our
              business and our services, make and receive payments, comply with
              legal requirements and defend our legal rights, prevent fraud and
              to know the customer to whom we are providing services to).
            </li>
            <li>
              Where we have your consent, in accordance with applicable law..
            </li>
          </ul>

          <h2 className="mb-3">CHANGES TO OUR PRIVACY POLICY</h2>
          <p className="mb-5">
            We reserve the right to amend this Privacy Policy at any time to
            reflect changes in the law, our data collection and use practices,
            the features of our services or advances in technology. We will make
            the revised Privacy Policy accessible through the services, so you
            should review the Privacy Policy periodically. If we make a material
            change to the Policy, you will be provided with appropriate notice
            in accordance with legal requirements. By continuing to use the
            services, you are confirming that you have read and understood the
            latest version of this Privacy Policy.
          </p>

          <h2 className="mb-3">CONTACT INFORMATION</h2>
          <p className="mb-5">
            Please feel free to contact us if you have any questions about
            Thinklikeapianist's Privacy Policy or the information practices of
            the services. You may contact us as follows:{" "}
            <a href="mailto:contact@thinklikeapianist.com">
              contact@thinklikeapianist.com
            </a>
            . We keep your information for no longer than necessary for the
            purposes for which it is processed. The length of time for which we
            retain information depends on the purposes for which we collected
            and use it and/or as required to comply with applicable laws.
          </p>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Privacy Policy" />
      <NavbarUser page="Privacy Policy" />
    </div>
  );
};

export default PrivacyPolicy;
