import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { logoutUser } from "../store/redux/userRedux";

let axiosInstance: AxiosInstance | null = null;

export const getAxiosInstance = (): AxiosInstance => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
  return axiosInstance;
};

export const getAuthenticatedInstance = () => {
  const token = localStorage.getItem("tlp-access-token");

  const instance = getAxiosInstance();
  instance.defaults.headers.common["token"] = `Bearer ${token}`;

  return instance;
};

interface AuthApiParams {
  method: AxiosRequestConfig["method"];
  url: string;
  data?: unknown;
  params?: Record<string, unknown>;
}

const refreshAccessToken = async () => {
  const token = localStorage.getItem("tlp-refresh-token");

  if (!token) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
      {
        token,
      }
    );

    const { accessToken, refreshToken } = response.data.data;

    // Update localStorage with the new access token
    localStorage.setItem("tlp-access-token", accessToken);
    localStorage.setItem("tlp-refresh-token", refreshToken);

    // Update Axios instance with the new access token
    const instance = getAxiosInstance();
    instance.defaults.headers.common["token"] = `Bearer ${accessToken}`;

    return accessToken;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const api = async <T>({ method, url, data, params }: AuthApiParams) => {
  const axiosInstance = getAuthenticatedInstance();

  try {
    const response: AxiosResponse = await axiosInstance({
      method,
      url,
      data,
      params,
    });

    return response.data as T;
  } catch (error: any) {
    if (error.response.status === 401) {
      try {
        await refreshAccessToken();

        // Retry the original request
        const response: AxiosResponse<T> = await axiosInstance({
          method,
          url,
          data,
          params,
        });

        return response.data as T;
      } catch (refreshError: any) {
        if (
          refreshError.message === "Token has expired. Please log in again."
        ) {
          localStorage.clear();
          logoutUser();
          window.location.href = "/login";
        }
      }
    } else {
      throw new Error(error.response?.data?.message || error.message);
    }
  }
};
