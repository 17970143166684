import { Link } from "react-router-dom";
import bg from "../assets/images/footer-bg.jpg";
import logo from "../assets/images/tlp-logo-white.png";
import { AiFillYoutube } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer
      className="flq-footer flq-background py-7"
      data-sr="footer"
      data-sr-interval={60}
      data-sr-duration={1000}
      data-sr-distance={10}
    >
      <div className="flq-background-image">
        <span
          className="flq-image jarallax"
          data-speed="0.7"
          style={{ zIndex: 0 }}
        >
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              zIndex: -100,
            }}
          >
            <img
              src={bg}
              className="jarallax-img"
              alt=""
              style={{
                objectFit: "cover",
                objectPosition: "center",
                // maxWidth: "none",
                position: "absolute",
                top: "0px",
                left: "0px",
                right: "0px",
                height: "912.452px",
                overflow: "hidden",
                pointerEvents: "none",
                transformStyle: "preserve-3d",
                backfaceVisibility: "hidden",
                willChange: "transform, opacity",
                marginTop: "-9.72617px",
                transform: "translate3d(0px, -136.721px, 0px)",
              }}
            />
          </div>
        </span>
      </div>
      <div
        className="flq-background-overlay"
        style={{ backgroundColor: "hsla(var(--flq-color-background), .8)" }}
      />
      <div className="container">
        <div className="row gx-5 gy-5 mb-6 justify-content-center justify-content-sm-start">
          <div
            className="col-md-9 col-lg-3 text-center text-sm-start"
            data-sr-item="footer"
            style={{
              visibility: "visible",
              opacity: 1,
              transform:
                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
              transition:
                "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
            }}
          >
            <Link to="/">
              <img src={logo} className="flq-logo" alt="logo" />
            </Link>
            <p className="mt-1 pt-1">
              Take advantage of our wide range of classes from beginner to
              professional to help you learn and grow as a pianist.
            </p>
          </div>
          <div className="col-lg-1 col-xl d-none d-lg-block" />
          <div className="col-12 col-md-4 col-lg col-xl-2">
            <nav>
              <ul className="nav flex-column gy-3">
                <li
                  className="nav-item"
                  data-sr-item="footer"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  <Link to="/faq" className="nav-link">
                    FAQ's
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-sr-item="footer"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  <Link to="/terms-of-use" className="nav-link">
                    Terms of Use
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-sr-item="footer"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  <Link to="/privacy-policy" className="nav-link">
                    Privacy Policy
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-sr-item="footer"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  <Link to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
          className="row gy-4 justify-content-between"
          data-sr="footer-copyright"
          data-sr-interval={100}
          data-sr-delay={200}
          data-sr-duration={1000}
          data-sr-distance={10}
        >
          <div
            className="col-12 col-sm-auto text-center text-sm-start"
            data-sr-item="footer-copyright"
            style={{
              visibility: "visible",
              opacity: 1,
              transform:
                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
              transition:
                "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
            }}
          >
            <p>© {new Date().getFullYear()} Think Like a Pianist</p>
          </div>
          <div
            className="col-12 col-sm-auto text-center text-sm-start"
            data-sr-item="footer-copyright"
            style={{
              visibility: "visible",
              opacity: 1,
              transform:
                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
              transition:
                "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
            }}
          >
            <div className="flq-social text-center">
              <ul className="gy-4 gx-4">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100092569717603"
                    target="_blank"
                    rel="noreferrer"
                    className="flq-social-link"
                    data-sr-item="footer-copyright"
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "color 0.15s ease-in-out 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
                    }}
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/thinklikeapianist/"
                    target="_blank"
                    rel="noreferrer"
                    className="flq-social-link"
                    data-sr-item="footer-copyright"
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "color 0.15s ease-in-out 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
                    }}
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@Itsdelect"
                    target="_blank"
                    rel="noreferrer"
                    className="flq-social-link"
                    data-sr-item="footer-copyright"
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "color 0.15s ease-in-out 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
                    }}
                  >
                    <AiFillYoutube fontSize={24} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@itsdelect?_t=8gesw2WXFlm&_r=1"
                    target="_blank"
                    rel="noreferrer"
                    className="flq-social-link"
                    data-sr-item="footer-copyright"
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "color 0.15s ease-in-out 0s, opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.2s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.2s",
                    }}
                  >
                    <FaTiktok fontSize={20} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
