import { useEffect } from "react";
import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import { TopRatedVideo } from "../../types";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import { BsStarFill } from "react-icons/bs";
import TopRatedLoader from "./TopRatedLoader";

const TopRated = ({
  isError,
  isLoading,
  data,
}: {
  isError: boolean;
  isLoading: boolean;
  data: TopRatedVideo[] | undefined;
}) => {
  useEffect(() => {
    // Initialize Swiper
    if (!isLoading || !isError) {
      const swiperContainers = document.querySelectorAll(".swiper-containerr");
      swiperContainers.forEach((container) => {
        const containerElement = container as HTMLElement;

        new Swiper(containerElement, {
          modules: [Navigation, Pagination],
          slidesPerView: 1,
          spaceBetween: 30,
          speed: 800,
          touchRatio: 0.8,
          breakpoints: {
            656: { slidesPerView: 1 },
            1072: { slidesPerView: 2 },
          },
          navigation: {
            nextEl: ".flq-swiper-button-nextt",
            prevEl: ".flq-swiper-button-prevv",
          },
          pagination: {
            el: ".flq-swiper-pagination-customm",
            clickable: true,
          },
        });
      });
    }
  }, [isError, isLoading]);

  return (
    <div
      className="container flq-swiper-wrapper my-7"
      data-sr="upcoming-movies"
      data-sr-interval={100}
      data-sr-duration={1000}
      data-sr-distance={10}
    >
      <h2 className="mb-5" data-sr-item="upcoming-movies">
        Top Rated Videos
      </h2>
      <div
        className="swiper flq-swiper-effect-touch mb-5"
        data-sr-item="upcoming-movies"
        data-buttons="true"
        data-pagination-progress="true"
        data-gap={30}
        data-speed={800}
        data-touch-ratio="0.8"
        data-breakpoints="656:1,1072:2"
      >
        <div className="swiper-containerr">
          <div className="swiper-wrapper">
            {isLoading
              ? Array(6)
                  .fill(0)
                  .map((_, index) => <TopRatedLoader key={index} />)
              : data?.map((video) => (
                  <div className="swiper-slide" key={video._id}>
                    <Link
                      to={`/video/${video.videoId}`}
                      className="card flq-card-movie flq-color-opacity"
                    >
                      <span className="card-img-wrap">
                        <span className="flq-image flq-responsive flq-responsive-lg-1x1 flq-responsive-xl-16x9">
                          <img
                            src={video.poster}
                            alt="poster"
                            style={{ objectPosition: "20% 50%" }}
                          />
                        </span>
                      </span>
                      <span className="card-body">
                        <span className="card-badge">
                          <span className="card-badge badge badge-dark badge-glass flq-color-white">
                            {capitalizeFirstLetter(video.category)}
                          </span>{" "}
                          <BsStarFill style={{ color: "#c79200" }} />
                          {video.averageRating.toFixed(1)}
                        </span>
                        <span className="card-title h5">{video.title}</span>
                        <span className="card-description">
                          <span>
                            {video.description.length > 120
                              ? `${video.description.substring(0, 120)}...`
                              : video.description}
                          </span>
                        </span>
                      </span>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="row align-items-center justify-content-between gy-4 gx-5">
        <div
          className="col-12 col-sm-6 order-sm-2"
          data-sr-item="upcoming-movies"
        >
          <div className="swiper-pagination flq-swiper-pagination-progress" />
        </div>

        <div className="col-auto order-sm-3" data-sr-item="upcoming-movies">
          <div
            className="flq-swiper-button-prevv btn btn-sm btn-square btn-dark btn-active btn-icon-sm me-1"
            data-sr-item="related"
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18L9 12L15 6"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="flq-swiper-button-nextt btn btn-sm btn-square btn-dark btn-active btn-icon-sm"
            data-sr-item="related"
          >
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 6L15 12L9 18"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopRated;
