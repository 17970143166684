const NewReleasesLoader = () => {
  return (
    <div className="swiper-slide">
      <div className="card flq-card-blog">
        <div className="card-img-wrap">
          <div className="flq-image flq-rounded-xl flq-responsive flq-responsive-sm-3x4 skeleton-image"></div>
        </div>
        <div className="card-body">
          <h5 className="card-title">
            <div className="skeleton-text skeleton-title"></div>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default NewReleasesLoader;
