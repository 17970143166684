import { Link } from "react-router-dom";
import logo from "../assets/images/tlp-logo-white.png";

const NavbarMobile = ({ page }: { page: string }) => {
  return (
    <nav className="flq-navbar-mobile fancybox-content" id="flq_navbar_mobile">
      <div className="flq-fancybox-head">
        <div className="container-fluid">
          <Link to="/" className="flq-fancybox-brand me-auto">
            <img src={logo} className="flq-logo" alt="logo" />
          </Link>
          <button
            className="flq-fancybox-close btn btn-link ms-4"
            data-fancybox-close
          >
            <span />
          </button>
        </div>
      </div>
      <div className="container pt-4 pb-6">
        <div className="flq-fancybox-body row gy-6 gx-6">
          <div className="col-12 col-lg">
            <ul className="nav flex-column flq-navbar-nav accordion gy-3">
              <li
                className={`${
                  page === "Home" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/" className="nav-link">
                  <span className="nav-link-name">Home</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "About" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/about" className="nav-link">
                  <span className="nav-link-name">About</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Library" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/library" className="nav-link">
                  <span className="nav-link-name">Library</span>
                </Link>
              </li>
              <li
                className={`${
                  page === "Plans" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/plans" className="nav-link">
                  <span className="nav-link-name">Plans</span>
                </Link>
              </li>

              <li
                className={`${
                  page === "Contact" ? "nav-item active" : "nav-item"
                }`}
              >
                <Link to="/contact" className="nav-link">
                  <span className="nav-link-name">Contact</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarMobile;
