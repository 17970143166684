import { FormEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../utils/apiClient";
import { toast } from "react-toastify";
import logo from "../../assets/images/tlp-logo-white.png";
import { ApiResponse, LoginResponse } from "../../types";
import { loginUser } from "../../store/redux/userRedux";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";

const VerifyOtp = () => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resent, setResent] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordResetEmail = localStorage.getItem("registration-email");

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setIsResendDisabled(false);
    }
  }, [countdown]);

  const handleResendOtp = () => {
    setCountdown(30);
    setIsResendDisabled(true);
    setResent(true);
    resendOtp();
  };

  const resendOtp = async () => {
    try {
      await api({
        method: "POST",
        url: "/api/auth/register-resend",
        data: { email: passwordResetEmail },
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const verifyOtp: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = await api<ApiResponse<LoginResponse>>({
        method: "POST",
        url: "/api/auth/verify",
        data: { otp },
      });

      setLoading(false);
      dispatch(loginUser(data?.data?.user));
      localStorage.removeItem("registration-email");
      localStorage.setItem("tlp-access-token", data?.data?.accessToken ?? "");
      localStorage.setItem("tlp-refresh-token", data?.data?.refreshToken ?? "");
      navigate("/");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>

                <div className="flq-fancybox-body pb-6">
                  <form className="flq-signin-content" onSubmit={verifyOtp}>
                    <h4 className="mb-2 pb-1 text-center">Verify account</h4>
                    <p className="mb-3">
                      Please enter the OTP we sent to{" "}
                      {passwordResetEmail ? (
                        <strong>{passwordResetEmail}</strong>
                      ) : (
                        "your email address"
                      )}{" "}
                      to verify your account and proceed.
                    </p>
                    <div className="row justify-content-between gy-4">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <input {...props} />}
                        containerStyle={{
                          width: "100%",
                          padding: "0",
                          justifyContent: "center",
                        }}
                        inputStyle={{
                          width: "auto",
                          maxWidth: "40px",
                          margin: "0 5px",
                          fontSize: "20px",
                          borderRadius: "8px",
                          border: "1px solid #ffffff26",
                          outline: "none",
                          textAlign: "center",
                          backgroundColor: "#ffffff0f",
                          transition: "border-color 0.2s",
                          color: "#bfbfbf",
                        }}
                      />

                      {!resent && passwordResetEmail && (
                        <>
                          {isResendDisabled ? (
                            <div className="col-auto">
                              <p className="flq-color-meta flq-color-title-hover">
                                Didn't receive code? Resend in {countdown}{" "}
                                seconds
                              </p>
                            </div>
                          ) : (
                            <a href="#/" onClick={handleResendOtp}>
                              Resend code
                            </a>
                          )}
                        </>
                      )}

                      {otp.length === 6 && (
                        <div className="col-12">
                          {loading ? (
                            <button
                              className="btn btn-block"
                              style={{ background: "#c79200" }}
                              disabled
                            >
                              Confirm
                            </button>
                          ) : (
                            <button
                              className="btn btn-block"
                              style={{ background: "#c79200" }}
                            >
                              Confirm
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
