import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/tlp-logo-white.png";
import { FormEventHandler, useState } from "react";
import { api } from "../../utils/apiClient";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/redux/userRedux";
import { ApiResponse, LoginResponse } from "../../types";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { getGoogleUrl } from "../../utils/getGoogleUrl";

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailFieldEmpty, setEmailFieldEmpty] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || "/";

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const googleHandler = () => {
    try {
      window.location.href = getGoogleUrl(from);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const loginHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    const emailFormat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!email || !email.match(emailFormat)) {
      setEmailFieldEmpty(true);
      return;
    } else {
      setEmailFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    try {
      setLoading(true);

      const data = await api<ApiResponse<LoginResponse>>({
        method: "POST",
        url: "/api/auth/login",
        data: { email, password },
      });

      setLoading(false);
      dispatch(loginUser(data?.data?.user));
      localStorage.setItem("tlp-access-token", data?.data?.accessToken ?? "");
      localStorage.setItem("tlp-refresh-token", data?.data?.refreshToken ?? "");
      navigate("/");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                  <form onSubmit={loginHandler} className="flq-signin-content">
                    <h4 className="mb-4 pb-1 text-center">Login</h4>
                    <div className="row justify-content-between gy-4">
                      <div className="col-12">
                        <input
                          className="form-control flq-form-user flq-form-translucent"
                          type="email"
                          name="email"
                          placeholder="Email address"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailFieldEmpty(false);
                          }}
                        />
                        {emailFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Please enter a valid email address
                          </p>
                        )}
                      </div>
                      <div className="col-12" style={{ position: "relative" }}>
                        <input
                          className="form-control flq-form-lock flq-form-translucent"
                          type={`${passwordShown ? "text" : "password"}`}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordFieldEmpty(false);
                          }}
                        />
                        <a
                          href="#/"
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "32px",
                            top: "10px",
                          }}
                        >
                          {!passwordShown ? (
                            <IoEyeOutline />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </a>
                        {passwordFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Please enter your password
                          </p>
                        )}
                      </div>
                      <div className="col-auto">
                        <Link
                          to="/forgot-password"
                          className="flq-color-meta flq-color-title-hover"
                        >
                          Forgot Password
                        </Link>
                      </div>
                      <div className="col-12">
                        {loading ? (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                            disabled
                          >
                            Login
                          </button>
                        ) : (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                          >
                            Login
                          </button>
                        )}
                      </div>

                      <div className="col-12 text-center mt-1">or</div>
                      <div className="col-12 mt-1">
                        <div className="row justify-content-center gy-1">
                          <div className="col-12">
                            <button
                              onClick={googleHandler}
                              className="btn btn-sm btn-block btn-white btn-icon-sm"
                              type="button"
                              style={{ width: "100%", fontSize: "15px" }}
                            >
                              <FcGoogle
                                style={{
                                  marginRight: "4px",
                                }}
                              />
                              Login with Google
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 mt-3 text-center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Don't have an account?{" "}
                        <Link
                          to="/register"
                          className="btn btn-link"
                          style={{ marginLeft: "5px" }}
                        >
                          {" "}
                          Sign Up{" "}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
