import { useInfiniteQuery } from "@tanstack/react-query";
import NavbarTop from "../../components/NavbarTop";
import { UserContentResponse } from "../../types";
import { getUserQuestionsAndReviews } from "../../utils/queries";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/redux/store";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../store/redux/userRedux";
import Footer from "../../components/Footer";
import NavbarIcons from "../../components/NavbarIcons";
import NavbarMobile from "../../components/NavbarMobile";
import NavbarUser from "../../components/NavbarUser";
import { AiFillMessage } from "react-icons/ai";
import { BsStarHalf, BsStar, BsStarFill } from "react-icons/bs";

const Questions = () => {
  const user: any = useSelector((state: RootState) => state.user.currentUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<UserContentResponse>({
      queryKey: ["user_questions_reviews"],
      queryFn: ({ pageParam = 1 }: { pageParam: any }) =>
        getUserQuestionsAndReviews({ id: user?._id, pageParam }),
      getNextPageParam: (lastPage: any, pages) => {
        if (lastPage.content.length === 10) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      initialPageParam: 1,
    });

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Questions" />
      <div className="content-wrap" style={{ paddingTop: "80px" }}>
        <div className="container pt-5 pb-7">
          <div className="row gy-6 gx-6">
            <div className="col-12 col-xl order-5">
              {data && data?.pages[0].totalContent > 0 ? (
                <div
                  className="row gx-0 gy-4"
                  data-sr="account-review"
                  data-sr-delay={300}
                  data-sr-interval={100}
                  data-sr-duration={1000}
                  data-sr-distance={10}
                >
                  {data.pages.map((page, index) => (
                    <div key={index}>
                      {page.content.map((content: any) => (
                        <div key={content._id} style={{ marginBottom: "24px" }}>
                          <div className="col-12" data-sr-item="account-review">
                            <div className="flq-account-comment flq-vertical-rhythm">
                              <div className="flq-media mb-0">
                                <div className="flq-media-meta">
                                  <h5 className="flq-media-title">
                                    <Link to={`/video/${content.videoId}`}>
                                      {content.title
                                        ? content.title
                                        : content.videoTitle}
                                    </Link>
                                  </h5>
                                  {content.replies && (
                                    <>
                                      {content.replies.length > 0 && (
                                        <div className="flq-meta">
                                          <ul>
                                            <li>
                                              <AiFillMessage
                                                style={{ marginRight: "5px" }}
                                              />{" "}
                                              {content.replies.length}
                                            </li>
                                          </ul>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <p style={{ marginTop: "8px" }}>{content.text}</p>
                              <hr className="my-0" />
                              {content.rating && (
                                <div>
                                  {content.rating === 1 && (
                                    <div className="flq-rating">
                                      <BsStarHalf
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 2 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 3 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 4 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 5 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 6 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 7 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 8 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar />
                                    </div>
                                  )}
                                  {content.rating === 9 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {content.rating === 10 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              <p className="mt-1">
                                <strong>
                                  {new Date(content?.createdAt)?.toDateString()}{" "}
                                  at{" "}
                                  {new Date(
                                    content?.createdAt
                                  )?.toLocaleTimeString()}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}

                      {hasNextPage && (
                        <div className="card-body">
                          {isFetchingNextPage ? (
                            <button
                              className="btn btn-dark btn-glass btn-block"
                              data-bs-toggle="button"
                              style={{ width: "80%", margin: "auto" }}
                              disabled
                            >
                              <span className="btn-name">Showing More...</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-dark btn-glass btn-block"
                              data-bs-toggle="button"
                              style={{ width: "80%", margin: "auto" }}
                              onClick={() => {
                                fetchNextPage();
                              }}
                            >
                              <span className="btn-name">Show More</span>
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flq-account-favorite">
                  <p style={{ margin: "auto" }}>No questions or reviews yet</p>
                </div>
              )}
            </div>
            <div className="col-12 col-xl-auto">
              <div className="flq-sidebar flq-sidebar-lg">
                <div className="flq-account-navbar">
                  <nav>
                    <ul className="nav nav-dots flex-column gy-3">
                      <li className="nav-item">
                        <Link to="/account-profile" className="nav-link">
                          <span className="nav-link-name">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-favorites" className="nav-link">
                          <span className="nav-link-name">Favorites</span>
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link to="/account-questions" className="nav-link">
                          <span className="nav-link-name">
                            Questions &amp; Reviews
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-edit" className="nav-link">
                          <span className="nav-link-name">Account Edit</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-subscription" className="nav-link">
                          <span className="nav-link-name">Subscription</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="#/" className="nav-link" onClick={logout}>
                          <span className="nav-link-name">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Questions" />
      <NavbarUser page="Questions" />
    </div>
  );
};

export default Questions;
