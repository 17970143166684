import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Numbers = ({ users, videos }: { users?: number; videos?: number }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      className="py-7 overflow-hidden flq-background flq-background-color-100"
      data-sr="features"
      data-sr-interval={100}
      data-sr-duration={1000}
      data-sr-distance={10}
      ref={ref}
    >
      <div className="flq-background-shape">
        <div data-rellax data-rellax-speed={1} className="mt-auto">
          <svg
            className="flq-translate-y50 ms-n7"
            width={866}
            height={831}
            viewBox="0 0 866 831"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M323.654 829.93C224.153 816.108 227.515 661.483 166.991 581.373C117.182 515.444 16.5101 485.726 2.15912 404.394C-12.2526 322.718 48.441 248.437 96.2908 180.687C138.901 120.355 193.017 70.7174 259.952 39.3885C326.181 8.38988 399.582 -9.42614 471.263 5.18105C541.916 19.579 590.604 76.5775 649.113 118.693C724.145 172.702 839.74 196.401 861.604 286.155C883.301 375.219 810.521 461.538 749.243 529.731C697.595 587.206 611.836 589.844 548.676 634.395C465.428 693.116 424.619 843.956 323.654 829.93Z"
              fill="#fff"
              fillOpacity="0.02"
            />
          </svg>
        </div>
        <div data-rellax data-rellax-speed={-1} className="ms-auto">
          <svg
            className="flq-translate-yn50 mt-n7 ms-7"
            width={782}
            height={865}
            viewBox="0 0 782 865"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M755.116 831.212C680.566 911.828 543.954 812.208 434.284 816.447C344.028 819.936 254.487 891.671 172.561 853.73C90.2879 815.629 61.5615 714.78 30.6714 629.531C3.16372 553.617 -7.56424 474.065 6.13963 394.452C19.6991 315.678 49.5645 238.707 107.441 183.536C164.489 129.156 246.033 121.094 320.36 94.9246C415.677 61.3647 509.14 -27.5167 603.314 8.94621C696.764 45.1286 728.741 164.34 751.641 261.91C770.941 344.146 719.74 422.719 720.256 507.206C720.936 618.565 830.763 749.41 755.116 831.212Z"
              fill="#fff"
              fillOpacity="0.02"
            />
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row gy-5 gx-6">
          <div className="col-lg-4">
            <h2 className="h1" data-sr-item="features">
              The numbers don't lie
            </h2>
          </div>
          <div className="col-lg-8">
            <div className="row g-6">
              <div className="col-12 col-sm-6" data-sr-item="features">
                <div className="card flq-card-feature">
                  <div className="card-body" style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        fontSize: "60px",
                        color: "#c79200",
                        marginBottom: "3px",
                      }}
                    >
                      {inView ? <CountUp end={users!} duration={1} /> : 0}
                    </h3>
                    <div className="card-title h5">students</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6" data-sr-item="features">
                <div className="card flq-card-feature">
                  <div className="card-body" style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        fontSize: "60px",
                        color: "#c79200",
                        marginBottom: "3px",
                      }}
                    >
                      {inView ? <CountUp end={videos!} duration={2} /> : 0}
                    </h3>
                    <div className="card-title h5">courses</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6" data-sr-item="features">
                <div className="card flq-card-feature">
                  <div className="card-body" style={{ textAlign: "center" }}>
                    <h3
                      style={{
                        fontSize: "60px",
                        color: "#c79200",
                        marginBottom: "3px",
                      }}
                    >
                      {inView ? <CountUp end={144} duration={3} /> : 0}
                    </h3>
                    <div className="card-title h5">countries</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;
