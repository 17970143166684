const TopRatedLoader = () => {
  return (
    <div className="swiper-slide">
      <div className="card flq-card-movie flq-color-opacity">
        <span className="card-img-wrap">
          <span className="flq-image flq-responsive flq-responsive-lg-1x1 flq-responsive-xl-16x9 skeleton-image"></span>
        </span>
        <span className="card-body">
          <span className="card-badge">
            <span className="card-badge badge badge-dark badge-glass flq-color-white"></span>
          </span>
        </span>
      </div>
    </div>
  );
};

export default TopRatedLoader;
