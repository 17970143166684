const LibraryLoader = () => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 flq-isotope-item">
      <div data-sr-item="tvshow-item">
        <div className="card flq-card-blog">
          <div className="card-img-wrap">
            <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4 skeleton-image"></span>
            <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryLoader;
