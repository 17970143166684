import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";
import ceo from "../assets/images/delect.png";
import { AiFillYoutube } from "react-icons/ai";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarUser from "../components/NavbarUser";
import ClientSlider from "../components/ClientsSlider";
import Banner from "../components/Banner";

const About = () => {
  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="About" />
      <Banner page="About" />
      <div className="content-wrap">
        <div className="pt-6">
          <div className="container">
            <div className="row justify-content-center mb-6">
              <div
                className="col-12 col-lg-10 col-xl-8 flq-vertical-rhythm text-center"
                data-sr="team-text"
                data-sr-interval={100}
                data-sr-distance={10}
                data-sr-duration={1000}
              >
                <p
                  className="lead"
                  data-sr-item="team-text"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  'Think Like a Pianist' is headed by one of the best pianists
                  we have in this day and age (Chibuzor Emmanuel Okafor - D
                  Elect) and it is designed to aid musicians worldwide to learn
                  the keys at their own pace and still become the very best they
                  can be. There is no pressure whatsoever. You set the targets
                  yourself and accomplish them when you want. We place premium
                  value on you and your craft, that's why we have detailed
                  explanations and exercises on all our courses in order to
                  ensure that you do not only know them, but know how to teach
                  them. You also have the opportunity to send in your reviews
                  and possible topics you might want to gain mastery of and we
                  will look into it and give feedback.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container pt-navbar pb-7">
          <div className="row gy-6 gx-6">
            <div
              className="col-12 col-lg-5 col-xxl-4"
              data-sr="actor-image"
              data-sr-interval={80}
              data-sr-delay={100}
              data-sr-duration={1000}
              data-sr-distance={10}
            >
              <div
                className="card flq-card-image flq-card-favorites mb-4"
                data-sr-item="actor-image"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.1s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.1s",
                }}
              >
                <a href="#/" className="card-image">
                  <span className="flq-image flq-responsive flq-responsive-1x1 flq-responsive-lg-3x4">
                    <img src={ceo} alt="D Elect" />
                  </span>
                </a>
              </div>
              <div className="row gx-1 gy-1">
                <div
                  className="col"
                  data-sr-item="actor-image"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.1s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.1s",
                  }}
                >
                  <a
                    href="https://www.facebook.com/cdokafor1"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-block btn-facebook btn-icon-sm"
                  >
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.625 12C23.625 5.57812 18.4219 0.375 12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 17.8022 4.62609 22.6116 10.1836 23.4844V15.3605H7.23047V12H10.1836V9.43875C10.1836 6.52547 11.918 4.91625 14.5744 4.91625C15.8466 4.91625 17.1769 5.14313 17.1769 5.14313V8.0025H15.7106C14.2669 8.0025 13.8164 8.89875 13.8164 9.81797V12H17.0405L16.5248 15.3605H13.8164V23.4844C19.3739 22.6116 23.625 17.8022 23.625 12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="col"
                  data-sr-item="actor-image"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.1s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.1s",
                  }}
                >
                  <a
                    href="https://www.instagram.com/itsdelect/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-block btn-instagram btn-icon-sm"
                  >
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.22506 0.450134C3.03566 0.450134 0.450134 3.03566 0.450134 6.22506V17.7749C0.450134 20.9643 3.03566 23.5498 6.22506 23.5498H17.7749C20.9643 23.5498 23.5498 20.9643 23.5498 17.7749V6.22506C23.5498 3.03566 20.9643 0.450134 17.7749 0.450134H6.22506ZM5.5834 12C5.5834 15.5437 8.45621 18.4166 12 18.4166C15.5437 18.4166 18.4166 15.5437 18.4166 12C18.4166 8.45621 15.5437 5.5834 12 5.5834C8.45621 5.5834 5.5834 8.45621 5.5834 12ZM12 15.8499C14.1263 15.8499 15.8499 14.1263 15.8499 12C15.8499 9.87365 14.1263 8.15003 12 8.15003C9.87366 8.15003 8.15004 9.87365 8.15004 12C8.15004 14.1263 9.87366 15.8499 12 15.8499ZM19.6999 4.30008C19.6999 5.00883 19.1253 5.5834 18.4166 5.5834C17.7078 5.5834 17.1333 5.00883 17.1333 4.30008C17.1333 3.59133 17.7078 3.01677 18.4166 3.01677C19.1253 3.01677 19.6999 3.59133 19.6999 4.30008Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
                <div
                  className="col"
                  data-sr-item="actor-image"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0.1s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0.1s",
                  }}
                >
                  <a
                    href="https://www.youtube.com/@Itsdelect"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-block btn-youtube btn-icon-sm"
                  >
                    <AiFillYoutube fontSize={30} />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 col-xxl-8"
              data-sr="actor"
              data-sr-interval={80}
              data-sr-duration={1000}
              data-sr-distance={10}
            >
              <span
                className="flq-subtitle badge badge-white badge-translucent"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                Instructor
              </span>
              <h1
                className="h2 mb-3"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                Chibuzor Emmanuel Okafor
              </h1>
              <p
                className="mb-3"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                Chibuzor Emmanuel Okafor (A.K.A - D ELECT) is a music director,
                instructor, producer, pianist and song writer. He is the CEO of
                'D ELECT' Music. He has been in the industry for more than a
                decade now, having worked with several artists home and abroad,
                including the likes of Kim Burrel, Kierra Sheard, Lionel
                Peterson, Uche Agu, Lawrence & DeCovenant, Efe Nathan, Minister
                Kenn, Tosin Martins, etc just to mention a few. He was also part
                of the production team for THE VOICE NIGERIA Season 3 in 2020.
                He has recorded lots of albums and singles for artists both home
                and abroad and has a passion for excellence in his business.
              </p>
              <p
                className="mb-4"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                He is also involved in actively training musicians and this has
                brought out the BEST in the lives of those He has trained round
                the globe. His passion is to see musicians and singers become
                all the best they can be and match up with the standard of music
                not just locally, but internationally.
              </p>
              <p
                className="mb-2"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                "I know this site will inspire you and make you better in your
                craft. Just follow carefully and patiently."
              </p>
              <p
                className="mb-4"
                data-sr-item="actor"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                Musically Yours,
                <br />D Elect
              </p>
            </div>
          </div>
        </div>
        <ClientSlider />

        <Footer />
      </div>
      <NavbarIcons />
      <NavbarMobile page="About" />
      <NavbarUser page="About" />
    </div>
  );
};

export default About;
