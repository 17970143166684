import { useDispatch, useSelector } from "react-redux";
import NavbarTop from "../../components/NavbarTop";
import { RootState } from "../../store/redux/store";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavbarIcons from "../../components/NavbarIcons";
import NavbarMobile from "../../components/NavbarMobile";
import NavbarUser from "../../components/NavbarUser";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import { logoutUser } from "../../store/redux/userRedux";

const Profile = () => {
  const user: any = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Profile" />
      <div className="content-wrap" style={{ paddingTop: "80px" }}>
        <div className="container pt-5 pb-7">
          <div className="row gy-6 gx-6">
            <div className="col-12 col-xl order-5">
              <div
                className="flq-account-content"
                style={{ maxWidth: "100%", overflowX: "auto" }}
              >
                <span className="flq-account-avatar mb-4">
                  {user?.picture && (
                    <span className="flq-image flq-responsive flq-responsive-1x1 flq-rounded">
                      <img src={user?.picture} alt="user" />
                    </span>
                  )}
                </span>

                <table className="table" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="flq-color-meta">Full Name</td>
                      <td>{user.name}</td>
                    </tr>
                    <tr>
                      <td className="flq-color-meta">Email</td>
                      <td>{user.email}</td>
                    </tr>
                    <tr>
                      <td className="flq-color-meta">Phone</td>
                      <td>{user?.phone ? user?.phone : "-"}</td>
                    </tr>
                    <tr>
                      <td className="flq-color-meta">Subscription</td>
                      <td>{capitalizeFirstLetter(user?.subscription)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-xl-auto">
              <div className="flq-sidebar flq-sidebar-lg">
                <div className="flq-account-navbar">
                  <nav>
                    <ul className="nav nav-dots flex-column gy-3">
                      <li className="nav-item active">
                        <Link to="/account-profile" className="nav-link">
                          <span className="nav-link-name">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-favorites" className="nav-link">
                          <span className="nav-link-name">Favorites</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-questions" className="nav-link">
                          <span className="nav-link-name">
                            Questions &amp; Reviews
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-edit" className="nav-link">
                          <span className="nav-link-name">Account Edit</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-subscription" className="nav-link">
                          <span className="nav-link-name">Subscription</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="#/" className="nav-link" onClick={logout}>
                          <span className="nav-link-name">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Profile" />
      <NavbarUser page="Profile" />
    </div>
  );
};

export default Profile;
