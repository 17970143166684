import logo from "../assets/images/tlp-logo-white.png";

const Preloader = () => {
  return (
    <div className="preloaderr">
      <div>
        <img src={logo} alt="loading" />
      </div>
    </div>
  );
};

export default Preloader;
