import { Link } from "react-router-dom";

const Banner = ({ page }: { page: string }) => {
  return (
    <div className="flq-background py-7">
      <div className="flq-background-ticker">
        <div
          className="flq-image-ticker mb-3 flq-image-ticker-ready"
          data-gap={20}
          data-pixels-per-second={20}
        >
          <div
            className="flq-image-ticker-inner"
            style={{ width: "2160px", animationDuration: "108s" }}
          >
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/16932464268976-8two.jpeg?alt=media&token=fd2f0c94-6c54-4936-bb4f-3f316a53c10b&_gl=1*w9oo6h*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk1NDMuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245334271passing-notes.jpeg?alt=media&token=d60ccc7d-c7fc-475c-865c-ea4680d55eda&_gl=1*s44y3r*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk1NzUuMjguMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1669929675122Diatonic-scales-1.jpeg?alt=media&token=7f52dea7-bb15-4450-9797-230dcb7bb9a9&_gl=1*1zevae*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk2MjUuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1669931645231Minor-chord-progressions.jpeg?alt=media&token=17f0a415-7502-4a69-bf33-bd32c8e89fda&_gl=1*1uvlid0*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk2NDYuMzkuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1676024015123tritones-2.jpeg?alt=media&token=97937f83-a4ec-465b-8205-8b72cdb22ad6&_gl=1*11g4u0m*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk2NzIuMTMuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682190211222how-great-beginner.jpeg?alt=media&token=792a9934-d478-4035-9ce7-b579a6902b78&_gl=1*1mfg8cx*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk2OTEuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693246836609upper-structures2.jpeg?alt=media&token=0c8a4f9e-bfeb-4734-a07b-1ba1a27c9d37&_gl=1*uj8nbq*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk3MTUuMzYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1669932366866Left-hand-techniques.jpeg?alt=media&token=f69ba376-011c-4775-9167-ef57dd8ab746&_gl=1*u432uu*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk3NTAuMS4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672332818duet-beg.jpeg?alt=media&token=74fc1cec-2bfd-4e1d-9735-911dadde2207&_gl=1*1yquphj*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk3ODAuMzMuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685817833484faithfulness.jpeg?alt=media&token=6c96319b-596e-401f-a243-c0f279e1aa32&_gl=1*tay7py*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk4MTAuMy4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1670317187188rootless-2.jpeg?alt=media&token=ff082e83-ced0-4497-8ecf-7f2e0015b5f8&_gl=1*2nr4t0*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk4NDMuMzEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672332818duet-beg.jpeg?alt=media&token=74fc1cec-2bfd-4e1d-9735-911dadde2207&_gl=1*xpbaj6*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk4NTkuMTUuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685816057828rootless-further.jpeg?alt=media&token=36fa979f-4644-4c69-8cba-8028f673b288&_gl=1*1f7e2c9*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk4ODguNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1676031243794further-chord2.jpeg?alt=media&token=2341ac1c-4047-4826-af4c-63661a6b9e48&_gl=1*vsp9p4*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk5MDguNDAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1676023898890tritones-1.jpeg?alt=media&token=bde2f3b6-df3d-400e-8106-3371b0edc41a&_gl=1*16vhjs9*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk5NDguNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672906339ballad.jpeg?alt=media&token=b572264d-ee6a-422b-bd27-53890d44cf3c&_gl=1*1iar05e*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk5NjIuNDYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1670318129946drop.jpeg?alt=media&token=04078094-6ebd-4cb2-ba55-dfbc6796d7cd&_gl=1*17tmdcu*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk5ODIuMjYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1676031092212further-chord1.jpeg?alt=media&token=1a028698-5d79-4137-8dc0-def19ba26330&_gl=1*1s5cf8j*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk5OTcuMTEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693246836609upper-structures2.jpeg?alt=media&token=0c8a4f9e-bfeb-4734-a07b-1ba1a27c9d37&_gl=1*85ctk9*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAwMjkuNDYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693246611126comping.jpeg?alt=media&token=93e3af4f-8056-4313-a807-3d87cd9712d9&_gl=1*4plh4f*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAwNDguMjcuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/16932464268976-8two.jpeg?alt=media&token=fd2f0c94-6c54-4936-bb4f-3f316a53c10b&_gl=1*n19u8g*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxMTcuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/16932463426116-8one.jpeg?alt=media&token=22a40f46-642d-4ea2-b619-73bd772610ee&_gl=1*1een481*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxMzEuNDYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/16932461238276-8.jpeg?alt=media&token=4ac7cbcb-00d3-49a5-98cf-3a5c7aecad24&_gl=1*nnpqas*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxNDMuMzQuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245992192dirty-arpeggios2.jpeg?alt=media&token=52a99f3f-9dfc-49f5-b96a-e922f0f89fa8&_gl=1*bruhz2*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxNTYuMjEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245812987dirty-arpeggios1.jpeg?alt=media&token=b6df23e2-c5fd-4d24-82e7-fbc400b7773a&_gl=1*l7g424*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxNjcuMTAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245531265talk-music.jpeg?alt=media&token=5d4abc0a-bde3-4863-a33c-165571132d76&_gl=1*1559orv*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxNzcuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245433494improvise.jpeg?alt=media&token=a745fd29-569c-4ebf-bca5-f56245e3ec51&_gl=1*b6rohs*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAxODkuNDguMC4w"
                  alt="ticker"
                />
              </span>
            </div>
          </div>
        </div>
        <div
          className="flq-image-ticker flq-image-ticker-ready"
          data-gap={20}
          data-pixels-per-second={20}
          data-inverted
        >
          <div
            className="flq-image-ticker-inner"
            style={{ width: "2160px", animationDuration: "108s" }}
          >
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1676024943611licks-fills.jpeg?alt=media&token=f7e1fe12-4f10-4056-b798-c001f42ae27b&_gl=1*85f0b1*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NjkwNzMuMTYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1669930954193Arpeggios.jpeg?alt=media&token=07ff2622-63c6-444d-a34b-1e9f5aa6b04f&_gl=1*xwx8na*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk0NjEuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1673541882691further-insight.jpeg?alt=media&token=dba0da07-fb72-4c13-bc34-7b2135254ec8&_gl=1*1tupswc*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3Njk0ODcuMzQuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245334271passing-notes.jpeg?alt=media&token=d60ccc7d-c7fc-475c-865c-ea4680d55eda&_gl=1*1ptdjuk*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAyMjEuMTYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693245140906diatonic-scale.jpeg?alt=media&token=2850568a-9cf6-4b4e-addc-c8571449dec4&_gl=1*1soeshf*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAyNzMuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693244814751inversion.jpeg?alt=media&token=aa9290c1-23f2-41a9-a363-180a997297a4&_gl=1*bff53m*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAyODcuNDYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1693244380479all-my-life.jpeg?alt=media&token=2752b790-587a-4e8b-b84a-a1390f85e23f&_gl=1*1uw462d*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAyOTcuMzYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689673447173a4p4.jpeg?alt=media&token=0283ad77-3d02-47ec-9743-4c4cee049ee3&_gl=1*1trddfu*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAzMTguMTUuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689673235452shout.jpeg?alt=media&token=a97ca6ed-fe36-4fea-b8a3-67c0d0849a3e&_gl=1*13oep5v*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAzMzMuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672906339ballad.jpeg?alt=media&token=b572264d-ee6a-422b-bd27-53890d44cf3c&_gl=1*1pdpuu4*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAzNDguNDUuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672512933afrivamp.jpeg?alt=media&token=83add5c0-3eba-41fa-bb3f-3d001d248144&_gl=1*1rdmrh8*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAzNzIuMjEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689672332818duet-beg.jpeg?alt=media&token=74fc1cec-2bfd-4e1d-9735-911dadde2207&_gl=1*1tdijk6*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzAzODYuNy4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1689671994672ao.jpeg?alt=media&token=69a254d3-7dfc-43f1-9d31-0b624f0b4529&_gl=1*3jowtc*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA0MDAuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685818144303patterns.jpeg?alt=media&token=a4e0c37b-a819-4db0-8a9a-94510a9b287b&_gl=1*14z37ps*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA0MTguNDIuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685818021439modes-2.jpeg?alt=media&token=f659dba4-c637-483a-b2a6-750bf09dd682&_gl=1*g82wp2*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA0MzIuMjguMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685817242944gospel-gitf.jpeg?alt=media&token=d10528df-a32a-40f7-ba17-2b1865ee2d9f&_gl=1*1rg9l39*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA0OTcuMzYuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685817031238practice-routine.jpeg?alt=media&token=527fba68-da55-4c02-a0ab-7753323b149c&_gl=1*1xippma*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA1MTIuMjEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685816898159various-beginners.jpeg?alt=media&token=4c327245-235b-41ae-a70a-8c0cbb30edca&_gl=1*toa5vo*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA1MjkuNC4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685816344008turning-melodies.jpeg?alt=media&token=1b7c4d08-982e-41a3-b430-b6ffa21440e1&_gl=1*rqjqfw*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA1NTQuNTEuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1685815912874dirty-drop-2.jpeg?alt=media&token=1dfd3c09-182f-49c7-811f-001de116512f&_gl=1*u0s0i7*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA1ODguMTcuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682193755659superimposition-intro.jpeg?alt=media&token=4c819559-c09d-49e1-8166-2315678154be&_gl=1*2e24k3*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2MDMuMi4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682193476493how-great-pro.jpeg?alt=media&token=8064519a-b960-4632-b575-6c939cf4230e&_gl=1*8fei8y*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2MTguNDcuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682193153258pro-because.jpeg?alt=media&token=b9f61322-b44c-4501-9ead-9130aa8c8981&_gl=1*1lsyn0a*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2MzIuMzMuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682192618458how-great-inter.jpeg?alt=media&token=7cad0b11-1e8a-4389-a6b3-fee8382d5d0d&_gl=1*1naxvup*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2NTYuOS4wLjA."
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682192303747inter-because.jpeg?alt=media&token=81abcfb8-f28b-437f-a936-d33c04f1ff82&_gl=1*9tkjpu*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2NzEuNjAuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682191281757jazz-beginner.jpeg?alt=media&token=99fda2d5-6438-414d-9470-113708e8664b&_gl=1*11upq54*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA2ODYuNDUuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
            <div className="flq-image-ticker-item">
              <span className="flq-image">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/1682190211222how-great-beginner.jpeg?alt=media&token=792a9934-d478-4035-9ce7-b579a6902b78&_gl=1*1d4pggl*_ga*NzQwOTY2ODMuMTY5MTM1MjAyOA..*_ga_CW55HF8NVT*MTY5Nzc2ODk2Mi4xOC4xLjE2OTc3NzA3MDIuMjkuMC4w"
                  alt="ticker"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flq-background-overlay"
        style={{ backgroundColor: "hsla(var(--flq-color-black), 0.8)" }}
      />
      <div
        className="container text-center"
        data-sr="banner-text"
        data-sr-interval={100}
        data-sr-distance={10}
        data-sr-duration={1000}
      >
        <h1
          className="display-5 mb-1"
          data-sr-item="banner-text"
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
          }}
        >
          {page}
        </h1>
        <nav
          aria-label="breadcrumb"
          data-sr-item="banner-text"
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
          }}
        >
          <ol className="breadcrumb flq-color-opacity">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {page}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default Banner;
