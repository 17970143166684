import error from "../../assets/images/error.png";
import { Video } from "../../types";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import NewReleasesLoader from "./NewReleasesLoader";
import { useEffect } from "react";
import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";

const NewReleases = ({
  isError,
  isLoading,
  data,
}: {
  isError: boolean;
  isLoading: boolean;
  data: Video[] | undefined;
}) => {
  useEffect(() => {
    // Initialize Swiper
    if (!isLoading || !isError) {
      const swiperContainers = document.querySelectorAll(".swiper-container");
      swiperContainers.forEach((container) => {
        const containerElement = container as HTMLElement;

        new Swiper(containerElement, {
          modules: [Navigation, Pagination],
          slidesPerView: 1,
          spaceBetween: 30,
          speed: 800,
          touchRatio: 0.8,
          breakpoints: {
            636: { slidesPerView: 2 },
            1072: { slidesPerView: 3 },
            1280: { slidesPerView: 4 },
          },
          navigation: {
            nextEl: ".flq-swiper-button-next",
            prevEl: ".flq-swiper-button-prev",
          },
          pagination: {
            el: ".flq-swiper-pagination-custom",
            clickable: true,
          },
        });
      });
    }
  }, [isError, isLoading]);

  return (
    <div className="content-wrap flq-background-color-100">
      <div
        className="flq-swiper-wrapper"
        data-sr="new-releases"
        data-sr-interval={100}
        data-sr-duration={1000}
        data-sr-distance={10}
      >
        <div className="container mb-3" data-sr-item="new-releases">
          <h2>New Releases</h2>
        </div>
        {isError ? (
          <div
            className="container mb-5"
            data-sr-item="new-releases"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={error} alt="error" />
            <p
              className="lead"
              data-sr-item="team-text"
              style={{ textAlign: "center" }}
            >
              Sadly, we couldn't fetch the newest videos for you.
            </p>
          </div>
        ) : (
          <>
            <div
              className="swiper flq-swiper-effect-touch"
              data-sr-item="new-releases"
              data-buttons="true"
              data-pagination-custom="true"
              data-gap={30}
              data-speed={800}
              data-touch-ratio="0.8"
              data-slides="1,"
              data-breakpoints="636:2,1072:3,1280:4"
            >
              <div className="swiper-container container">
                <div className="swiper-wrapper">
                  {isLoading
                    ? Array(6)
                        .fill(0)
                        .map((_, index) => <NewReleasesLoader key={index} />)
                    : data?.map((video) => (
                        <div className="swiper-slide" key={video._id}>
                          <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                              <Link to={`/video/${video.videoId}`}>
                                <span className="flq-image flq-rounded-xl flq-responsive flq-responsive-sm-3x4">
                                  <img
                                    src={video.poster}
                                    alt="poster"
                                    style={{ objectPosition: "20% 50%" }}
                                  />
                                </span>
                              </Link>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title">
                                <Link to={`/video/${video.videoId}`}>
                                  {video.title}
                                </Link>
                              </h5>
                              <div className="flq-meta flq-meta-sm">
                                <ul>
                                  <li>{video.duration}m</li>
                                  <li>
                                    {capitalizeFirstLetter(video.category)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>

            <div className="container mt-5">
              <div className="row align-items-center justify-content-between gx-5">
                <div
                  className="col d-none d-sm-block"
                  data-sr-item="new-releases"
                >
                  <hr />
                </div>
                <div className="col-auto" data-sr-item="new-releases">
                  <div
                    className="flq-swiper-button-prev btn btn-sm btn-dark btn-active btn-square btn-icon-sm me-1"
                    data-sr-item="related"
                  >
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 18L9 12L15 6"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    className="flq-swiper-button-next btn btn-sm btn-dark btn-active btn-square btn-icon-sm"
                    data-sr-item="related"
                  >
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewReleases;
