import { Link } from "react-router-dom";
import { RootState } from "../store/redux/store";
import { useSelector } from "react-redux";

const NavbarIcons = () => {
  const user: any = useSelector((state: RootState) => state.user.currentUser);

  return (
    <div className="flq-navbar-icons">
      <ul>
        <li>
          <Link to="/" className="btn btn-link btn-icon-md">
            <svg
              width={22}
              height={22}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 12H9V22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0392 3 20.5305 3 20V9.9782C3 9.36102 3.28495 8.77841 3.77212 8.39949L10.7721 2.95505C11.4943 2.39332 12.5057 2.39332 13.2279 2.95505L20.2279 8.39949C20.7151 8.77841 21 9.36102 21 9.9782V20C21 20.5305 20.7893 21.0392 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H15V15"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </li>
        <li>
          <Link to="/search" className="btn btn-link btn-icon-md">
            <svg
              width={22}
              height={22}
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.25 19.25L15.2625 15.2625"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </li>
        {user && (
          <li>
            <button
              className="btn btn-link btn-icon-md"
              data-fancybox
              data-src="#flq_popup_user"
              data-base-class="flq-fancybox-navbar"
              data-animation-duration={1000}
              data-keyboard="false"
              data-auto-focus="false"
              data-touch="false"
              data-close-existing="true"
              data-small-btn="false"
              data-toolbar="false"
            >
              {user?.picture ? (
                <img
                  src={user?.picture}
                  alt="user"
                  style={{ width: "36px", height: "36px", borderRadius: "50%" }}
                />
              ) : (
                <svg
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.4999 21.1422C17.888 20.8923 18.9884 20.5116 19.5 20C19.5718 19.3531 19.5 18.3333 19.5 18.3333C19.5 17.4493 19.0785 16.6014 18.3284 15.9763C15.9594 14.0022 8.04049 14.0022 5.67154 15.9763C4.92139 16.6014 4.49996 17.4493 4.49996 18.3333C4.49996 18.3333 4.42809 19.3531 4.49996 20C5.01153 20.5116 6.11194 20.8923 7.49996 21.1422M16 7C16 9.20914 14.2091 11 12 11C9.79082 11 7.99996 9.20914 7.99996 7C7.99996 4.79086 9.79082 3 12 3C14.2091 3 16 4.79086 16 7Z"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </li>
        )}
        {!user && (
          <li>
            <Link to="/login" className="btn btn-link btn-icon-md">
              <svg
                width={22}
                height={22}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17L15 12M15 12L10 7M15 12H3M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </li>
        )}
        <li className="d-flex align-items-center">
          <div className="flq-scroll-top-wrapper">
            <a className="flq-scroll-top-button flq-anchor" href="#page">
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 6L15 12L9 18"
                  stroke="currentColor"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
            <div className="flq-scroll-progress flq-scroll-progress-circle">
              <svg>
                <circle stroke="currentColor" r={0} fill="none" />
              </svg>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NavbarIcons;
