const Testimonials = () => {
  return (
    <div className="py-7">
      <div
        className="container flq-isotope"
        style={{ paddingLeft: "0", paddingRight: "0" }}
      >
        <div className="text-center mb-5">
          <h2 data-sr-item="online-streaming">Testimonials</h2>
        </div>
        <div
          className="row gy-5 justify-content-center flq-isotope-grid"
          style={{ display: "flex", alignItems: "end" }}
          data-isotope-layout="fitRows"
          data-sr="movies-item"
          data-sr-interval={80}
          data-sr-duration={1000}
          data-sr-distance={10}
        >
          <div className="col-12 col-md-6 col-lg" data-sr-item="card-feature">
            <a
              href="#/"
              className="card flq-card-feature flq-card-feature-filled text-center"
            >
              <span className="card-body">
                <span
                  className="card-icon"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/ini.jpeg?alt=media&token=5a4e5012-6504-4d45-b332-2a1e36f5f33c"
                    alt="Ini D'Minstrel"
                    style={{
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="card-title h5" style={{ marginTop: "16px" }}>
                  Ini D'Minstrel
                </span>
                <span className="paragraph">
                  "Chibuzor D Elect is an amazing and a creative keyboard
                  player, musical director, arranger and a clinician. At
                  thinklikeapianist.com with Chibuzor, you’re set to learn
                  modern piano techniques, approaches and reharmonization that
                  would set you up to be a great pianist, accompanist and
                  improvisor. All the best 👍🏾"
                </span>
              </span>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg" data-sr-item="card-feature">
            <a
              href="#/"
              className="card flq-card-feature flq-card-feature-filled text-center"
            >
              <span className="card-body">
                <span
                  className="card-icon"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/frimpong.jpeg?alt=media&token=d27776eb-9238-48ef-ba74-79b94d180836"
                    alt="Emmanuel Frimpong"
                    style={{
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="card-title h5" style={{ marginTop: "16px" }}>
                  Emmanuel Frimpong
                </span>
                <span className="paragraph">
                  "ThinkLikeAPianist.com has been an invaluable resource in my
                  journey towards becoming a better pianist. The structured
                  lessons, insightful tips and comprehensive tutorials have
                  truly transformed my approach to playing the piano. The
                  platform's emphasis on technique, expression and musicality
                  has helped me refine my skills and develop a deeper
                  understanding of music. Thanks to ThinkLikeAPianist.com, I've
                  gained confidence, proficiency, and a newfound passion for
                  playing the piano. It's truly been a game-changer in my
                  musical growth."
                </span>
              </span>
            </a>
          </div>
        </div>

        <div
          className="row gy-5 justify-content-center flq-isotope-grid"
          style={{ marginTop: "1px" }}
          data-isotope-layout="fitRows"
          data-sr="movies-item"
          data-sr-interval={80}
          data-sr-duration={1000}
          data-sr-distance={10}
        >
          <div className="col-12 col-md-6 col-lg" data-sr-item="card-feature">
            <a
              href="#/"
              className="card flq-card-feature flq-card-feature-filled text-center"
            >
              <span className="card-body">
                <span
                  className="card-icon"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/chima.jpg?alt=media&token=d377561d-505b-463d-ac76-d34e107fc6f8"
                    alt="Emmanuel Frimpong"
                    style={{
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="card-title h5" style={{ marginTop: "16px" }}>
                  Chima Okoli
                </span>
                <span className="paragraph">
                  “I have been a student at Think Like a Pianist online school
                  led by instructor Chibuzor D'Elect for over a year now, and I
                  can confidently say that it has been an incredible journey of
                  musical discovery. Through Chibuzor's expert guidance, I have
                  delved into various music genres, including jazz, classical,
                  Afro Jams, gospel, funk, and pop. The depth and breadth of
                  knowledge I have gained in music concepts have been truly
                  remarkable. Each lesson has enhanced my understanding and
                  appreciation of music. Chibuzor's teaching style is engaging
                  and effective, making even complex concepts easy to understand
                  and apply. I highly recommend TLP to anyone looking to expand
                  their musical horizons and unlock their full potential as a
                  musician.”
                </span>
              </span>
            </a>
          </div>
          <div className="col-12 col-md-6 col-lg" data-sr-item="card-feature">
            <a
              href="#/"
              className="card flq-card-feature flq-card-feature-filled text-center"
            >
              <span className="card-body">
                <span
                  className="card-icon"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/tlap-ab68c.appspot.com/o/mayowa.jpeg?alt=media&token=bc225e41-97e0-4e09-bf86-d47a3885119d"
                    alt="Mayowa Okuwa"
                    style={{
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </span>
                <span className="card-title h5" style={{ marginTop: "16px" }}>
                  Mayowa Okuwa
                </span>
                <span className="paragraph">
                  "TLP has been a big blessing to me. It has opened my eyes to
                  think critically when approaching the piano. The site has
                  offered many resources that has improved my play on various
                  styles and forms of music. I love how the teacher (D’elect),
                  explains the theory of every topic in depth, making it very
                  easy to use and understand the material. I can gladly say the
                  TLP site has helped shaped my play over the years of playing
                  keys."
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
