import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import NavbarTop from "../components/NavbarTop";
import { Link, useParams } from "react-router-dom";
import {
  addToFavorites,
  addVideoQuestion,
  addVideoReview,
  deleteQuestion,
  deleteReply,
  getNextVideos,
  getUserFavorites,
  getVideoDetails,
  getVideoPlayback,
  getVideoQuestions,
  getVideoReviews,
  removeFavorite,
  sendReply,
} from "../utils/queries";
import Preloader from "../components/Preloader";
import { useSelector } from "react-redux";
import { RootState } from "../store/redux/store";
import { hasAccessToCategory } from "../helpers/hasAccessToCategory";
import {
  BsChatLeft,
  BsReply,
  BsStar,
  BsStarFill,
  BsStarHalf,
} from "react-icons/bs";
import { MdOutlineSlowMotionVideo } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { toast } from "react-toastify";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarUser from "../components/NavbarUser";
import Footer from "../components/Footer";
import { useState } from "react";
import { Question, QuestionResponse, Reply, ReviewResponse } from "../types";
import { getInitials } from "../helpers/getInitials";
import { formatDistanceToNowShort } from "../helpers/formatDate";
import { RiDeleteBinLine } from "react-icons/ri";
import { capitalizeFirstLetter } from "../helpers/capitalize";

const Video = () => {
  const [tabSelected, setTabSelected] = useState("Questions");
  const [questionBoxOpen, setQuestionBoxOpen] = useState(false);
  const [reviewBoxOpen, setReviewBoxOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState<Question>({
    _id: "",
    user: null,
    videoId: "",
    title: "",
    text: "",
    replies: [],
    answered: false,
    createdAt: new Date(),
  });
  const [questionToReply, setQuestionToReply] = useState<Question>({
    _id: "",
    user: null,
    videoId: "",
    title: "",
    text: "",
    replies: [],
    answered: false,
    createdAt: new Date(),
  });
  const [replyInputOpen, setReplyInputOpen] = useState(false);
  const [questionDeleteModalOpen, setQuestionDeleteModalOpen] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = useState("");
  const [viewRepliesModalOpen, setViewRepliesModalOpen] = useState(false);
  const [replies, setReplies] = useState<Reply[]>([]);
  const [reply, setReply] = useState("");
  const [replyToDelete, setReplyToDelete] = useState<Reply>({
    repliedOn: new Date(),
    text: "",
    user: null,
  });
  const [deleteReplyModalOpen, setDeleteReplyModalOpen] = useState(false);
  const [rating, setRating] = useState("");
  const [ratingEmpty, setRatingEmpty] = useState(false);
  const [reviewText, setReviewText] = useState("");

  const { videoId } = useParams();
  const queryClient = useQueryClient();
  const index = replies?.indexOf(replyToDelete);

  const user: any = useSelector((state: RootState) => state.user.currentUser);
  let videoRating: any;

  const { data: video, isLoading } = useQuery({
    queryKey: ["videos", { videoId }],
    queryFn: () => getVideoDetails(videoId!),
  });

  const { data: playbackData, isLoading: isPlaybackLoading } = useQuery({
    queryKey: ["video_playback", { videoId }],
    queryFn: () => getVideoPlayback(user._id, videoId!),
    enabled: user !== null,
  });

  const { data: favoritesData } = useQuery({
    queryKey: ["user_favorites", { videoId }],
    queryFn: () => getUserFavorites(user._id),
    enabled: user !== null,
  });

  const { data: nextVideos } = useQuery({
    queryKey: ["next_videos", { videoId }],
    queryFn: () =>
      getNextVideos({
        category: video?.category ?? "",
        serialNumber: video?.serialNumber ?? 1,
      }),
    enabled: video !== null,
  });

  const {
    data: questions,
    fetchNextPage: fetchMoreQuestions,
    hasNextPage: hasMoreQuestions,
    isFetchingNextPage: isFetchingMoreQuestions,
    isLoading: isQuestionsLoading,
  } = useInfiniteQuery<QuestionResponse>({
    queryKey: ["video_questions", { videoId }],
    queryFn: ({ pageParam = 1 }: { pageParam: any }) =>
      getVideoQuestions({ pageParam, videoId: videoId ?? "" }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.questions.length === 10) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
    initialPageParam: 1,
  });

  const {
    data: reviews,
    fetchNextPage: fetchMoreReviews,
    hasNextPage: hasMoreReviews,
    isFetchingNextPage: isFetchingMoreReviews,
    isLoading: isReviewsLoading,
  } = useInfiniteQuery<ReviewResponse>({
    queryKey: ["video_reviews", { videoId }],
    queryFn: ({ pageParam = 1 }: { pageParam: any }) =>
      getVideoReviews({ pageParam, videoId: videoId ?? "" }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.reviews.length === 10) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
    initialPageParam: 1,
  });

  const isFavorite =
    favoritesData?.some((favorite) => favorite.video.videoId === videoId) ??
    false;

  const favoriteVideo = favoritesData?.find(
    (favorite) => favorite.video.videoId === videoId
  );

  const { isPending: addingToFavs, mutate: addVideoToFavorites } = useMutation({
    mutationFn: () => {
      return addToFavorites({
        user: user?._id,
        video: video?._id ?? "",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user_favorites"],
      });
      queryClient.invalidateQueries({
        queryKey: ["user_favorites", { videoId }],
      });
    },
    onError: () => {
      toast.error("Unable to add to favorites.");
    },
  });

  const { isPending: removingFromFavs, mutate: removeVideoFromFavorites } =
    useMutation({
      mutationFn: () => {
        return removeFavorite({
          id: favoriteVideo?._id ?? "",
          userId: user?._id,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["user_favorites"],
        });
        queryClient.invalidateQueries({
          queryKey: ["user_favorites", { videoId }],
        });
      },
      onError: () => {
        toast.error("Unable to remove from favorites.");
      },
    });

  const { isPending: askingQuestion, mutate: askQuestion } = useMutation({
    mutationFn: () => {
      return addVideoQuestion({
        text,
        title,
        videoId: videoId ?? "",
        video: video?._id ?? "",
        userId: user?._id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["video_questions", { videoId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["user_questions_reviews"],
      });

      setTitle("");
      setText("");
      setQuestionBoxOpen(false);
      toast.success("Question added successfully");
    },
    onError: () => {
      toast.error("Unable to submit question.");
    },
  });

  const { isPending: sendingReply, mutate: replyQuestion } = useMutation({
    mutationFn: () => {
      return sendReply({
        text: reply,
        questionId: questionToReply._id,
        repliedOn: new Date(),
        userId: user?._id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["video_questions", { videoId }],
      });

      setReply("");
      setReplyInputOpen(false);
      toast.success("Reply added successfully");
    },
    onError: () => {
      toast.error("Unable to submit reply.");
    },
  });

  const { isPending: isDeletingReply, mutate: deleteQuestionReply } =
    useMutation({
      mutationFn: () => {
        return deleteReply({
          id: selectedQuestion._id,
          userId: user._id,
          index,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["video_questions", { videoId }],
        });

        setDeleteReplyModalOpen(false);
        setReplyToDelete({
          repliedOn: new Date(),
          text: "",
          user: null,
        });
      },
      onError: () => {
        toast.error("Unable to delete reply.");
      },
    });

  const { isPending: isDeletingQuestion, mutate: deleteVideoQuestion } =
    useMutation({
      mutationFn: () => {
        return deleteQuestion({ id: questionIdToDelete, userId: user?._id });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["video_questions", { videoId }],
        });
        queryClient.invalidateQueries({
          queryKey: ["user_questions_reviews"],
        });

        setQuestionIdToDelete("");
        setQuestionDeleteModalOpen(false);
      },
      onError: () => {
        toast.error("Unable to delete question.");
      },
    });

  const { isPending: reviewing, mutate: submitReview } = useMutation({
    mutationFn: () => {
      return addVideoReview({
        rating: Math.round(+rating),
        videoId: videoId ?? "",
        videoTitle: video?.title ?? "",
        text: reviewText,
        userId: user?._id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["video_reviews", { videoId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["videos", { videoId }],
      });
      queryClient.invalidateQueries({
        queryKey: ["user_questions_reviews"],
      });

      setReviewText("");
      setRating("");
      setReviewBoxOpen(false);
      toast.success("Review added successfully");
    },
    onError: () => {
      toast.error("Unable to submit review.");
    },
  });

  if (video) {
    videoRating = video.ratingsTotal / video.ratedBy;
  }

  if (
    isLoading ||
    isPlaybackLoading ||
    isQuestionsLoading ||
    isReviewsLoading
  ) {
    return <Preloader />;
  }

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Video" />
      <div className="py-7 min-vh-100 flq-background d-flex align-items-center">
        <div className="flq-background-image">
          <span
            className="flq-image jarallax"
            data-speed="0.7"
            style={{ zIndex: 0 }}
          >
            <div
              id="jarallax-container-0"
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                overflow: "hidden",
                zIndex: -100,
              }}
            >
              <img
                src={video?.poster}
                className="jarallax-img"
                alt=""
                style={{
                  objectFit: "cover",
                  objectPosition: "20% 50%",
                  maxWidth: "none",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  pointerEvents: "none",
                  transformStyle: "preserve-3d",
                  backfaceVisibility: "hidden",
                  willChange: "transform, opacity",
                  marginTop: "-143.702px",
                  transform: "translate3d(0px, 143.702px, 0px)",
                }}
              />
            </div>
          </span>
        </div>
        <div
          className="flq-background-overlay"
          style={{ backgroundColor: "hsla(var(--flq-color-black), 0.9)" }}
        />

        {user && video && (
          <>
            {hasAccessToCategory(user?.subscription, video.category) ? (
              <div
                className="container pt-navbar vid-w"
                data-sr="movie"
                data-sr-interval={70}
                data-sr-duration={1200}
                data-sr-distance={10}
              >
                <div className="row gy-6 gx-6">
                  <div
                    className="col-12 col-lg-6 col-xl-5 col-xxl-4 left"
                    data-sr
                    data-sr-duration={1600}
                    data-sr-distance={10}
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "opacity 1.6s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.6s cubic-bezier(0.5, 0, 0, 1) 0s",
                      marginTop: "0",
                      paddingLeft: "0",
                      paddingRight: "0",
                    }}
                  >
                    <div className="card flq-card-image flq-card-favorites h-lg-100">
                      <div
                        style={{ position: "relative", paddingTop: "52.73%" }}
                      >
                        {playbackData && (
                          <iframe
                            title="video"
                            src={`https://player.vdocipher.com/v2/?otp=${playbackData.otp}&playbackInfo=${playbackData.playbackInfo}`}
                            style={{
                              border: "0",
                              maxWidth: "100%",
                              position: "absolute",
                              top: "0",
                              left: "0",
                              height: "100%",
                              width: "100%",
                              zIndex: "9999",
                            }}
                            allowFullScreen={true}
                            allow="encrypted-media"
                          ></iframe>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      {isFavorite ? (
                        <>
                          {removingFromFavs ? (
                            <button
                              className="btn btn-dark btn-glass btn-block favbtn"
                              data-bs-toggle="button"
                              disabled
                            >
                              <span className="btn-icon">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    fill="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="btn-name">
                                Remove from favorites
                              </span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-dark btn-glass btn-block favbtn"
                              data-bs-toggle="button"
                              onClick={() => {
                                removeVideoFromFavorites();
                              }}
                            >
                              <span className="btn-icon">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    fill="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="btn-name">
                                Remove from favorites
                              </span>
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {addingToFavs ? (
                            <button
                              className="btn btn-dark btn-glass btn-block favbtn"
                              data-bs-toggle="button"
                              disabled
                            >
                              <span className="btn-icon">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="btn-name">Add to favorites</span>
                            </button>
                          ) : (
                            <button
                              className="btn btn-dark btn-glass btn-block favbtn"
                              data-bs-toggle="button"
                              onClick={() => {
                                addVideoToFavorites();
                              }}
                            >
                              <span className="btn-icon">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="btn-name">Add to favorites</span>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 py-lg-2 flq-vertical-rhythm right">
                    <span
                      className="flq-subtitle badge badge-white badge-translucent"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                        textTransform: "capitalize",
                      }}
                    >
                      {video?.category}
                    </span>

                    <h1
                      className="mb-0"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                      }}
                    >
                      {video?.title}
                    </h1>
                    <div className="mt-3">
                      <div className="row gy-1 flq-color-opacity">
                        {video?.ratedBy > 0 && (
                          <div
                            className="col-auto"
                            data-sr-item="movie"
                            style={{
                              visibility: "visible",
                              opacity: 1,
                              transform:
                                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                              transition:
                                "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <BsStarFill style={{ color: "#c79200" }} />
                              <strong className="ms-1 flq-color-title">
                                {(video?.ratingsTotal / video?.ratedBy).toFixed(
                                  1
                                )}
                              </strong>
                            </div>
                          </div>
                        )}

                        <div
                          className="col-auto"
                          data-sr-item="movie"
                          style={{
                            visibility: "visible",
                            opacity: 1,
                            transform:
                              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                            transition:
                              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <MdOutlineSlowMotionVideo />
                            <strong className="ms-1 flq-color-title">
                              {video?.duration}m
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      className="lead"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                      }}
                    >
                      {video?.description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="container pt-navbar"
                data-sr="movie"
                data-sr-interval={70}
                data-sr-duration={1200}
                data-sr-distance={10}
              >
                <div className="row gy-6 gx-6">
                  <div
                    className="col-12 col-lg-6 col-xl-5 col-xxl-4 leftt"
                    data-sr
                    data-sr-duration={1600}
                    data-sr-distance={10}
                    style={{
                      visibility: "visible",
                      opacity: 1,
                      transform:
                        "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                      transition:
                        "opacity 1.6s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.6s cubic-bezier(0.5, 0, 0, 1) 0s",
                      marginTop: "0",
                      paddingLeft: "0",
                      paddingRight: "0",
                    }}
                  >
                    <div className="card flq-card-image flq-card-favorites h-lg-100">
                      <span className="flq-image flq-responsive">
                        <img src={video.poster} alt="poster" />
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 py-lg-2 flq-vertical-rhythm rightt">
                    <span
                      className="flq-subtitle badge badge-white badge-translucent"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                        textTransform: "capitalize",
                      }}
                    >
                      {video.category}
                    </span>
                    <h1
                      className="mb-0"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                      }}
                    >
                      {video.title}
                    </h1>
                    <div className="mt-3">
                      <div className="row gy-1 flq-color-opacity">
                        {video?.ratedBy > 0 && (
                          <div
                            className="col-auto"
                            data-sr-item="movie"
                            style={{
                              visibility: "visible",
                              opacity: 1,
                              transform:
                                "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                              transition:
                                "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <BsStarFill style={{ color: "#c79200" }} />
                              <strong className="ms-1 flq-color-title">
                                {(video?.ratingsTotal / video?.ratedBy).toFixed(
                                  1
                                )}
                              </strong>
                            </div>
                          </div>
                        )}

                        <div
                          className="col-auto"
                          data-sr-item="movie"
                          style={{
                            visibility: "visible",
                            opacity: 1,
                            transform:
                              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                            transition:
                              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <MdOutlineSlowMotionVideo />
                            <strong className="ms-1 flq-color-title">
                              {video?.duration}m
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      className="lead"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                      }}
                    >
                      {video.description}
                    </p>

                    <div className="py-1">
                      <div className="row gy-4 align-items-center">
                        <div className="col-auto" data-sr-item="movie">
                          <Link to="/plans" className="btn btn-icon-sm">
                            <span className="btn-name">Watch Now</span>
                            <FaLock style={{ height: "14px" }} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {!user && video && (
          <div
            className="container pt-navbar"
            data-sr="movie"
            data-sr-interval={70}
            data-sr-duration={1200}
            data-sr-distance={10}
          >
            <div className="row gy-6 gx-6">
              <div
                className="col-12 col-lg-6 col-xl-5 col-xxl-4 leftt"
                data-sr
                data-sr-duration={1600}
                data-sr-distance={10}
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1.6s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.6s cubic-bezier(0.5, 0, 0, 1) 0s",
                  marginTop: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                <div className="card flq-card-image flq-card-favorites h-lg-100">
                  <span className="flq-image flq-responsive">
                    <img src={video.poster} alt="poster" />
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-6 py-lg-2 flq-vertical-rhythm rightt">
                <span
                  className="flq-subtitle badge badge-white badge-translucent"
                  data-sr-item="movie"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                    textTransform: "capitalize",
                  }}
                >
                  {video.category}
                </span>
                <h1
                  className="mb-0"
                  data-sr-item="movie"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  {video.title}
                </h1>
                <div className="mt-3">
                  <div className="row gy-1 flq-color-opacity">
                    {video?.ratedBy > 0 && (
                      <div
                        className="col-auto"
                        data-sr-item="movie"
                        style={{
                          visibility: "visible",
                          opacity: 1,
                          transform:
                            "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                          transition:
                            "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <BsStarFill style={{ color: "#c79200" }} />
                          <strong className="ms-1 flq-color-title">
                            {(video?.ratingsTotal / video?.ratedBy).toFixed(1)}
                          </strong>
                        </div>
                      </div>
                    )}

                    <div
                      className="col-auto"
                      data-sr-item="movie"
                      style={{
                        visibility: "visible",
                        opacity: 1,
                        transform:
                          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                        transition:
                          "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <MdOutlineSlowMotionVideo />
                        <strong className="ms-1 flq-color-title">
                          {video?.duration}m
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <p
                  className="lead"
                  data-sr-item="movie"
                  style={{
                    visibility: "visible",
                    opacity: 1,
                    transform:
                      "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                    transition:
                      "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0s",
                  }}
                >
                  {video.description}
                </p>

                <div className="py-1">
                  <div className="row gy-4 align-items-center">
                    <div className="col-auto" data-sr-item="movie">
                      <Link to="/plans" className="btn btn-icon-sm">
                        <span className="btn-name">Watch Now</span>
                        <FaLock style={{ height: "14px" }} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="py-5">
        <div className="container flq-isotope">
          <nav>
            <ul
              className="nav nav-tabs flq-isotope-options justify-content-center text-center"
              data-sr="movie-options"
              data-sr-interval={80}
              data-sr-duration={1000}
              data-sr-distance={10}
            >
              <li
                className="nav-item"
                data-sr-item="movie-options"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                <a
                  href="#/"
                  data-filter="all"
                  className={`${
                    tabSelected === "Questions" ? "nav-link active" : "nav-link"
                  }`}
                  onClick={() => setTabSelected("Questions")}
                >
                  {questions && (
                    <span className="nav-link-name">
                      Questions{" "}
                      {questions?.pages[0].totalQuestions > 0 &&
                        `(${questions?.pages[0].totalQuestions})`}
                    </span>
                  )}
                </a>
              </li>
              <li
                className="nav-item"
                data-sr-item="movie-options"
                style={{
                  visibility: "visible",
                  opacity: 1,
                  transform:
                    "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
                  transition:
                    "opacity 1s cubic-bezier(0.5, 0, 0, 1) 0s, transform 1s cubic-bezier(0.5, 0, 0, 1) 0s",
                }}
              >
                <a
                  href="#/"
                  data-filter="action"
                  className={`${
                    tabSelected === "Reviews" ? "nav-link active" : "nav-link"
                  }`}
                  onClick={() => setTabSelected("Reviews")}
                >
                  {reviews && (
                    <span className="nav-link-name">
                      Reviews{" "}
                      {reviews?.pages[0].totalReviews > 0 &&
                        `(${reviews?.pages[0].totalReviews})`}
                    </span>
                  )}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="content-wrap">
        {tabSelected === "Questions" && (
          <div className="pb-7">
            <div className="container-small flq-vertical-rhythm">
              {questionBoxOpen ? (
                <div className="py-5">
                  <div>
                    <h3 className="mb-5">Ask a question</h3>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        askQuestion();
                      }}
                    >
                      <div
                        className="row gy-4"
                        data-sr="movie-review"
                        data-sr-interval={100}
                        data-sr-distance={10}
                        data-sr-duration={1000}
                      >
                        <div className="col-12" data-sr-item="movie-review">
                          <input
                            className="form-control flq-form-message"
                            type="text"
                            placeholder="Title/Subject"
                            required
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12" data-sr-item="movie-review">
                          <textarea
                            className="form-control flq-form-message"
                            rows={5}
                            placeholder="Ask your question"
                            required
                            value={text}
                            onChange={(e) => {
                              setText(e.target.value);
                            }}
                          />
                        </div>

                        <div
                          className="col-12 col-sm-auto"
                          data-sr-item="movie-review"
                        >
                          {askingQuestion ? (
                            <button
                              className="btn btn-block"
                              type="button"
                              disabled
                            >
                              Send
                            </button>
                          ) : (
                            <button className="btn btn-block" type="submit">
                              Send
                            </button>
                          )}
                        </div>
                        <div
                          className="col-12 col-sm-auto"
                          data-sr-item="movie-review"
                        >
                          <button
                            className="btn btn-block btn-dark"
                            type="button"
                            onClick={() => {
                              setQuestionBoxOpen(false);
                              setTitle("");
                              setText("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  {video &&
                    hasAccessToCategory(
                      user?.subscription,
                      video?.category
                    ) && (
                      <div className="card-body">
                        <button
                          className="btn btn-dark btn-glass btn-block"
                          data-bs-toggle="button"
                          onClick={() => setQuestionBoxOpen(true)}
                        >
                          <span className="btn-name">Ask a question</span>
                        </button>
                      </div>
                    )}

                  {questions && questions?.pages[0].totalQuestions > 0 ? (
                    <>
                      {questions?.pages.map((page, index) => (
                        <div key={index}>
                          <h3 className="mb-5">
                            {page.totalQuestions}{" "}
                            {page.totalQuestions > 1 ? "Questions" : "Question"}
                          </h3>
                          <div
                            className="flq-comments"
                            data-sr="movie-comment"
                            data-sr-interval={100}
                            data-sr-distance={10}
                            data-sr-duration={1000}
                          >
                            <ul>
                              {page.questions.map((question) => (
                                <li
                                  className="flq-comment"
                                  data-sr-item="movie-comment"
                                  key={question._id}
                                >
                                  <div className="flq-media">
                                    <div className="flq-media-image">
                                      {question.user?.picture ? (
                                        <span className="flq-image">
                                          <img
                                            src={question.user.picture}
                                            alt="user"
                                            style={{ borderRadius: "50%" }}
                                          />
                                        </span>
                                      ) : (
                                        <span
                                          className="flq-image"
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                          }}
                                        >
                                          {getInitials(question.user?.name)}
                                        </span>
                                      )}
                                    </div>

                                    <div className="flq-media-meta">
                                      <h5 className="flq-media-title">
                                        {question.user?.name}
                                      </h5>
                                      <div className="flq-meta">
                                        <ul>
                                          <li>
                                            {formatDistanceToNowShort(
                                              question.createdAt
                                            )}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flq-comment-content flq-vertical-rhythm">
                                    <h5
                                      className="flq-media-title"
                                      style={{ marginBottom: "4px" }}
                                    >
                                      {question.title}
                                    </h5>
                                    <p style={{ marginTop: "0" }}>
                                      {question.text}
                                    </p>
                                  </div>

                                  {video &&
                                    hasAccessToCategory(
                                      user?.subscription,
                                      video.category
                                    ) && (
                                      <button
                                        style={{
                                          background: "transparent",
                                          border: "0",
                                          color: "#bfbfbf",
                                          fontSize: "24px",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => {
                                          setQuestionToReply(question);
                                          setReplyInputOpen(true);
                                        }}
                                      >
                                        <BsReply />
                                      </button>
                                    )}
                                  {question.user?._id === user?._id && (
                                    <button
                                      style={{
                                        background: "transparent",
                                        border: "0",
                                        color: "#bfbfbf",
                                        fontSize: "22px",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        setQuestionIdToDelete(question._id);
                                        setQuestionDeleteModalOpen(true);
                                      }}
                                    >
                                      <RiDeleteBinLine />
                                    </button>
                                  )}
                                  {question.replies.length > 0 && (
                                    <button
                                      style={{
                                        background: "transparent",
                                        border: "0",
                                        color: "#bfbfbf",
                                        fontSize: "17px",
                                        marginRight: "10px",
                                      }}
                                      onClick={() => {
                                        setViewRepliesModalOpen(
                                          !viewRepliesModalOpen
                                        );
                                        setReplies(question.replies);
                                        setSelectedQuestion(question);
                                      }}
                                    >
                                      <BsChatLeft />
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {question.replies.length}
                                      </span>
                                    </button>
                                  )}

                                  {replyInputOpen &&
                                    questionToReply._id === question._id && (
                                      <>
                                        <div
                                          className="col-12"
                                          style={{
                                            marginTop: "6px",
                                            marginBottom: "12px",
                                          }}
                                          data-sr-item="movie-review"
                                        >
                                          <textarea
                                            className="form-control flq-form-message"
                                            placeholder="Reply to this question"
                                            rows={4}
                                            value={reply}
                                            onChange={(e) =>
                                              setReply(e.target.value)
                                            }
                                          />
                                        </div>

                                        {reply && (
                                          <div
                                            className="col-12 col-sm-auto"
                                            data-sr-item="movie-review"
                                          >
                                            {sendingReply ? (
                                              <button
                                                className="btn btn-block"
                                                disabled
                                              >
                                                Send
                                              </button>
                                            ) : (
                                              <button
                                                className="btn btn-block"
                                                onClick={() => {
                                                  replyQuestion();
                                                }}
                                              >
                                                Send
                                              </button>
                                            )}
                                          </div>
                                        )}
                                        <button
                                          className="btn btn-block btn-dark"
                                          onClick={() => {
                                            setReplyInputOpen(false);
                                            setReply("");
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    )}

                                  {viewRepliesModalOpen &&
                                    selectedQuestion === question && (
                                      <ul style={{ marginTop: "10px" }}>
                                        {replies?.map((reply, index) => (
                                          <li
                                            key={index}
                                            style={{
                                              listStyle: "none",
                                              borderTop: "1px solid #323234",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <div className="flq-media">
                                              <div className="flq-media-image">
                                                {reply.user?.picture ? (
                                                  <span className="flq-image">
                                                    <img
                                                      src={reply?.user?.picture}
                                                      alt="user"
                                                      style={{
                                                        borderRadius: "50%",
                                                      }}
                                                    />
                                                  </span>
                                                ) : (
                                                  <span
                                                    className="flq-image"
                                                    style={{
                                                      height: "100%",
                                                      width: "100%",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    {getInitials(
                                                      reply.user?.name
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="flq-media-meta">
                                                <h5 className="flq-media-title">
                                                  {reply?.user?.name}
                                                </h5>
                                                <div className="flq-meta">
                                                  <ul>
                                                    <li>
                                                      {formatDistanceToNowShort(
                                                        reply.repliedOn
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="flq-comment-content flq-vertical-rhythm">
                                              <p style={{ marginTop: "8px" }}>
                                                {reply.text}
                                              </p>
                                            </div>
                                            {reply?.user?._id === user?._id && (
                                              <button
                                                style={{
                                                  background: "transparent",
                                                  border: "0",
                                                  color: "#bfbfbf",
                                                  fontSize: "20px",
                                                  marginRight: "10px",
                                                }}
                                                onClick={() => {
                                                  setReplyToDelete(reply);
                                                  setDeleteReplyModalOpen(true);
                                                }}
                                              >
                                                <RiDeleteBinLine />
                                              </button>
                                            )}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  <hr />
                                </li>
                              ))}
                            </ul>

                            {hasMoreQuestions && (
                              <div className="card-body">
                                {isFetchingMoreQuestions ? (
                                  <button
                                    className="btn btn-dark btn-glass btn-block"
                                    data-bs-toggle="button"
                                    style={{ width: "80%", margin: "auto" }}
                                    disabled
                                  >
                                    <span className="btn-name">
                                      Showing More Questions...
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-dark btn-glass btn-block"
                                    data-bs-toggle="button"
                                    style={{ width: "80%", margin: "auto" }}
                                    onClick={() => {
                                      fetchMoreQuestions();
                                    }}
                                  >
                                    <span className="btn-name">
                                      Show More Questions
                                    </span>
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p style={{ marginTop: "80px", textAlign: "center" }}>
                      No questions yet
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {tabSelected === "Reviews" && (
          <div className="pb-7">
            <div className="container-small flq-vertical-rhythm">
              {reviewBoxOpen ? (
                <div className="py-5">
                  <div>
                    <h3 className="mb-5">Submit a review</h3>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!rating || +rating < 1 || +rating > 10) {
                          setRatingEmpty(true);
                          return;
                        } else {
                          setRatingEmpty(false);
                        }
                        submitReview();
                      }}
                    >
                      <div
                        className="row gy-4"
                        data-sr="movie-review"
                        data-sr-interval={100}
                        data-sr-distance={10}
                        data-sr-duration={1000}
                      >
                        <div className="col-12" data-sr-item="movie-review">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Rate this lesson over 10"
                            required
                            value={rating}
                            onChange={(e) => {
                              setRating(e.target.value);
                              setRatingEmpty(false);
                            }}
                          />
                          {ratingEmpty && (
                            <p className="text-center text-danger">
                              Please enter a number between 1 and 10
                            </p>
                          )}
                        </div>
                        <div className="col-12" data-sr-item="movie-review">
                          <textarea
                            className="form-control flq-form-message"
                            rows={5}
                            placeholder="Your review"
                            required
                            value={reviewText}
                            onChange={(e) => {
                              setReviewText(e.target.value);
                            }}
                          />
                        </div>

                        <div
                          className="col-12 col-sm-auto"
                          data-sr-item="movie-review"
                        >
                          {reviewing ? (
                            <button
                              className="btn btn-block"
                              type="button"
                              disabled
                            >
                              Send
                            </button>
                          ) : (
                            <button className="btn btn-block" type="submit">
                              Send
                            </button>
                          )}
                        </div>
                        <div
                          className="col-12 col-sm-auto"
                          data-sr-item="movie-review"
                        >
                          <button
                            className="btn btn-block btn-dark"
                            type="button"
                            onClick={() => setReviewBoxOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <>
                  {video &&
                    hasAccessToCategory(user?.subscription, video.category) && (
                      <div className="card-body">
                        <button
                          className="btn btn-dark btn-glass btn-block"
                          data-bs-toggle="button"
                          onClick={() => setReviewBoxOpen(true)}
                        >
                          <span className="btn-name">Rate this lesson</span>
                        </button>
                      </div>
                    )}

                  {reviews && reviews?.pages[0].totalReviews > 0 ? (
                    <>
                      <h3
                        style={{
                          fontSize: "60px",
                          color: "#c79200",
                          marginBottom: "3px",
                        }}
                      >
                        {(Math.round(videoRating * 10) / 10)?.toFixed(1)}
                      </h3>
                      <h3 className="mb-5 mt-0" style={{ fontSize: "18px" }}>
                        {reviews.pages[0].totalReviews}{" "}
                        {reviews.pages[0].totalReviews > 1
                          ? "Reviews"
                          : "Review"}
                      </h3>
                      {reviews.pages.map((page, index) => (
                        <div
                          className="flq-comments"
                          data-sr="movie-comment"
                          data-sr-interval={100}
                          data-sr-distance={10}
                          data-sr-duration={1000}
                        >
                          <ul>
                            {page.reviews.map((review) => (
                              <li
                                className="flq-comment"
                                data-sr-item="movie-comment"
                                key={review._id}
                              >
                                <div className="flq-media">
                                  <div className="flq-media-image">
                                    {review.user?.picture ? (
                                      <span className="flq-image">
                                        <img
                                          src={review.user?.picture}
                                          alt="user"
                                          style={{ borderRadius: "50%" }}
                                        />
                                      </span>
                                    ) : (
                                      <span
                                        className="flq-image"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        {getInitials(review.user?.name)}
                                      </span>
                                    )}
                                  </div>

                                  <div className="flq-media-meta">
                                    <h5 className="flq-media-title">
                                      {review.user?.name}
                                    </h5>
                                    <div className="flq-meta">
                                      <ul>
                                        <li>
                                          {formatDistanceToNowShort(
                                            review.createdAt
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="flq-rating">
                                  {review.rating === 1 && (
                                    <div className="flq-rating">
                                      <BsStarHalf
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 2 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 3 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 4 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 5 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 6 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar style={{ marginRight: "4px" }} />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 7 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 8 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStar />
                                    </div>
                                  )}
                                  {review.rating === 9 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarHalf
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {review.rating === 10 && (
                                    <div className="flq-rating">
                                      <BsStarFill
                                        style={{
                                          color: "#c79200",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                      <BsStarFill
                                        style={{
                                          marginRight: "4px",
                                          color: "#c79200",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="flq-comment-content flq-vertical-rhythm">
                                  <p>{review.text}</p>
                                </div>
                                <hr />
                              </li>
                            ))}
                          </ul>

                          {hasMoreReviews && (
                            <div className="card-body">
                              {isFetchingMoreReviews ? (
                                <button
                                  className="btn btn-dark btn-glass btn-block"
                                  data-bs-toggle="button"
                                  style={{ width: "80%", margin: "auto" }}
                                  disabled
                                >
                                  <span className="btn-name">
                                    Showing More Reviews...
                                  </span>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-dark btn-glass btn-block"
                                  data-bs-toggle="button"
                                  style={{ width: "80%", margin: "auto" }}
                                  onClick={() => {
                                    fetchMoreReviews();
                                  }}
                                >
                                  <span className="btn-name">
                                    Show More Reviews
                                  </span>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <p style={{ marginTop: "80px", textAlign: "center" }}>
                      No reviews yet
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        {video?.category !== "special" &&
          video &&
          nextVideos &&
          nextVideos.length > 0 && (
            <div className="pb-7">
              <div
                className="container"
                data-sr="related"
                data-sr-interval={100}
                data-sr-duration={1000}
                data-sr-distance={10}
              >
                <div className="row gy-5 flq-isotope-grid">
                  <h3 className="my-0">
                    Next Videos in {capitalizeFirstLetter(video.category)}{" "}
                    session
                  </h3>

                  {nextVideos.map((video, index) => (
                    <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 flq-isotope-item"
                      data-filters="all"
                      key={index}
                    >
                      <div data-sr-item="tvshow-item">
                        <div className="card flq-card-blog">
                          <div className="card-img-wrap">
                            <Link to={`/video/${video.videoId}`}>
                              <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                                <img
                                  src={video.poster}
                                  alt="poster"
                                  style={{ objectPosition: "20% 50%" }}
                                />
                              </span>
                            </Link>
                          </div>
                          <div className="card-body">
                            <h5 className="card-title h6">
                              <Link to={`/video/${video.videoId}`}>
                                {video.title}
                              </Link>
                            </h5>
                            <div className="flq-meta flq-meta-sm">
                              <ul>
                                <li>
                                  <a href="#/" className="card-year">
                                    {video.duration}m
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#/"
                                    className="card-category"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {video.category}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Video" />
      <NavbarUser page="Video" />

      {deleteReplyModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to delete this reply?
            </h2>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                <div className="col-12 col-sm-auto">
                  {isDeletingReply ? (
                    <button className="btn btn-dark btn-block" disabled>
                      Deleting...
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        deleteQuestionReply();
                      }}
                    >
                      Yes, Continue
                    </button>
                  )}
                </div>
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => setDeleteReplyModalOpen(false)}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {questionDeleteModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to delete this question?
            </h2>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                <div className="col-12 col-sm-auto">
                  {isDeletingQuestion ? (
                    <button className="btn btn-dark btn-block" disabled>
                      Deleting...
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        deleteVideoQuestion();
                      }}
                    >
                      Yes, Continue
                    </button>
                  )}
                </div>
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => setQuestionDeleteModalOpen(false)}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Video;
