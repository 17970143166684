import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarTop from "../components/NavbarTop";
import NavbarUser from "../components/NavbarUser";

const Terms = () => {
  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Terms" />
      <div className="py-7 flq-background-color-100">
        <div className="container pt-navbar">
          <h1 className="display-3 mb-0">Terms of Use</h1>
        </div>
      </div>
      <div className="content-wrap">
        <div className="container py-7">
          <h2 className="mb-3">1. Welcome to Think Like a Pianist!</h2>
          <h2 className="mb-3">1.1 Introduction:</h2>
          <p className="mb-2">
            Thinklikeapianist (“Thinklikeapianist”, “TLP”, “we”, “us”, “our”)
            provides its services (described below) to you through its website
            located at thinklikeapianist.com (the “Site”) and the Site, the
            “Service(s)”, subject to the following Terms of Use (the “Terms of
            Service”).
          </p>
          <p className="mb-5">
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY GOVERN YOUR
            USE OF THE SITE AND SERVICES.
          </p>

          <h2 className="mb-3">1.2 Modifications to Terms of Service:</h2>
          <p className="mb-5">
            We reserve the right, at our sole discretion, to change or modify
            portions of this Terms of Service at any time. If we do this,
            depending on the nature of the change, we will post the changes on
            this page and indicate at the top of this page the date these terms
            were last revised and/or notify you, either through the Services'
            user interface, in an email notification or through other reasonable
            means. Any such changes will become effective no earlier than
            fourteen (14) days after they are posted, except that changes
            addressing new functions of the Services or changes made for legal
            reasons will be effective immediately. Your continued use of the
            Service after the date any such changes become effective constitutes
            your acceptance of the new Terms of Service. In addition, when using
            certain Services, you will be subject to any additional terms
            applicable to such Services that may be posted on the Service from
            time to time.
          </p>

          <h2 className="mb-3">1.3 Privacy:</h2>
          <p className="mb-5">
            At Thinklikeapianist, we respect the privacy of our users. For
            details please see our{" "}
            <Link className="text-gold" to="/privacy-policy">
              Privacy Policy
            </Link>
            . By using the Service, you consent to our collection and use of
            personal data as outlined therein.
          </p>

          <h2 className="mb-3">2. Access and Use of the Service</h2>
          <h2 className="mb-3">2.1 Use Description:</h2>
          <p className="mb-5">
            The Thinklikeapianist service and any content viewed through our
            service, is solely for your personal and non-commercial use. With
            your Thinklikeapianist purchase we grant you a limited,
            non-exclusive, non-transferable, license to access the
            Thinklikeapianist content and view your course(s) through the
            service on a streaming-only basis for that purpose. Except for the
            foregoing limited license, no right, title or interest shall be
            transferred to you. You agree not to use the service for public
            performances. Thinklikeapianist may revoke your license at any time
            in its sole discretion. Upon such revocation, you must promptly
            destroy all content downloaded or otherwise obtained through the
            service, as well as copies of such materials, whether made in
            accordance with these Terms of Service or otherwise.
          </p>

          <h2 className="mb-3">2.2 Your Registration Obligations:</h2>
          <p className="mb-5">
            You will be required to register with Thinklikeapianist in order to
            access and use certain features of the Service. If you choose to
            register for the Service, you agree to provide and maintain true,
            accurate, current and complete information about yourself as
            prompted by the Service’s registration form. Registration data and
            certain other information about you are governed by our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </p>

          <h2 className="mb-3">2.3 Member Account, Password and Security:</h2>
          <p className="mb-5">
            You may never use another's account and you may not provide another
            person with the email address and password to access your account.
            You should maintain control over all of the devices that are used to
            access the Service. If you fail to maintain control of a device,
            other users may access the Service through your account and may be
            able to access certain of your account information. You are fully
            responsible for any and all activities that occur under your
            password or account and it is your responsibility to ensure that
            your password remains confidential and secure. You agree to (a)
            immediately notify Thinklikeapianist of any unauthorized use of your
            password or account or any other breach of security and (b) ensure
            that you exit from your account at the end of each session when
            accessing the Service. Thinklikeapianist will not be liable for any
            loss or damage arising from your failure to comply with this
            Section.
          </p>

          <h2 className="mb-3">2.4 Modifications to Service:</h2>
          <p className="mb-5">
            Thinklikeapianist reserves the right to modify or discontinue,
            temporarily or permanently, the Service (or any part thereof) with
            or without notice. You agree that Thinklikeapianist will not be
            liable to you or to any third party for any modification, suspension
            or discontinuation of the Service. We have no obligation to retain
            any of Your Account or Submitted Content for any period of time
            beyond what may be required by applicable law.
          </p>

          <h2 className="mb-3">
            2.5 General Practices Regarding Use and Storage:
          </h2>
          <p className="mb-5">
            You acknowledge that Thinklikeapianist may establish general
            practices and limits concerning use of the Service, including
            without limiting the maximum period of time that data or other
            content will be retained by the Service and the maximum storage
            space that will be allotted on Thinklikeapianist’s servers on your
            behalf. You agree that Thinklikeapianist has no responsibility or
            liability for the deletion or failure to store any data or other
            content maintained or uploaded by the Service.
          </p>

          <h2 className="mb-3">2.6 Mobile Services:</h2>
          <p className="mb-5">
            The Service may include certain services that are available via a
            mobile device, including (i) the ability to browse the Service and
            the Site from a mobile device and to the extent you access the
            Service through a mobile device, your wireless service carrier’s
            standard charges, data rates and other fees may apply.
          </p>

          <h2 className="mb-3">3. Conditions of Use</h2>
          <h2 className="mb-3">3.1 User Conduct:</h2>
          <p className="mb-0">
            You are solely responsible for all code, video, images, information,
            data, text, software, music, sound, photographs, graphics, messages
            or other materials (“content”) that you upload, post, publish or
            display (hereinafter, “upload”) or email or otherwise use via the
            Service. Thinklikeapianist reserves the right to investigate and
            take appropriate legal action against anyone who, in
            Thinklikeapianist's sole discretion, violates this provision,
            including without limitation, removing the offending content from
            the Service, suspending or terminating the account of such violators
            and reporting you to the law enforcement authorities. You agree to
            not use the Service to:
          </p>
          <ul className="mb-5">
            <li>
              email or otherwise upload any content that (i) infringes any
              intellectual property or other proprietary rights of any party;
              (ii) you do not have a right to upload under any law or under
              contractual or fiduciary relationships; (iii) contains software
              viruses or any other computer code, files or programs designed to
              interrupt, destroy or limit the functionality of any computer
              software or hardware or telecommunications equipment; (iv) poses
              or creates a privacy or security risk to any person; (v)
              constitutes unsolicited or unauthorized advertising, promotional
              materials, commercial activities and/or sales, “junk mail”,
              “spam”, “chain letters”, “pyramid schemes”, “contests”,
              “sweepstakes” or any other form of solicitation; (vi) is unlawful,
              harmful, threatening, abusive, harassing, tortious, excessively
              violent, defamatory, vulgar, obscene, pornographic, libelous,
              invasive of another’s privacy, hateful racially, ethnically or
              otherwise objectionable; or (vii) in the sole judgment of
              Thinklikeapianist, is objectionable or which restricts or inhibits
              any other person from using or enjoying the Service, or which may
              expose Thinklikeapianist or its users to any harm or liability of
              any type;
            </li>
            <li>
              interfere with or disrupt the Service or servers or networks
              connected to the Service, or disobey any requirements, procedures,
              policies or regulations of networks connected to the Service;
            </li>
            <li>
              violate any applicable local, state, national or international law
              or any regulations having the force of law;
            </li>
            <li>
              impersonate any person or entity or falsely state or otherwise
              misrepresent your affiliation with a person or entity; solicit
              personal information from anyone under the age of 18;
            </li>
            <li>
              harvest or collect email addresses or other contact information of
              other users from the Service by electronic or other means for the
              purposes of sending unsolicited emails or other unsolicited
              communications;
            </li>
            <li>
              advertise or offer to sell or buy any goods or services for any
              business purpose that is not specifically authorized;
            </li>
            <li>
              further or promote any criminal activity or enterprise or provide
              instructional information about illegal activities; or
            </li>
            <li>
              obtain or attempt to access or otherwise obtain any materials or
              information through any means not intentionally made available or
              provided for through the Service.
            </li>
          </ul>

          <h2 className="mb-3">3.2 Fees:</h2>
          <p className="mb-2">
            To the extent the Service or any portion thereof is made available
            for any fee, you will be required to select a payment plan and
            provide Thinklikeapianist information regarding your credit/debit
            card or other payment instrument. You represent and warrant to
            Thinklikeapianist that such information is true and that you are
            authorized to use the payment instrument. You will promptly update
            your account information with any changes (for example, a change in
            your billing address or credit card expiration date) that may occur.
            You agree to Thinklikeapianist the amount that is specified in the
            payment plan in accordance with the terms of such plan and this
            Terms of Service. You hereby authorize Thinklikeapianist to bill
            your payment instrument in accordance with the terms of the
            applicable payment plan (as well as any applicable taxes) until you
            terminate your account and you further agree to pay any charges so
            incurred. If you dispute any charges you must let Thinklikeapianist
            know within sixty (60) days after the date that Thinklikeapianist
            charges you. We reserve the right to change the payment plan of
            courses on Thinklikeapianist.
          </p>
          <p className="mb-5">
            Please note that Thinklikeapianist does not store your payment
            details. Thinklikeapianist makes use of a third-party payment
            gateway responsible for handling all matters regarding payment.
          </p>

          <h2 className="mb-3">3.3 Recurring Subscriptions:</h2>
          <p className="mb-2">
            If you select a Service with an auto renewal feature (“Recurring
            Subscription”), you authorize Thinklikeapianist to maintain your
            account information and charge that account automatically upon the
            renewal of the Service you choose with no further action required by
            you. In the event that Thinklikeapianist is unable to charge your
            account as authorized by you when you enrolled in a Recurring
            Subscription, Thinklikeapianist, may, in its sole discretion: (i)
            bill you for your Service and suspend your access to the Service
            until payment is received, and/or (ii) seek to update your account
            information through third party sources (i.e., your bank or a
            payment processor) to continue charging your account as authorized
            by you.
          </p>
          <p className="mb-2">
            Thinklikeapianist may change the price for Recurring Subscriptions
            from time to time and will communicate any price changes to you in
            advance and, if applicable, how to accept those changes. Price
            changes for Recurring Subscriptions will take effect at the start of
            the next subscription period following the date of the price change.
            You accept the new price by continuing to use your Recurring
            Subscription after the price change takes effect. If you do not
            agree with the price changes, you have the right to reject the
            change by cancelling your Recurring Subscription before the price
            change goes into effect. Please therefore make sure you read any
            such notification of price changes carefully.
          </p>
          <p className="mb-2">
            {" "}
            Payments are non-refundable and there are no refunds or credits for
            partially used periods. You may cancel a Recurring Subscription at
            any time, but if you cancel your subscription before the end of the
            current subscription period, we will not refund any subscription
            fees already paid to us. Following any cancellation, however, you
            may continue to have access to the service through the end of your
            current subscription period. At any time, and for any reason, we may
            provide a refund, discount, or other consideration to some or all of
            our users ("credits"). The amount and form of such credits, and the
            decision to provide them, are at our sole and absolute discretion.
            The provision of credits in one instance does not entitle you to
            credits in the future for similar instances, nor does it obligate us
            to provide credits in the future, under any circumstance.
          </p>
          <p className="mb-5">
            If you subscribed on our website, you can cancel by contacting
            Support at{" "}
            <a href="mailto:contact@thinklikeapianist.com">
              contact@thinklikeapianist.com
            </a>{" "}
            or by going to Subscription in your profile page, selecting Cancel
            under the Subscription box and then following the onscreen
            instructions to continue and cancel subscription.
          </p>

          <h2 className="mb-3">3.4 Commercial Use:</h2>
          <p className="mb-5">
            Unless otherwise expressly authorized herein or by Thinklikeapianist
            in writing, you agree not to display, distribute, license, perform,
            publish, reproduce, duplicate, copy, create derivative works from,
            modify, sell, resell, exploit, transfer or upload for any commercial
            purposes, any portion of the Service, use of the Service or access
            to the Service. The Service is solely for your non-commercial,
            personal, entertainment use.
          </p>

          <h2 className="mb-3">4. Intellectual Property Rights</h2>
          <h2 className="mb-3">
            4.1 Service Content, Software and Trademarks:
          </h2>
          <p className="mb-5">
            You acknowledge and agree that the Service may contain content or
            features (“Service Content”) that are protected by copyright,
            patent, trademark, trade secret or other proprietary rights and
            laws. Except as expressly authorized by Thinklikeapianist, you agree
            not to modify, copy, frame, scrape, rent, lease, loan, sell,
            distribute or create derivative works based on the Service or the
            Service Content, in whole or in part, except that the foregoing does
            not apply to your own User Content (as defined below) that you
            legally upload to the Service. In connection with your use of the
            Service, you will not engage in or use any data mining, robots,
            scraping or similar data gathering or extraction methods. If you are
            blocked by Thinklikeapianist from accessing the Service (including
            blocking your IP address), you agree not to implement any measures
            to circumvent such blocking (e.g. by masking your IP address or
            using a proxy IP address). Any use of the Service or the Service
            Content other than as specifically authorized herein is strictly
            prohibited. You agree not to copy, modify, create a derivative work
            of, reverse engineer, reverse assemble or otherwise attempt to
            discover any source code, sell, assign, sublicense, or otherwise
            transfer any right in the Software. Any rights not expressly granted
            herein are reserved by Thinklikeapianist.
          </p>
          <p className="mb-5">
            The Thinklikeapianist name and logos are trademarks and service
            marks of Thinklikeapianist (collectively the “Thinklikeapianist
            Trademarks”). Other Thinklikeapianist products, service names and
            logos used and displayed via the Service may be trademarks or
            service marks of their respective owners who may or may not endorse
            or be affiliated with or connected to Thinklikeapianist. Nothing in
            this Terms of Service or the Service should be construed as
            granting, by implication, estoppel, or otherwise, any license or
            right to use any of Thinklikeapianist Trademarks displayed on the
            Service, without our prior written permission in each instance. All
            goodwill generated from the use of Thinklikeapianist Trademarks will
            inure to our exclusive benefit.
          </p>

          <h2 className="mb-3">4.2 Third Party Material:</h2>
          <p className="mb-5">
            Under no circumstances will Thinklikeapianist be liable in any way
            for any content or materials of any third parties (including users),
            including, but not limited to, for any errors or omissions in any
            content, or for any loss or damage of any kind incurred as a result
            of the use of any such content. You acknowledge that
            Thinklikeapianist does not pre-screen content but that
            Thinklikeapianist and its designees will have the right (but not the
            obligation) in their sole discretion to refuse or remove any content
            that is available via the Service. Without limiting the foregoing,
            Thinklikeapianist and its designees will have the right to remove
            any content that violates these Terms of Service or is deemed by
            Thinklikeapianist in its sole discretion, to be otherwise
            objectionable. You agree that you must evaluate and bear all risks
            associated with the use of any content, including any reliance on
            the accuracy, completeness or usefulness of such content.
          </p>

          <h2 className="mb-3">
            4.3 User Content Transmitted Through the Service:
          </h2>
          <p className="mb-2">
            With respect to the content or other materials you upload through
            the Service or share with other users or recipients (collectively,
            “User Content”), you represent and warrant that you own all rights,
            title and interest in and to such User Content including without
            limitation, all copyrights and rights of publicity contained
            therein, and that you have all required rights to post or transmit
            such content or other materials without violation of any third-party
            rights.
          </p>
          <p className="mb-2">
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback or other information relevant to the Service
            (“Submissions”) provided by you to Thinklikeapianist are
            non-confidential and Thinklikeapianist will be entitled to the
            unrestricted use and dissemination of these Submissions for any
            purpose, commercial or otherwise without acknowledgment or
            compensation to you.
          </p>
          <p className="mb-5">
            You acknowledge and agree that Thinklikeapianist may preserve
            content and may also disclose content if required to do so by law or
            in the good faith belief that such preservation or disclosure is
            reasonably necessary to: (a) comply with legal process, applicable
            laws or government requests; (b) enforce these Terms of Service; (c)
            respond to claims that any content violates the rights of third
            parties;
          </p>

          <h2 className="mb-3">4.4 Copyright Complaints:</h2>
          <p className="mb-0">
            Thinklikeapianist respects the intellectual property of others. If
            you believe in good faith that any materials on the Site infringes
            upon your copyrights, please send the following information to
            Thinklikeapianist at{" "}
            <a href="mailto:contact@thinklikeapianist.com">
              contact@thinklikeapianist.com
            </a>
            :
          </p>
          <ul className="mb-5">
            <li>
              Identification of the copyrighted work claimed to have been
              infringed or if multiple copyrighted works at a single online site
              are covered by a single notification, a representative list of
              such works at that site;
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled and information reasonably
              sufficient to permit the service provider to locate the material;
            </li>
            <li>Your address, telephone number and email address;</li>
            <li>
              A statement by you that you have a good faith belief that use of
              the material in the manner complained of is not authorized by the
              copyright owner, its agent or the law;
              <br />a statement by you, made under penalty of perjury, that the
              information in your notification is accurate, and that you are the
              copyright owner or are authorized to act on the copyright owner’s
              behalf;
            </li>
            <li>
              A physical or electronic signature of the copyright owner or the
              person authorized to act on behalf of the owner of the copyright
              interest.
            </li>
          </ul>

          <h2 className="mb-3">4.5 Counter-Notice:</h2>
          <p className="mb-2">
            If you believe that your User Content that was removed (or to which
            access was disabled) is not infringing or that you have the
            authorization from the copyright owner, the copyright owner’s agent,
            or pursuant to the law to upload and use the content in your User
            Content, you may send a written counter-notice containing the
            following information to the Copyright Agent: your physical or
            electronic signature; identification of the content that has been
            removed or to which access has been disabled and the location at
            which the content appeared before it was removed or disabled, a
            statement that you have a good faith belief that the content was
            removed or disabled as a result of mistake or a misidentification of
            the content, your name, address, telephone number, email address, a
            statement that you consent to the jurisdiction of the court located
            within Lagos, Nigeria and a statement that you will accept service
            of process from the person who provided notification of the alleged
            infringement.
          </p>
          <p className="mb-5">
            If a counter-notice is received by Thinklikeapianist, we will send a
            copy of the counter-notice to the original complaining party
            informing that person that it may replace the removed content or
            cease disabling it in 10 business days. Unless the copyright owner
            files an action seeking a court order against the content provider,
            member or user, the removed content may be replaced, or access to it
            restored, in 10 to 14 business days or more after receipt of the
            counter-notice at our sole discretion.
          </p>

          <h2 className="mb-3">4.6 Repeat Infringer Policy:</h2>
          <p className="mb-5">
            Thinklikeapianist has adopted a policy of terminating, in
            appropriate circumstances and at Thinklikeapianist's sole
            discretion, users who are deemed to be repeat infringers.
            Thinklikeapianist may also at its sole discretion limit access to
            the Service and/or terminate the memberships of any users who
            infringe any intellectual property rights of others, whether or not
            there is any repeat infringement.
          </p>

          <h2 className="mb-3">5. Social Networking Services</h2>
          <p className="mb-2">
            You may enable or log in to the Service via various online
            third-party services such as social media and social networking
            services like Facebook or Twitter (“Social Networking Services”). By
            logging in or directly integrating these Social Networking Services
            into the Service, we make your online experiences richer and more
            personalized. To take advantage of this feature and capabilities, we
            may ask you to authenticate, register for or log into Social
            Networking Services on the websites of their respective providers.
            As part of such integration, the Social Networking Services will
            provide us with access to certain information that you have provided
            to such Social Networking Services, and we will use, store and
            disclose such information in accordance with our Privacy Policy. For
            more information about the implications of activating these Social
            Networking Services and Thinklikeapianist's use, storage and
            disclosure of information related to you and your use of such
            services within Thinklikeapianist (including your friend lists and
            the like), please see our{" "}
            <Link to="/privacy-policy" className="text-gold">
              Privacy Policy
            </Link>
            .
          </p>
          <p className="mb-2">
            However, please remember that the manner in which Social Networking
            Services use, store and disclose your information is governed solely
            by the policies of such third parties and Thinklikeapianist shall
            have no liability or responsibility for the privacy practices or
            other actions of any third party site or service that may be enabled
            within the Service.
          </p>
          <p className="mb-5">
            In addition, Thinklikeapianist is not responsible for the accuracy,
            availability or reliability of any information, content, goods,
            data, opinions, advice or statements made available in connection
            with Social Networking Services. As such, Thinklikeapianist is not
            liable for any damage or loss caused or alleged to be caused by or
            in connection with use of or reliance on any such Social Networking
            Services. Thinklikeapianist enables these features merely as a
            convenience and the integration or inclusion of such features do not
            imply an endorsement or recommendation.
          </p>

          <h2 className="mb-3">6. Indemnity and Release</h2>
          <p className="mb-5">
            To the fullest extent permitted by law, you agree to release,
            indemnify and hold Thinklikeapianist and its affiliates and their
            officers, employees, directors and agent harmless from any and all
            losses, damages, expenses, including reasonable attorneys’ fees,
            rights, claims, actions of any kind arising out of or relating to
            your use of the Service, any User Content, your connection to the
            Service, your violation of these Terms of Service or your violation
            of any rights of another.
          </p>

          <h2 className="mb-3">7. Disclaimer of Warranties</h2>
          <p className="mb-2">
            YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS OTHERWISE
            EXPRESSLY PROVIDED HEREIN, THINKLIKEAPIANIST EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY,
            INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
            NON-INFRINGEMENT.
          </p>
          <p className="mb-5">
            THINKLIKEAPIANIST MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET
            YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
            SECURE OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM
            THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE
            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL
            PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR
            EXPECTATIONS.
          </p>

          <h2 className="mb-3">8. Limitation of Liability</h2>
          <p className="mb-2">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THINKLIKEAPIANIST WILL NOT
            BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
            LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES (EVEN IF THINKLIKEAPIANIST HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE
            USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF
            PROCUREMENT OF SUBSTITUTE SERVICES RESULTING FROM ANY GOODS, DATA,
            INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED
            OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III)
            UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
            (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V)
            ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL
            THINKLIKEAPIANIST’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES
            OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID
            THINKLIKEAPIANIST IN THE LAST SIX (6) MONTHS.
          </p>
          <p className="mb-5">
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES
            OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
            CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS
            SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH
            ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
          </p>

          <h2 className="mb-3">9. Binding Arbitration; Class Action Waiver</h2>
          <p className="mb-2">
            You and Thinklikeapisnist agree that these Terms of Service affect
            interstate commerce and that the Arbitration and Conciliation Act
            CAP A18 Laws of the Federation (ACA) which is the primary
            legislation applying to Arbitration in Nigeria, which substantially
            conforms with the UNCITRAL Model Law shall govern the arbitration
            process.
          </p>
          <p className="mb-2">
            If you have any dispute with us, you agree that before taking any
            formal action, you will contact us at{" "}
            <a href="mailto:contact@thinklikeapianist.com">
              contact@thinklikeapianist.com
            </a>
            and provide a brief, written description of the dispute and your
            contact information (including your email address, if your dispute
            relates to an account). Except for intellectual property and small
            court claims, the parties agree to use their best efforts to settle
            any dispute, claim, question, or disagreement directly through
            consultation and good faith negotiations which shall be a condition
            to either party initiating a lawsuit or arbitration.
          </p>
          <p className="mb-2">
            All disputes, claims or controversies arising out of or relating to
            the Terms of Service or the Service that are not resolved by the
            procedures identified above shall be resolved by individual (not
            group) binding arbitration. For residents outside Nigeria,
            arbitration will be held in Lagos, Nigeria and you and
            Thinklikeapianist agree to submit to the personal jurisdiction of
            any federal or state court in Lagos, Nigeria in order to compel
            arbitration, stay proceedings pending arbitration, or to confirm,
            modify, vacate, or enter judgment on the award entered by the
            arbitrator.
          </p>
          <p className="mb-2">
            The arbitrator and not any federal, state or local court or agency
            shall have exclusive authority to resolve all disputes arising out
            of or relating to the interpretation, applicability, enforceability,
            or formation of these Terms of Service, including but not limited to
            any claim that all or any part of these Terms of Services are void
            or voidable or whether a claim is subject to arbitration. The
            arbitrator shall be empowered to grant whatever relief would be
            available in a court under law or in equity. The arbitrator's award
            shall be written and shall be binding on the parties and may be
            entered as a judgment in any court of competent jurisdiction.
          </p>
          <p className="mb-2">
            You are responsible for all costs that you may incur in the
            arbitration including but not limited to attorneys’ fees and expert
            witness costs unless Thinklikeapianist is otherwise specifically
            required to pay such fees under applicable law.
          </p>
          <p className="mb-2">
            You and we agree that the arbitration shall be conducted in the
            party’s respective individual capacities only and not as a class
            action or other representative action and the parties expressly
            waive their right to file a class action or seek relief on a class
            basis.
            <br />
            YOU AND THINKLIKEAPIANIST AGREE THAT EACH MAY BRING CLAIMS AGAINST
            THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
            PROCEEDING.
            <br />
            If any court or arbitrator determines that the class action waiver
            set forth in this paragraph is void or unenforceable for any reason
            or that an arbitration can proceed on a class basis, then the
            arbitration provisions set forth above shall be deemed null and void
            in their entirety and the parties shall be deemed to have not agreed
            to arbitrate disputes.
          </p>
          <p className="mb-2">
            Notwithstanding the parties' decision to resolve all disputes
            through arbitration, you or we may bring enforcement actions,
            validity determinations or claims arising from or relating to theft,
            piracy or unauthorized use of intellectual property in state or
            federal court with jurisdiction to protect intellectual property
            rights ("intellectual property rights" means patents, copyrights,
            moral rights, trademarks, and trade secrets, but not privacy or
            publicity rights). You or we may also seek relief in a small claims
            court for disputes or claims within the scope of that court's
            jurisdiction to the extent such claims do not seek equitable relief.
          </p>
          <p className="mb-2">
            You have the right to opt out and not be bound by the arbitration
            and class action waiver provisions set forth above by sending
            written notice of your decision to opt out to Thinklikeapianist at
            the address identified in Section 13 below. The notice must be sent
            within thirty (30) days of (a) the “Date of Last Revision” date of
            these Terms of Service; or (b) your first date that you used the
            Services that contained any versions of the Terms of Service that
            included this version of the mandatory arbitration and class action
            waiver, whichever is later. Otherwise you shall be bound to
            arbitrate disputes in accordance with the terms of these paragraphs.
            If you opt out of these arbitration provisions, Thinklikeapianist
            also will not be bound by them. If Thinklikeapianist changes this
            ‘Arbitration’ section after the date you first accepted these Terms
            of Service (or accepted any subsequent changes to these Terms of
            Service), you may reject any such change by sending us written
            notice within 30 days of the date such change became effective, as
            indicated in the “Date of Last Revision” or in the date of
            Thinklikeapianist's email to you notifying you of such change. By
            rejecting any change, you are agreeing that you will arbitrate any
            dispute between you and Thinklikeapianist in accordance with the
            provisions of this section as of the date you first accepted these
            Terms of Service (or accepted any subsequent changes to these Terms
            of Service).
          </p>
          <p className="mb-5">
            YOU UNDERSTAND AND AGREE THAT THE ABOVE DISPUTE PROCEDURES SHALL BE
            YOUR SOLE REMEDY IN THE EVENT OF DISPUTE BETWEEN YOU AND
            THINKLIKEAPIANIST REGARDING ANY ASPECT OF THE SERVICE (INCLUDING THE
            ENROLMENT PROCESS) AND THAT YOU ARE WAIVING YOUR RIGHT TO LEAD OR
            PARTICIPATE IN A LAWSUIT INVOLVING OTHER PERSONS, SUCH AS A CLASS
            ACTION.
          </p>

          <h2 className="mb-3">10. Termination</h2>
          <p className="mb-5">
            You agree that Thinklikeapianist in its sole discretion, may suspend
            or terminate your account (or any part thereof) or use of the
            Service and remove and discard any content within the Service for
            any reason, including, without limitation, for lack of use or if
            Thinklikeapianist believes that you have violated or acted
            inconsistently with the letter or spirit of these Terms of Service.
            Any suspected fraudulent, abusive or illegal activity that may be
            grounds for termination of your use of Service, may be referred to
            appropriate law enforcement authorities. Thinklikeapianist may also
            in its sole discretion and at any time discontinue providing the
            Service, or any part thereof, with or without notice. You agree that
            any termination of your access to the Service under any provision of
            this Terms of Service may be effected without prior notice, and
            acknowledge and agree that Thinklikeapianist may immediately
            deactivate or delete your account and all related information and
            files in your account and/or bar any further access to such files or
            the Service. Further, you agree that Thinklikeapianist will not be
            liable to you or any third party for any termination of your access
            to the Service.
          </p>

          <h2 className="mb-3">11. Disputes Between Users</h2>
          <p className="mb-5">
            You agree that you are solely responsible for your interactions with
            any other user in connection with the Service and Thinklikeapianist
            will have no liability or responsibility with respect thereto.
            Thinklikeapianist reserves the right, but has no obligation to
            become involved in any way with disputes between you and any other
            user of the Service.
          </p>

          <h2 className="mb-3">12. General</h2>
          <p className="mb-5">
            These Terms of Service constitute the entire agreement between you
            and Thinklikeapianist and govern your use of the Service,
            superseding any prior agreements between you and Thinklikeapianist
            with respect to the Service. You also may be subject to additional
            terms and conditions that may apply when you use affiliate or third
            party services, third party content or third party software. These
            Terms of Service will be governed by the laws of Nigeria without
            regard to its conflict of law provisions. With respect to any
            disputes or claims not subject to arbitration, as set forth in
            Section 9 above, you and Thinklikeapianist agree to submit to the
            personal and exclusive jurisdiction of the state and federal courts
            located within Lagos, Nigeria. The failure of Thinklikeapianist to
            exercise or enforce any right or provision of these Terms of Service
            will not constitute a waiver of such right or provision. If any
            provision of these Terms of Service is found by a court of competent
            jurisdiction to be invalid, the parties nevertheless agree that the
            court should endeavor to give effect to the parties’ intentions as
            reflected in the provision and the other provisions of these Terms
            of Service remain in full force and effect. You agree that
            regardless of any statute or law to the contrary, any claim or cause
            of action arising out of or related to use of the Service or these
            Terms of Service must be filed within one (1) year after such claim
            or cause of action arose or be forever barred. A printed version of
            this agreement and of any notice given in electronic form will be
            admissible in judicial or administrative proceedings based upon or
            relating to this agreement to the same extent and subject to the
            same conditions as other business documents and records originally
            generated and maintained in printed form. You may not assign this
            Terms of Service without the prior written consent of
            Thinklikeapianist, but Thinklikeapianist may assign or transfer this
            Terms of Service, in whole or in part, without restriction. The
            section titles in these Terms of Service are for convenience only
            and have no legal or contractual effect. Notices to you may be made
            via either email or regular mail. Under no circumstances shall
            Thinklikeapianist be held liable for any delay or failure in
            performance resulting directly or indirectly from an event beyond
            its reasonable control. The Service may also provide notices to you
            of changes to these Terms of Service or other matters by displaying
            notices or links to notices generally on the Service.
          </p>

          <h2 className="mb-3">13. Questions? Concerns? Suggestions?</h2>
          <p className="mb-5">
            Please contact us at{" "}
            <a href="mailto:contact@thinklikeapianist.com">
              contact@thinklikeapianist.com
            </a>{" "}
            to report any violations of these Terms of Service or to pose any
            questions regarding this Terms of Service or the Service.
          </p>
        </div>
        <Footer />
      </div>
      <NavbarIcons />
      <NavbarMobile page="Terms" />
      <NavbarUser page="Terms" />
    </div>
  );
};

export default Terms;
