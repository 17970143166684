import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/tlp-logo-white.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/redux/store";
import { getInitials } from "../helpers/getInitials";
import { logoutUser } from "../store/redux/userRedux";

const NavbarTop = ({ page }: { page: string }) => {
  const user: any = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <>
      <nav className="flq-navbar flq-navbar-top flq-navbar-top-fixed">
        <div className="flq-navbar-container container-fluid">
          <Link to="/" className="flq-navbar-brand me-auto me-auto me-lg-4">
            <img src={logo} className="flq-logo" alt="logo" />
          </Link>
          <ul className="nav nav-parent flex-grow-1 flq-navbar-nav justify-content-center d-none d-lg-flex">
            <li
              className={`${page === "Home" ? "nav-item active" : "nav-item"}`}
            >
              <Link to="/" className="nav-link">
                <span className="nav-link-name">Home</span>
              </Link>
            </li>
            <li
              className={`${page === "About" ? "nav-item active" : "nav-item"}`}
            >
              <Link to="/about" className="nav-link">
                <span className="nav-link-name">About</span>
              </Link>
            </li>
            <li
              className={`${
                page === "Library" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link to="/library" className="nav-link">
                <span className="nav-link-name">Library</span>
              </Link>
            </li>
            <li
              className={`${page === "Plans" ? "nav-item active" : "nav-item"}`}
            >
              <Link to="/plans" className="nav-link">
                <span className="nav-link-name">Plans</span>
              </Link>
            </li>
            <li
              className={`${
                page === "Contact" ? "nav-item active" : "nav-item"
              }`}
            >
              <Link to="/contact" className="nav-link">
                <span className="nav-link-name">Contact</span>
              </Link>
            </li>
          </ul>

          <Link
            to="/search"
            className="btn btn-link btn-icon-md ms-5 d-none d-sm-flex"
          >
            <svg
              width={22}
              height={22}
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0833 17.4167C14.1334 17.4167 17.4167 14.1334 17.4167 10.0833C17.4167 6.03325 14.1334 2.75 10.0833 2.75C6.03325 2.75 2.75 6.03325 2.75 10.0833C2.75 14.1334 6.03325 17.4167 10.0833 17.4167Z"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.25 19.25L15.2625 15.2625"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>

          {!user && (
            <div className="d-flex align-items-center ms-4 d-none d-sm-flex">
              <Link
                to="/login"
                className="btn btn-xs btn-outline btn-white d-none d-md-flex"
              >
                Login
              </Link>
            </div>
          )}

          {user && (
            <div className="flq-dropdown flq-dropdown-nav ms-4 d-none d-sm-flex">
              <button className="btn btn-link align-self-center">
                {user?.picture ? (
                  <span className="flq-image flq-navbar-top-user">
                    <img src={user?.picture} alt="user" />
                  </span>
                ) : (
                  <span
                    className="flq-image flq-navbar-top-user"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {getInitials(user?.name)}
                  </span>
                )}
              </button>
              <div className="dropdown-menu gy-2 flq-dropdown-drop-left">
                <ul className="nav">
                  <li
                    className={`${
                      page === "Profile" ? "nav-item active" : "nav-item"
                    }`}
                  >
                    <Link to="/account-profile" className="nav-link">
                      <span className="nav-link-name">Profile</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      page === "Favorites" ? "nav-item active" : "nav-item"
                    }`}
                  >
                    <Link to="/account-favorites" className="nav-link">
                      <span className="nav-link-name">Favorites</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      page === "Questions" ? "nav-item active" : "nav-item"
                    }`}
                  >
                    <Link to="/account-questions" className="nav-link">
                      <span className="nav-link-name">
                        Questions &amp; Reviews
                      </span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      page === "Account Edit" ? "nav-item active" : "nav-item"
                    }`}
                  >
                    <Link to="/account-edit" className="nav-link">
                      <span className="nav-link-name">Account Edit</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      page === "Subscription" ? "nav-item active" : "nav-item"
                    }`}
                  >
                    <Link to="/account-subscription" className="nav-link">
                      <span className="nav-link-name">Subscription</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="#/" className="nav-link" onClick={logout}>
                      <span className="nav-link-name">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}

          <button
            className="flq-navbar-top-toggle btn btn-link ms-4 d-flex d-lg-none"
            data-fancybox
            data-src="#flq_navbar_mobile"
            data-base-class="flq-fancybox-navbar"
            data-animation-duration={1000}
            data-keyboard="false"
            data-auto-focus="false"
            data-touch="false"
            data-close-existing="true"
            data-small-btn="false"
            data-toolbar="false"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
    </>
  );
};

export default NavbarTop;
