import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/redux/userRedux";
import Preloader from "../../components/Preloader";
import { api } from "../../utils/apiClient";
import { ApiResponse, LoginResponse } from "../../types";

const Verifying = () => {
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Function to extract the authorization code from the URL
    const getAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        setCode(code);
      }
    };

    // Call the function to check for the authorization code
    getAuthorizationCode();
  }, []);

  useEffect(() => {
    if (code) {
      const verifyCode = async () => {
        try {
          const data = await api<ApiResponse<LoginResponse>>({
            method: "POST",
            url: "/api/auth/google",
            data: { code },
          });

          if (data?.data?.user) {
            dispatch(loginUser(data?.data?.user));
            localStorage.setItem(
              "tlp-access-token",
              data?.data?.accessToken ?? ""
            );
            localStorage.setItem(
              "tlp-refresh-token",
              data?.data?.refreshToken ?? ""
            );
            navigate("/");
          } else {
            navigate(
              `/create-account?name=${data?.data?.name}&email=${data?.data?.email}`
            );
          }
        } catch (error) {
          navigate("/login");
        }
      };

      verifyCode();
    }
  }, [code, dispatch, navigate]);

  return <Preloader />;
};

export default Verifying;
