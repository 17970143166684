import { useState } from "react";
import NavbarTop from "../components/NavbarTop";
import Footer from "../components/Footer";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarUser from "../components/NavbarUser";
import Banner from "../components/Banner";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getLibraryVideos } from "../utils/queries";
import { Video, VideoResponse } from "../types";
import LibraryLoader from "../components/swiper/LibraryLoader";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../helpers/capitalize";
import { isNewVideo } from "../helpers/isNewVideo";

const Library = () => {
  const [activeCategory, setActiveCategory] = useState("beginner");

  const handleCategoryChange = (category: string) => {
    setActiveCategory(category);
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery<VideoResponse>({
      queryKey: ["videos", activeCategory],
      queryFn: ({ pageParam = 1 }: { pageParam: any }) =>
        getLibraryVideos({ pageParam, category: activeCategory }),
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.videos.length === 12) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      initialPageParam: 1,
    });

  const sortedVideos = (videos: Video[]) => {
    return videos.sort((a, b) => a.serialNumber - b.serialNumber);
  };

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Library" />
      <Banner page="Library" />
      <div className="content-wrap">
        <div className="py-7">
          <div className="row justify-content-center mb-6">
            <div
              className="col-12 col-lg-10 col-xl-8 flq-vertical-rhythm text-center"
              data-sr="team-text"
              data-sr-interval={100}
              data-sr-distance={10}
              data-sr-duration={1000}
            >
              <h2
                className="display-6"
                data-sr-item="team-text"
                style={{ paddingLeft: "8px", paddingRight: "8px" }}
              >
                Piano for Everybody
              </h2>
              <div className="row align-items-center gy-4 mb-5">
                <div className="col-12 col-md" style={{ marginTop: "0" }}>
                  <nav>
                    <ul
                      className="nav nav-tabs flq-isotope-options"
                      data-sr="tvshow-options"
                      data-sr-interval={80}
                      data-sr-duration={1000}
                      data-sr-distance={10}
                      style={{ justifyContent: "center" }}
                    >
                      <li className="nav-item" data-sr-item="tvshow-options">
                        <a
                          href="#/"
                          data-filter="beginner"
                          className={`${
                            activeCategory === "beginner"
                              ? "nav-link active"
                              : "nav-link"
                          }`}
                          onClick={() => handleCategoryChange("beginner")}
                        >
                          <span className="nav-link-name">Beginner</span>
                        </a>
                      </li>
                      <li className="nav-item" data-sr-item="tvshow-options">
                        <a
                          href="#/"
                          data-filter="intermediate"
                          className={`${
                            activeCategory === "intermediate"
                              ? "nav-link active"
                              : "nav-link"
                          }`}
                          onClick={() => handleCategoryChange("intermediate")}
                        >
                          <span className="nav-link-name">Intermediate</span>
                        </a>
                      </li>
                      <li className="nav-item" data-sr-item="tvshow-options">
                        <a
                          href="#/"
                          data-filter="professional"
                          className={`${
                            activeCategory === "professional"
                              ? "nav-link active"
                              : "nav-link"
                          }`}
                          onClick={() => handleCategoryChange("professional")}
                        >
                          <span className="nav-link-name">Professional</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              {activeCategory === "beginner" && (
                <p
                  className="lead"
                  data-sr-item="team-text"
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                  Welcome, future pianists! Dive into our Beginner's Corner and
                  unlock the magic of piano playing. Let's jumpstart your
                  musical journey together. Let's play!
                </p>
              )}
              {activeCategory === "intermediate" && (
                <p
                  className="lead"
                  data-sr-item="team-text"
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                  Welcome, intermediate maestros! Ready to elevate your piano
                  prowess? Explore our Intermediate Hub to refine your skills
                  and delve deeper into the world of piano magic. Let's play on!
                </p>
              )}
              {activeCategory === "professional" && (
                <p
                  className="lead"
                  data-sr-item="team-text"
                  style={{ paddingLeft: "8px", paddingRight: "8px" }}
                >
                  Welcome, virtuosos! Dive into our Professional Zone to refine
                  your craft and push the boundaries of piano mastery. Let's
                  soar to new heights of musical excellence. Let's make magic
                  happen.
                </p>
              )}
            </div>
          </div>

          <div
            className="container flq-isotope"
            data-sr="tvshow-item"
            data-sr-interval={80}
            data-sr-duration={1000}
            data-sr-distance={10}
          >
            <div className="row gy-5 justify-content-center flq-isotope-grid">
              {isLoading
                ? Array(12)
                    .fill(0)
                    .map((_, index) => <LibraryLoader key={index} />)
                : data?.pages.map((page) =>
                    sortedVideos(page.videos).map((video) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 flq-isotope-item"
                        key={video._id}
                      >
                        <div data-sr-item="tvshow-item">
                          <div className="card flq-card-blog">
                            <div className="card-img-wrap">
                              <Link to={`/video/${video.videoId}`}>
                                <span className="flq-image flq-rounded-lg flq-responsive flq-responsive-sm-3x4">
                                  <img
                                    src={video.poster}
                                    alt="poster"
                                    style={{ objectPosition: "20% 50%" }}
                                  />
                                </span>
                                {isNewVideo(new Date(video.createdAt)) && (
                                  <span className="card-badge badge badge-dark badge-glass flq-color-white badge-sm">
                                    NEW
                                  </span>
                                )}
                              </Link>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title h6">
                                <Link to={`/video/${video.videoId}`}>
                                  {video.title}
                                </Link>
                              </h5>
                              <div className="flq-meta flq-meta-sm">
                                <ul>
                                  <li>{video.duration}m</li>
                                  <li>
                                    {capitalizeFirstLetter(video.category)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
            </div>
            {hasNextPage && (
              <div className="text-center mt-5" data-sr-item="tvshow-item">
                <button
                  className="btn btn-outline btn-white"
                  disabled={isFetchingNextPage}
                  onClick={() => {
                    fetchNextPage();
                  }}
                >
                  {isFetchingNextPage ? "Showing More..." : "Show More"}
                </button>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Library" />
      <NavbarUser page="Library" />
    </div>
  );
};

export default Library;
