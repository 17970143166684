import { Link } from "react-router-dom";
import cover from "../assets/images/my-all.jpg";

const Archive = () => {
  return (
    <div
      className="py-7"
      style={{
        backgroundImage: "linear-gradient(90deg, #e6aa9d 0%, #be5d6c 100%)",
      }}
      data-sr="movie"
      data-sr-interval={80}
      data-sr-duration={1000}
      data-sr-distance={10}
    >
      <div className="container">
        <div className="row align-items-center g-6">
          <div className="col-12 col-lg-5 flq-vertical-rhythm">
            <span
              className="flq-subtitle badge badge-white badge-translucent flq-color-opacity"
              data-sr-item="movie"
            >
              New Single
            </span>
            <h2 className="mb-0" data-sr-item="movie">
              I'm excited to introduce to you MY ALL!
            </h2>

            <p
              className="flq-color-opacity flq-color-text"
              data-sr-item="movie"
            >
              This is another simple classic from me and my team. Observe how I
              played the song in a very simple way and used the key changes and
              then modulation. You'll need to study the "Modulation" course in
              the professional section to understand the type of modulation I
              did. Enjoy the piece!
            </p>
          </div>
          <div
            className="col-12 col-lg-7"
            data-sr
            data-sr-delay={200}
            data-sr-duration={1500}
            data-sr-distance={10}
          >
            <div className="card flq-card-image flq-card-image-link flq-card-image-play">
              <Link
                to="/video/7afa671e91fd43e991135b2bf3a4bbd7"
                className="card-image"
              >
                <span className="flq-image flq-responsive">
                  <img src={cover} alt="archive" />
                </span>
              </Link>

              <div className="card-body">
                <span className="btn btn-xl btn-round btn-play btn-glass btn-icon-md">
                  <span className="btn-play-progress">
                    <svg>
                      <circle stroke="currentColor" r={0} fill="none" />
                    </svg>
                  </span>
                  <span className="btn-icon">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M8 19L19 12L8 5V19Z" fill="currentColor" />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archive;
