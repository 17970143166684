import { FormEventHandler, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/redux/userRedux";
import logo from "../../assets/images/tlp-logo-white.png";
import { toast } from "react-toastify";
import { api } from "../../utils/apiClient";
import { ApiResponse, LoginResponse } from "../../types";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const CreateAccount = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordFieldEmpty, setPasswordFieldEmpty] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordFieldEmpty, setConfirmPasswordFieldEmpty] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordWeak, setPasswordWeak] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const location = useLocation();

  const [name, setName] = useState<any>(
    new URLSearchParams(location.search).get("name")
  );
  const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
  const email: any = new URLSearchParams(location.search).get("email");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const registerHandler: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameFieldEmpty(true);
      return;
    } else {
      setNameFieldEmpty(false);
    }

    if (!password) {
      setPasswordFieldEmpty(true);
      return;
    } else {
      setPasswordFieldEmpty(false);
    }

    if (password.length < 6) {
      setPasswordWeak(true);
      return;
    } else {
      setPasswordWeak(false);
    }

    if (!confirmPassword) {
      setConfirmPasswordFieldEmpty(true);
      return;
    } else {
      setConfirmPasswordFieldEmpty(false);
    }

    if (password !== confirmPassword) {
      setPassword("");
      setConfirmPassword("");
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    try {
      setLoading(true);

      const data = await api<ApiResponse<LoginResponse>>({
        method: "POST",
        url: "/api/auth/create-account",
        data: { name, email, phone, password },
      });

      setLoading(false);
      dispatch(loginUser(data?.data?.user));
      localStorage.removeItem("registration-email");
      localStorage.setItem("tlp-access-token", data?.data?.accessToken ?? "");
      localStorage.setItem("tlp-refresh-token", data?.data?.refreshToken ?? "");
      navigate("/");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <div
      className="fancybox-container flq-fancybox-signin fancybox-is-open flq-fancybox-open"
      role="dialog"
      tabIndex={-1}
      id="fancybox-container-1"
      style={{ transitionDuration: "1000ms" }}
    >
      <div className="fancybox-inner">
        <div className="fancybox-stage">
          <div className="fancybox-slide fancybox-slide--html fancybox-slide--current fancybox-slide--complete">
            <div
              className="flq-fancybox-content-signin fancybox-content"
              id="flq_popup_signin"
              style={{ display: "inline-block" }}
            >
              <div className="flq-signin">
                <div className="flq-fancybox-head">
                  <div className="container-fluid">
                    <Link to="/" className="flq-fancybox-brand me-auto">
                      <img src={logo} className="flq-logo" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="flq-fancybox-body pb-6">
                  <form
                    onSubmit={registerHandler}
                    className="flq-signup-content"
                  >
                    <h4 className="mb-4 pb-1 text-center">Create account</h4>
                    <div className="row gy-4">
                      <div className="col-12">
                        <input
                          className="form-control flq-form-user flq-form-translucent"
                          type="text"
                          name="name"
                          placeholder="Full name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setNameFieldEmpty(false);
                          }}
                        />
                        {nameFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control flq-form-mail flq-form-translucent"
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={email}
                          disabled
                        />
                      </div>
                      <div className="col-12">
                        <input
                          className="form-control flq-form-phone flq-form-translucent"
                          type="tel"
                          name="phone"
                          placeholder="Phone"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12" style={{ position: "relative" }}>
                        <input
                          className="form-control flq-form-lock flq-form-translucent"
                          type={`${passwordShown ? "text" : "password"}`}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordFieldEmpty(false);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        <a
                          href="#/"
                          onClick={togglePasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "30px",
                            top: "8px",
                          }}
                        >
                          {!passwordShown ? (
                            <IoEyeOutline />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </a>
                        {passwordFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            This field is required
                          </p>
                        )}
                        {passwordWeak && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Password should be at least 6 characters long
                          </p>
                        )}
                      </div>
                      <div className="col-12" style={{ position: "relative" }}>
                        <input
                          className="form-control flq-form-lock flq-form-translucent"
                          type={`${confirmPasswordShown ? "text" : "password"}`}
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setConfirmPasswordFieldEmpty(false);
                            setPasswordError(false);
                            setPasswordWeak(false);
                          }}
                        />
                        <a
                          href="#/"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            position: "absolute",
                            right: "30px",
                            top: "8px",
                          }}
                        >
                          {!confirmPasswordShown ? (
                            <IoEyeOutline />
                          ) : (
                            <IoEyeOffOutline />
                          )}
                        </a>
                        {confirmPasswordFieldEmpty && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            This field is required
                          </p>
                        )}
                        {passwordError && (
                          <p
                            className="text-danger mt-1 text-center"
                            style={{ fontSize: "15px" }}
                          >
                            Passwords do not match
                          </p>
                        )}
                      </div>
                      <div className="col-12">
                        <div className="form-check flq-form-translucent">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="signupTerms"
                            required
                          />
                          <label
                            className="form-check-label"
                            htmlFor="signupTerms"
                          >
                            I have read and agree to Thinklikeapianist's{" "}
                            <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
                            <Link className="text-gold" to="/terms-of-use">
                              Terms of Use
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        {loading ? (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                            disabled
                            type="button"
                          >
                            Creating account...
                          </button>
                        ) : (
                          <button
                            className="btn btn-block"
                            style={{ background: "#c79200" }}
                            type="submit"
                          >
                            Create account
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
