import { useState } from "react";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarTop from "../components/NavbarTop";
import NavbarUser from "../components/NavbarUser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PaystackConsumer } from "react-paystack";
import { api } from "../utils/apiClient";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import Preloader from "../components/Preloader";

const Plans = () => {
  const [monthly, setMonthly] = useState(true);
  const [beginnerPaymentOptionModalOpen, setBeginnerPaymentModalOptionOpen] =
    useState(false);
  const [downgradeToBeginnerModalOpen, setDowngradeToBeginnerModalOpen] =
    useState(false);
  const [
    intermediatePaymentOptionModalOpen,
    setIntermediatePaymentModalOptionOpen,
  ] = useState(false);
  const [upgradeToIntermediateModalOpen, setUpgradeToIntermediateModalOpen] =
    useState(false);
  const [
    downgradeToIntermediateModalOpen,
    setDowngradeToIntermediateModalOpen,
  ] = useState(false);
  const [
    intermediateProPaymentOptionModalOpen,
    setIntermediateProPaymentModalOptionOpen,
  ] = useState(false);
  const [
    upgradeToIntermediateProModalOpen,
    setUpgradeToIntermediateProModalOpen,
  ] = useState(false);
  const [
    downgradeToIntermediateProModalOpen,
    setDowngradeToIntermediateProModalOpen,
  ] = useState(false);
  const [
    professionalPaymentOptionModalOpen,
    setProfessionalPaymentModalOptionOpen,
  ] = useState(false);
  const [upgradeToProfessionalModalOpen, setUpgradeToProfessionalModalOpen] =
    useState(false);
  const [
    downgradeToProfessionalModalOpen,
    setDowngradeToProfessionalModalOpen,
  ] = useState(false);
  const [
    professionalProPaymentOptionModalOpen,
    setProfessionalProPaymentModalOptionOpen,
  ] = useState(false);
  const [
    upgradeToProfessionalProModalOpen,
    setUpgradeToProfessionalProModalOpen,
  ] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentFailure, setPaymentFailure] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);

  const reference = new Date().getTime().toString();
  const email = user?.email;
  const name = user?.name;
  const amount = 0;
  let subscription;

  const publicKey = process.env.REACT_APP_PUBLIC_KEY ?? "";
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID ?? "";

  const toggleBeginnerDowngrade = () => {
    setDowngradeToBeginnerModalOpen(!downgradeToBeginnerModalOpen);
  };

  const toggleIntermediateUpgrade = () => {
    setUpgradeToIntermediateModalOpen(!upgradeToIntermediateModalOpen);
  };

  const toggleIntermediateDowngrade = () => {
    setDowngradeToIntermediateModalOpen(!downgradeToIntermediateModalOpen);
  };

  const toggleIntermediateProUpgrade = () => {
    setUpgradeToIntermediateProModalOpen(!upgradeToIntermediateProModalOpen);
  };

  const toggleIntermediateProDowngrade = () => {
    setDowngradeToIntermediateProModalOpen(
      !downgradeToIntermediateProModalOpen
    );
  };

  const toggleProfessionalUpgrade = () => {
    setUpgradeToProfessionalModalOpen(!upgradeToProfessionalModalOpen);
  };

  const toggleProfessionalDowngrade = () => {
    setDowngradeToProfessionalModalOpen(!downgradeToProfessionalModalOpen);
  };

  const toggleProfessionalProUpgrade = () => {
    setUpgradeToProfessionalProModalOpen(!upgradeToProfessionalProModalOpen);
  };

  const cancelPaypalSubscription = async () => {
    setPageLoading(true);

    try {
      await api({
        method: "POST",
        url: `/api/paypal/cancel/${user?._id}`,
        data: { subscriptionId: user?.subscriptionId, email: user?.email },
      });

      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      toast.error("Unable to cancel subscription");
    }
  };

  const cancelPaystackSubscription = async () => {
    setPageLoading(true);

    try {
      await api({
        method: "POST",
        url: `/api/paystack/cancel/${user?._id}`,
        data: { email: user?.email },
      });

      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      toast.error("Unable to cancel subscription");
    }
  };

  const handleClose = () => {
    setBeginnerPaymentModalOptionOpen(false);
    setIntermediatePaymentModalOptionOpen(false);
    setIntermediateProPaymentModalOptionOpen(false);
    setProfessionalPaymentModalOptionOpen(false);
    setProfessionalProPaymentModalOptionOpen(false);

    setPaymentFailure(true);
    setPaymentSuccess(false);
  };

  const handleSuccess = async (reference) => {
    const ref = reference.reference;

    if (ref) {
      try {
        const data = await api({
          method: "GET",
          url: "/api/paystack",
          params: { ref, monthly, subscription },
        });

        if (data?.message === "User subscription activated successfully") {
          setBeginnerPaymentModalOptionOpen(false);
          setIntermediatePaymentModalOptionOpen(false);
          setIntermediateProPaymentModalOptionOpen(false);
          setProfessionalPaymentModalOptionOpen(false);
          setProfessionalProPaymentModalOptionOpen(false);

          setPaymentFailure(false);
          setPaymentSuccess(true);
        } else {
          setBeginnerPaymentModalOptionOpen(false);
          setIntermediatePaymentModalOptionOpen(false);
          setIntermediateProPaymentModalOptionOpen(false);
          setProfessionalPaymentModalOptionOpen(false);
          setProfessionalProPaymentModalOptionOpen(false);

          setPaymentFailure(true);
          setPaymentSuccess(false);
        }
      } catch (error) {
        setBeginnerPaymentModalOptionOpen(false);
        setIntermediatePaymentModalOptionOpen(false);
        setIntermediateProPaymentModalOptionOpen(false);
        setProfessionalPaymentModalOptionOpen(false);
        setProfessionalProPaymentModalOptionOpen(false);

        setPaymentFailure(true);
        setPaymentSuccess(false);
      }
    } else {
      setBeginnerPaymentModalOptionOpen(false);
      setIntermediatePaymentModalOptionOpen(false);
      setIntermediateProPaymentModalOptionOpen(false);
      setProfessionalPaymentModalOptionOpen(false);
      setProfessionalProPaymentModalOptionOpen(false);

      setPaymentFailure(true);
      setPaymentSuccess(false);
    }
  };

  const beginnerMonthlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_BEGINNER_MONTHLY_PLAN_ID,
    publicKey,
    amount,
    metadata: {
      name,
      email,
    },
  };

  const beginnerYearlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_BEGINNER_YEARLY_PLAN_ID,
    publicKey,
    amount,
    metadata: {
      name,
      email,
    },
  };

  const intermediateMonthlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_INTERMEDIATE_MONTHLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const intermediateYearlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_INTERMEDIATE_YEARLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const intermediateProMonthlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_INTERMEDIATE_PRO_MONTHLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const intermediateProYearlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_INTERMEDIATE_PRO_YEARLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const professionalMonthlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_PROFESSIONAL_MONTHLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const professionalYearlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_PROFESSIONAL_YEARLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const professionalProMonthlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_PROFESSIONAL_PRO_MONTHLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const professionalProYearlyConfig = {
    reference,
    email,
    plan: process.env.REACT_APP_PAYSTACK_PROFESSIONAL_PRO_YEARLY_PLAN_ID,
    publicKey,
    metadata: {
      name,
      email,
    },
  };

  const beginnerMonthlyProps = {
    ...beginnerMonthlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const beginnerYearlyProps = {
    ...beginnerYearlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const intermediateMonthlyProps = {
    ...intermediateMonthlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const intermediateYearlyProps = {
    ...intermediateYearlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const intermediateProMonthlyProps = {
    ...intermediateProMonthlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const intermediateProYearlyProps = {
    ...intermediateProYearlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const professionalMonthlyProps = {
    ...professionalMonthlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const professionalYearlyProps = {
    ...professionalYearlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const professionalProMonthlyProps = {
    ...professionalProMonthlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const professionalProYearlyProps = {
    ...professionalProYearlyConfig,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div className="flq-navbar-icons-existence">
      {pageLoading && <Preloader />}
      <NavbarTop page="Plans" />
      <Banner page="Plans" />
      <div className="content-wrap">
        <div
          className="py-7 flq-background-color-100"
          style={{
            backgroundImage:
              "radial-gradient(100% 100% at 50% 0%, hsla(var(--flq-color-brand), 0.14) 25%, hsla(var(--flq-color-background), 0) 100%)",
          }}
        >
          <div
            className="text-center mb-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span style={{ marginRight: "10px" }}>Yearly</span>
            <input
              className="react-switch-checkbox"
              checked={monthly}
              onChange={() => setMonthly(!monthly)}
              id="react-switch-new"
              type="checkbox"
            />
            <label className="react-switch-label" htmlFor="react-switch-new">
              <span className="react-switch-button" />
            </label>
            <span style={{ marginLeft: "10px" }}>Monthly</span>
          </div>
          <div
            className="container"
            data-sr="pricing"
            data-sr-interval={150}
            data-sr-duration={1200}
            data-sr-distance={30}
          >
            <div className="row align-items-center justify-content-center g-5">
              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4"
                data-sr-item="pricing"
              >
                <div className="card flq-card-pricing">
                  <div className="card-head">
                    <div
                      className="card-subtitle h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Beginner{" "}
                      {user?.subscription === "beginner" && (
                        <span
                          className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"
                          style={{ fontSize: "9px" }}
                        >
                          YOUR PLAN
                        </span>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>$</span>10<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>$</span>100<span>year</span>
                        </>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>&#8358;</span>7,500<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>&#8358;</span>80,000<span>year</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body flq-vertical-rhythm">
                    <p>
                      Beginner-level courses ranging from introduction to the
                      piano and hitting your first notes to triads, inversions,
                      chords, chord progressions and many more.
                    </p>
                    <ul>
                      <li>Access to beginner courses only</li>
                      <li>Switch plans or cancel anytime</li>
                      <li>Watch on mobile, tablet or desktop</li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {(!user || user?.subscription === "free") && (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          if (user) {
                            setBeginnerPaymentModalOptionOpen(true);
                          } else {
                            navigate("/register");
                          }
                        }}
                      >
                        Subscribe
                      </button>
                    )}

                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription !== "beginner" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleBeginnerDowngrade}
                        >
                          Downgrade
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4"
                data-sr-item="pricing"
              >
                <div className="card flq-card-pricing">
                  <div className="card-head">
                    <div
                      className="card-subtitle h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Intermediate{" "}
                      {user?.subscription === "intermediate" && (
                        <span
                          className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"
                          style={{ fontSize: "9px" }}
                        >
                          YOUR PLAN
                        </span>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>$</span>15<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>$</span>150<span>year</span>
                        </>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>&#8358;</span>10,000<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>&#8358;</span>110,000<span>year</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body flq-vertical-rhythm">
                    <p>
                      Mid-level courses for intermediate players ranging from
                      rooted and rootless voicings and left hand nuances to
                      pedal control tips, octave stabs and playing with a band
                      and many more.
                    </p>
                    <ul>
                      <li>Access to intermediate courses only</li>
                      <li>Switch plans or cancel anytime</li>
                      <li>Watch on mobile, tablet or desktop</li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {(!user || user?.subscription === "free") && (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          if (user) {
                            setIntermediatePaymentModalOptionOpen(true);
                          } else {
                            navigate("/register");
                          }
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription === "beginner" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleIntermediateUpgrade}
                        >
                          Upgrade
                        </button>
                      )}
                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription !== "beginner" &&
                      user?.subscription !== "intermediate" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleIntermediateDowngrade}
                        >
                          Downgrade
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4"
                data-sr-item="pricing"
              >
                <div className="card flq-card-pricing">
                  <div className="card-head">
                    <div
                      className="card-subtitle h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Intermediate Pro{" "}
                      {user?.subscription === "intermediate-pro" && (
                        <span
                          className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"
                          style={{ fontSize: "9px" }}
                        >
                          YOUR PLAN
                        </span>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>$</span>20<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>$</span>220<span>year</span>
                        </>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>&#8358;</span>12,500<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>&#8358;</span>140,000<span>year</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body flq-vertical-rhythm">
                    <p>Everything in beginner and intermediate levels.</p>
                    <ul>
                      <li>Access to intermediate and beginner courses</li>
                      <li>Switch plans or cancel anytime</li>
                      <li>Watch on mobile, tablet or desktop</li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {(!user || user?.subscription === "free") && (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          if (user) {
                            setIntermediateProPaymentModalOptionOpen(true);
                          } else {
                            navigate("/register");
                          }
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                    {user &&
                      user?.subscription !== "free" &&
                      (user?.subscription === "beginner" ||
                        user?.subscription === "intermediate") && (
                        <button
                          className="btn btn-block"
                          onClick={toggleIntermediateProUpgrade}
                        >
                          Upgrade
                        </button>
                      )}
                    {user &&
                      user?.subscription !== "free" &&
                      (user?.subscription === "professional" ||
                        user?.subscription === "professional-pro") && (
                        <button
                          className="btn btn-block"
                          onClick={toggleIntermediateProDowngrade}
                        >
                          Downgrade
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container"
            data-sr="pricing"
            data-sr-interval={150}
            data-sr-duration={1200}
            data-sr-distance={30}
          >
            <div className="row align-items-center justify-content-center g-5 mt-3">
              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4"
                data-sr-item="pricing"
              >
                <div className="card flq-card-pricing">
                  <div className="card-head">
                    <div
                      className="card-subtitle h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Professional{" "}
                      {user?.subscription === "professional" && (
                        <span
                          className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"
                          style={{ fontSize: "9px" }}
                        >
                          YOUR PLAN
                        </span>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>$</span>25<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>$</span>270<span>year</span>
                        </>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>&#8358;</span>15,000<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>&#8358;</span>170,000<span>year</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body flq-vertical-rhythm">
                    <p>
                      Professional-level courses ranging from complex chord
                      substitutions, comping and ragtime to complex
                      slurs/gliding, superimposition, jazz tips for pros and
                      many more.
                    </p>
                    <ul>
                      <li>Access to professional courses only</li>
                      <li>Switch plans or cancel anytime</li>
                      <li>Watch on mobile, tablet or desktop</li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {(!user || user?.subscription === "free") && (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          if (user) {
                            setProfessionalPaymentModalOptionOpen(true);
                          } else {
                            navigate("/register");
                          }
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription !== "professional" &&
                      user?.subscription !== "professional-pro" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleProfessionalUpgrade}
                        >
                          Upgrade
                        </button>
                      )}
                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription === "professional-pro" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleProfessionalDowngrade}
                        >
                          Downgrade
                        </button>
                      )}
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-4"
                data-sr-item="pricing"
              >
                <div className="card flq-card-pricing">
                  <div className="card-head">
                    <div
                      className="card-subtitle h6"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Professional Pro{" "}
                      {user?.subscription === "professional-pro" && (
                        <span
                          className="card-badge badge badge-dark badge-glass flq-color-white badge-sm"
                          style={{ fontSize: "9px" }}
                        >
                          YOUR PLAN
                        </span>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>$</span>30<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>$</span>335<span>year</span>
                        </>
                      )}
                    </div>
                    <div className="card-price h1">
                      {monthly ? (
                        <>
                          <span>&#8358;</span>20,000<span>month</span>
                        </>
                      ) : (
                        <>
                          <span>&#8358;</span>200,000<span>year</span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body flq-vertical-rhythm">
                    <p>
                      A complete package with all courses available for all
                      levels.
                    </p>
                    <ul>
                      <li>Access to all courses</li>
                      <li>Switch plans or cancel anytime</li>
                      <li>Watch on mobile, tablet or desktop</li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {(!user || user?.subscription === "free") && (
                      <button
                        className="btn btn-block"
                        onClick={() => {
                          if (user) {
                            setProfessionalProPaymentModalOptionOpen(true);
                          } else {
                            navigate("/register");
                          }
                        }}
                      >
                        Subscribe
                      </button>
                    )}
                    {user &&
                      user?.subscription !== "free" &&
                      user?.subscription !== "professional-pro" && (
                        <button
                          className="btn btn-block"
                          onClick={toggleProfessionalProUpgrade}
                        >
                          Upgrade
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Plans" />
      <NavbarUser page="Plans" />

      {beginnerPaymentOptionModalOpen && (
        <div
          className="flq-account-content modal"
          onClick={() => setBeginnerPaymentModalOptionOpen(false)}
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2
              className="h5 mb-2 text-center"
              style={{ paddingTop: "120px", paddingBottom: "20px" }}
            >
              Choose your preferred payment option
            </h2>

            {monthly ? (
              <PaystackConsumer {...beginnerMonthlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "beginner";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            ) : (
              <PaystackConsumer {...beginnerYearlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "beginner";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            )}

            {monthly ? (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env.REACT_APP_PAYPAL_BEGINNER_MONTHLY_PLAN_ID ??
                        "",
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "beginner",
                        subscriptionType: "monthly",
                      },
                    });

                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env.REACT_APP_PAYPAL_BEGINNER_YEARLY_PLAN_ID ??
                        "",
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "beginner",
                        subscriptionType: "yearly",
                      },
                    });

                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setBeginnerPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      )}

      {downgradeToBeginnerModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to downgrade to Beginner plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleBeginnerDowngrade();
                        setBeginnerPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleBeginnerDowngrade();
                        setBeginnerPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleBeginnerDowngrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {intermediatePaymentOptionModalOpen && (
        <div
          className="flq-account-content modal"
          onClick={() => setIntermediatePaymentModalOptionOpen(false)}
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2
              className="h5 mb-2 text-center"
              style={{ paddingTop: "120px", paddingBottom: "20px" }}
            >
              Choose your preferred payment option
            </h2>

            {monthly ? (
              <PaystackConsumer {...intermediateMonthlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "intermediate";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            ) : (
              <PaystackConsumer {...intermediateYearlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "intermediate";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            )}

            {monthly ? (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_INTERMEDIATE_MONTHLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "intermediate",
                        subscriptionType: "monthly",
                      },
                    });

                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_INTERMEDIATE_YEARLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "intermediate",
                        subscriptionType: "yearly",
                      },
                    });

                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setIntermediatePaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      )}

      {upgradeToIntermediateModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to upgrade to Intermediate plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleIntermediateUpgrade();
                        setIntermediatePaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleIntermediateUpgrade();
                        setIntermediatePaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleIntermediateUpgrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {downgradeToIntermediateModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to downgrade to Intermediate plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleIntermediateDowngrade();
                        setIntermediatePaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleIntermediateDowngrade();
                        setIntermediatePaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleIntermediateDowngrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {intermediateProPaymentOptionModalOpen && (
        <div
          className="flq-account-content modal"
          onClick={() => setIntermediateProPaymentModalOptionOpen(false)}
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2
              className="h5 mb-2 text-center"
              style={{ paddingTop: "120px", paddingBottom: "20px" }}
            >
              Choose your preferred payment option
            </h2>

            {monthly ? (
              <PaystackConsumer {...intermediateProMonthlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "intermediate-pro";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            ) : (
              <PaystackConsumer {...intermediateProYearlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "intermediate-pro";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            )}

            {monthly ? (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_INTERMEDIATE_PRO_MONTHLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "intermediate-pro",
                        subscriptionType: "monthly",
                      },
                    });

                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_INTERMEDIATE_PRO_YEARLY_PLAN_ID,
                      subscriber: {
                        email_address: user?.email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "intermediate-pro",
                        subscriptionType: "yearly",
                      },
                    });

                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setIntermediateProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      )}

      {upgradeToIntermediateProModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to upgrade to Intermediate Pro plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleIntermediateProUpgrade();
                        setIntermediateProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleIntermediateProUpgrade();
                        setIntermediateProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleIntermediateProUpgrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {downgradeToIntermediateProModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to downgrade to Intermediate Pro plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleIntermediateProDowngrade();
                        setIntermediateProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleIntermediateProDowngrade();
                        setIntermediateProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleIntermediateProDowngrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {professionalPaymentOptionModalOpen && (
        <div
          className="flq-account-content modal"
          onClick={() => setProfessionalPaymentModalOptionOpen(false)}
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2
              className="h5 mb-2 text-center"
              style={{ paddingTop: "120px", paddingBottom: "20px" }}
            >
              Choose your preferred payment option
            </h2>

            {monthly ? (
              <PaystackConsumer {...professionalMonthlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "professional";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            ) : (
              <PaystackConsumer {...professionalYearlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "professional";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            )}

            {monthly ? (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_PROFESSIONAL_MONTHLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "professional",
                        subscriptionType: "monthly",
                      },
                    });

                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_PROFESSIONAL_YEARLY_PLAN_ID,
                      subscriber: {
                        email_address: user?.email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "professional",
                        subscriptionType: "yearly",
                      },
                    });

                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setProfessionalPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      )}

      {upgradeToProfessionalModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to upgrade to Professional plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleProfessionalUpgrade();
                        setProfessionalPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleProfessionalUpgrade();
                        setProfessionalPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleProfessionalUpgrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {downgradeToProfessionalModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to downgrade to Professional plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleProfessionalDowngrade();
                        setProfessionalPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleProfessionalDowngrade();
                        setProfessionalPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleProfessionalDowngrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {professionalProPaymentOptionModalOpen && (
        <div
          className="flq-account-content modal"
          onClick={() => setProfessionalProPaymentModalOptionOpen(false)}
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2
              className="h5 mb-2 text-center"
              style={{ paddingTop: "120px", paddingBottom: "20px" }}
            >
              Choose your preferred payment option
            </h2>

            {monthly ? (
              <PaystackConsumer {...professionalProMonthlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "professional-pro";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            ) : (
              <PaystackConsumer {...professionalProYearlyProps}>
                {({ initializePayment }) => (
                  <button
                    className="btn btn-dark btn-block"
                    style={{ maxWidth: "400px", margin: "auto" }}
                    onClick={() => {
                      subscription = "professional-pro";
                      initializePayment(handleSuccess, handleClose);
                    }}
                  >
                    Paystack
                  </button>
                )}
              </PaystackConsumer>
            )}

            {monthly ? (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_PROFESSIONAL_PRO_MONTHLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "professional-pro",
                        subscriptionType: "monthly",
                      },
                    });

                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            ) : (
              <PayPalScriptProvider
                options={{
                  "client-id": clientId,
                  intent: "subscription",
                  vault: true,
                }}
              >
                <PayPalButtons
                  className="pp"
                  style={{ tagline: "false", height: 54 }}
                  createSubscription={async (data, actions) => {
                    return actions.subscription.create({
                      plan_id:
                        process.env
                          .REACT_APP_PAYPAL_PROFESSIONAL_PRO_YEARLY_PLAN_ID,
                      subscriber: {
                        email_address: email,
                      },
                    });
                  }}
                  onApprove={async (data, actions) => {
                    await api({
                      method: "POST",
                      url: "/api/paypal/subscribe",
                      data: {
                        subscriptionId: data.subscriptionID,
                        email,
                        subscription: "professional-pro",
                        subscriptionType: "yearly",
                      },
                    });

                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(false);
                    setPaymentSuccess(true);
                  }}
                  onCancel={() => {
                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                  onError={() => {
                    setProfessionalProPaymentModalOptionOpen(false);
                    setPaymentFailure(true);
                    setPaymentSuccess(false);
                  }}
                />
              </PayPalScriptProvider>
            )}
          </div>
        </div>
      )}

      {upgradeToProfessionalProModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to upgrade to Professional Pro plan?
            </h2>
            <p className="text-center">
              Your current plan will be cancelled and you'll be charged now and
              on subsequent renewals.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                {user?.subscriptionMethod === "paypal" ? (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaypalSubscription();
                        }
                        toggleProfessionalProUpgrade();
                        setProfessionalProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                ) : (
                  <div className="col-12 col-sm-auto">
                    <button
                      className="btn btn-dark btn-block"
                      onClick={() => {
                        if (user?.subscriptionStatus !== "cancelled") {
                          cancelPaystackSubscription();
                        }
                        toggleProfessionalProUpgrade();
                        setProfessionalProPaymentModalOptionOpen(true);
                      }}
                    >
                      Yes, Continue
                    </button>
                  </div>
                )}
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={toggleProfessionalProUpgrade}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentSuccess && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Payment Succesful
            </h2>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => {
                      setPaymentSuccess(false);
                      navigate(0);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentFailure && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Payment not completed
            </h2>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => {
                      setPaymentFailure(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;
