import { useSelector } from "react-redux";
import { RootState } from "../store/redux/store";
import { useQuery } from "@tanstack/react-query";
import { getNewReleases, getStats, getTopRatedVideos } from "../utils/queries";
import { Link } from "react-router-dom";
import NewReleases from "../components/swiper/NewReleases";
import ClientSlider from "../components/ClientsSlider";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BsClock, BsFillJournalBookmarkFill } from "react-icons/bs";
import Archive from "../components/Archive";
import Footer from "../components/Footer";
import LectureTapes from "../components/LectureTapes";
import Numbers from "../components/Numbers";
import Testimonials from "../components/Testimonials";
import NavbarTop from "../components/NavbarTop";
import NavbarIcons from "../components/NavbarIcons";
import NavbarUser from "../components/NavbarUser";
import NavbarMobile from "../components/NavbarMobile";
import TopRated from "../components/swiper/TopRated";

export const NEW_VIDEOS_KEY = ["new_videos"];
export const STATS_KEY = ["stats"];
export const TOP_RATED_VIDEOS_KEY = ["top_rated_videos"];

const Home = () => {
  const user = useSelector((state: RootState) => state.user.currentUser);

  const { data, isLoading, isError } = useQuery({
    queryKey: NEW_VIDEOS_KEY,
    queryFn: getNewReleases,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const { data: statsData, isError: isStatsError } = useQuery({
    queryKey: STATS_KEY,
    queryFn: getStats,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const {
    data: topRatedData,
    isLoading: isTopRatedLoading,
    isError: isTopRatedError,
  } = useQuery({
    queryKey: TOP_RATED_VIDEOS_KEY,
    queryFn: getTopRatedVideos,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Home" />

      <div className="py-7 flq-background-color-100">
        <div className="container pt-navbar" style={{ textAlign: "center" }}>
          <h1 className="display-3 mb-0" style={{ fontSize: "50px" }}>
            Learn to Play the Piano like a Pro
          </h1>
          <p className="my-3">
            We have carefully curated a list of piano lessons to help you learn
            and grow as a pianist.
          </p>
          <div className="py-1">
            <div className="row gy-4 align-items-center">
              <div className="col-auto mx-auto" data-animejs>
                {user ? (
                  <Link to="/library" className="btn btn-icon-sm">
                    <span className="btn-name">Get Started</span>
                  </Link>
                ) : (
                  <Link to="/register" className="btn btn-icon-sm">
                    <span className="btn-name">Get Started</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewReleases isError={isError} data={data} isLoading={isLoading} />
      <ClientSlider />
      {!isTopRatedError && (
        <TopRated
          isError={isTopRatedError}
          data={topRatedData}
          isLoading={isTopRatedLoading}
        />
      )}

      <div className="content-wrap">
        <div
          className="py-7 overflow-hidden flq-background flq-background-color-100"
          data-sr="features"
          data-sr-interval={100}
          data-sr-duration={1000}
          data-sr-distance={10}
        >
          <div className="flq-background-shape">
            <div data-rellax data-rellax-speed={1} className="mt-auto">
              <svg
                className="flq-translate-y50 ms-n7"
                width={866}
                height={831}
                viewBox="0 0 866 831"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M323.654 829.93C224.153 816.108 227.515 661.483 166.991 581.373C117.182 515.444 16.5101 485.726 2.15912 404.394C-12.2526 322.718 48.441 248.437 96.2908 180.687C138.901 120.355 193.017 70.7174 259.952 39.3885C326.181 8.38988 399.582 -9.42614 471.263 5.18105C541.916 19.579 590.604 76.5775 649.113 118.693C724.145 172.702 839.74 196.401 861.604 286.155C883.301 375.219 810.521 461.538 749.243 529.731C697.595 587.206 611.836 589.844 548.676 634.395C465.428 693.116 424.619 843.956 323.654 829.93Z"
                  fill="#fff"
                  fillOpacity="0.02"
                />
              </svg>
            </div>
            <div data-rellax data-rellax-speed={-1} className="ms-auto">
              <svg
                className="flq-translate-yn50 mt-n7 ms-7"
                width={782}
                height={865}
                viewBox="0 0 782 865"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M755.116 831.212C680.566 911.828 543.954 812.208 434.284 816.447C344.028 819.936 254.487 891.671 172.561 853.73C90.2879 815.629 61.5615 714.78 30.6714 629.531C3.16372 553.617 -7.56424 474.065 6.13963 394.452C19.6991 315.678 49.5645 238.707 107.441 183.536C164.489 129.156 246.033 121.094 320.36 94.9246C415.677 61.3647 509.14 -27.5167 603.314 8.94621C696.764 45.1286 728.741 164.34 751.641 261.91C770.941 344.146 719.74 422.719 720.256 507.206C720.936 618.565 830.763 749.41 755.116 831.212Z"
                  fill="#fff"
                  fillOpacity="0.02"
                />
              </svg>
            </div>
          </div>
          <div className="container">
            <div className="row gy-5 gx-6">
              <div className="col-lg-4">
                <h2 className="h1" data-sr-item="features">
                  Play Like A Pro At Your Own Pace
                </h2>
              </div>
              <div className="col-lg-8">
                <div className="row g-6">
                  <div className="col-12 col-sm-6" data-sr-item="features">
                    <div className="card flq-card-feature">
                      <div className="card-body">
                        <div className="card-icon">
                          <BsFillJournalBookmarkFill
                            style={{ width: "27px", height: "27px" }}
                          />
                        </div>
                        <div className="card-title h5">Exciting Courses</div>
                        <p>
                          Elevate your piano playing skills with ease. Whether
                          you're a novice or an experienced pianist, our dynamic
                          lessons offer a path to musical mastery at your own
                          pace.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6" data-sr-item="features">
                    <div className="card flq-card-feature">
                      <div className="card-body">
                        <div className="card-icon">
                          <BsClock style={{ width: "27px", height: "27px" }} />
                        </div>
                        <div className="card-title h5">Always Available</div>
                        <p>
                          Our lessons are always available, ensuring that your
                          learning fits seamlessly into your schedule. Enjoy the
                          flexibility to practice and enhance your skills at any
                          time.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6" data-sr-item="features">
                    <div className="card flq-card-feature">
                      <div className="card-body">
                        <div className="card-icon">
                          <FaChalkboardTeacher
                            style={{ width: "27px", height: "27px" }}
                          />
                        </div>
                        <div className="card-title h5">
                          Experienced Instructor
                        </div>
                        <p>
                          Experienced pianist to guide you on your musical
                          journey, providing expert insights, personalized
                          feedback and a wealth of knowledge to help you achieve
                          your piano playing goals.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6" data-sr-item="features">
                    <div className="card flq-card-feature">
                      <div className="card-body">
                        <div className="card-icon">
                          <svg
                            width={30}
                            height={30}
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 27.5C15 27.5 25 22.5 25 15V6.25L15 2.5L5 6.25V15C5 22.5 15 27.5 15 27.5Z"
                              stroke="currentColor"
                              strokeWidth="2.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="card-title h5">Affordable Plans</div>
                        <p>
                          Our budget-friendly options make high-quality musical
                          education accessible to all, ensuring that your
                          musical aspirations can thrive within your means.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
        <Archive />
        {isStatsError ? (
          <Numbers users={3604} videos={180} />
        ) : (
          <Numbers
            users={statsData?.totalUsers}
            videos={statsData?.totalVideos}
          />
        )}
        <LectureTapes />
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Home" />
      <NavbarUser page="Home" />
    </div>
  );
};

export default Home;
