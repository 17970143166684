import theVoice from "../assets/images/the-voice-logo.png";
import experience from "../assets/images/experience.png";
import sheard from "../assets/images/sheard.png";

const ClientSlider = () => {
  return (
    <div
      className="flq-background py-6"
      style={{ background: "hsl(240deg, 4%, 9%)" }}
    >
      <div className="flq-background-ticker">
        <div
          className="flq-image-ticker mb-3 flq-image-ticker-ready"
          data-gap={20}
          data-pixels-per-second={20}
        >
          <div
            className="flq-image-ticker-inner"
            style={{ width: "2160px", animationDuration: "108s" }}
          >
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={theVoice} alt="ticker" />
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={experience} alt="ticker" />
              </span>
            </div>

            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Lawrence & DeCovenant
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={sheard} alt="ticker" />
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                KIM BURRELL
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Lionel Peterson
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Efe Nathan
              </span>
            </div>

            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Minister Kennedy Ogeleka
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                MAGPSALMS
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                David Jamesy
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Tosin Martins
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Onyeka <span style={{ fontWeight: "bold" }}>Onwenu</span>
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Uche Agu
              </span>
            </div>

            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={theVoice} alt="ticker" />
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={experience} alt="ticker" />
              </span>
            </div>

            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Lawrence & DeCovenant
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span className="flq-image" style={{ background: "none" }}>
                <img src={sheard} alt="ticker" />
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                KIM BURRELL
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Lionel Peterson
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Efe Nathan
              </span>
            </div>

            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Minister Kennedy Ogeleka
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                MAGPSALMS
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                David Jamesy
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Tosin Martins
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Onyeka <span style={{ fontWeight: "bold" }}>Onwenu</span>
              </span>
            </div>
            <div
              className="flq-image-ticker-item"
              style={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <span
                className="flq-image"
                style={{
                  background: "none",
                  position: "relative",
                  top: "30%",
                  fontSize: "28px",
                }}
              >
                Uche Agu
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
