import { FormEventHandler, useState } from "react";
import Footer from "../../components/Footer";
import NavbarIcons from "../../components/NavbarIcons";
import NavbarMobile from "../../components/NavbarMobile";
import NavbarTop from "../../components/NavbarTop";
import NavbarUser from "../../components/NavbarUser";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/redux/store";
import { Link, useNavigate } from "react-router-dom";
import { loadUser, logoutUser } from "../../store/redux/userRedux";
import { getInitials } from "../../helpers/getInitials";
import app from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { toast } from "react-toastify";
import { api } from "../../utils/apiClient";
import { ApiResponse, LoginResponse } from "../../types";

const EditAccout = () => {
  const [image, setImage] = useState<any>(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [nameFieldEmpty, setNameFieldEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const user: any = useSelector((state: RootState) => state.user.currentUser);

  const [name, setName] = useState(user?.name);
  const [phone, setPhone] = useState(user?.phone);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  const editUser: FormEventHandler = async (e) => {
    e.preventDefault();

    if (!name) {
      setNameFieldEmpty(true);
      return;
    } else {
      setNameFieldEmpty(false);
    }

    if (image) {
      setLoading(true);

      const fileName = new Date().getTime() + image.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          toast.error("Unable to upload image");
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const edit = async () => {
              try {
                const data = await api<ApiResponse<LoginResponse>>({
                  method: "PUT",
                  url: `/api/user/${user?._id}`,
                  data: { name, phone, picture: downloadURL },
                });

                setLoading(false);
                toast.success("Changes saved");
                dispatch(loadUser(data?.data));
              } catch (error) {
                setLoading(false);
                toast.error("Unable to save changes");
              }
            };

            edit();
          });
        }
      );
    } else {
      try {
        setLoading(true);

        const data = await api<ApiResponse<LoginResponse>>({
          method: "PUT",
          url: `/api/user/${user?._id}`,
          data: { name, phone },
        });

        setLoading(false);
        toast.success("Changes saved");
        dispatch(loadUser(data?.data));
      } catch (error) {
        setLoading(false);
        toast.error("Unable to save changes");
      }
    }
  };

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Account Edit" />
      <div className="content-wrap" style={{ paddingTop: "80px" }}>
        <div className="container pt-5 pb-7">
          <div className="row gy-6 gx-6">
            <div className="col-12 col-xl order-5">
              <div
                className="flq-account-content"
                data-sr
                data-sr-delay={300}
                data-sr-duration={1200}
                data-sr-distance={10}
              >
                <form
                  onSubmit={(e) => {
                    if (changesMade) {
                      editUser(e);
                    }
                  }}
                >
                  <div className="row gx-0 mb-5">
                    <div className="col-12">
                      <div className="row gy-1 gx-4 align-items-center">
                        <div className="col-12 col-md-4 col-xl-3 flq-color-title">
                          Avatar
                        </div>
                        <div className="col-12 col-sm">
                          {fileUploaded ? (
                            <div className="flq-account-avatar">
                              <div className="card flq-card-image">
                                <label htmlFor="avatar" className="card-image">
                                  <span className="flq-image flq-responsive flq-responsive-1x1">
                                    <img
                                      src={URL.createObjectURL(image)}
                                      alt="avatar"
                                    />
                                  </span>
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 5.00004H6C5.46957 5.00004 4.96086 5.21075 4.58579 5.58583C4.21071 5.9609 4 6.46961 4 7.00004V18C4 18.5305 4.21071 19.0392 4.58579 19.4143C4.96086 19.7893 5.46957 20 6 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4143C18.7893 19.0392 19 18.5305 19 18V13M17.586 3.58604C17.7705 3.39502 17.9912 3.24266 18.2352 3.13784C18.4792 3.03302 18.7416 2.97785 19.0072 2.97554C19.2728 2.97323 19.5361 3.02384 19.7819 3.1244C20.0277 3.22496 20.251 3.37346 20.4388 3.56125C20.6266 3.74904 20.7751 3.97234 20.8756 4.21813C20.9762 4.46392 21.0268 4.72728 21.0245 4.99284C21.0222 5.2584 20.967 5.52084 20.8622 5.76485C20.7574 6.00886 20.605 6.22955 20.414 6.41404L11.828 15H9V12.172L17.586 3.58604Z"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="avatar"
                                  onChange={(e: any) => {
                                    setFileUploaded(true);
                                    setImage(e.target.files[0]);
                                    setChangesMade(true);
                                  }}
                                />
                                <div className="card-body"></div>
                              </div>
                            </div>
                          ) : (
                            <div className="flq-account-avatar">
                              <div className="card flq-card-image">
                                <label htmlFor="avatar" className="card-image">
                                  {user?.picture ? (
                                    <span className="flq-image flq-responsive flq-responsive-1x1">
                                      <img src={user?.picture} alt="user" />
                                    </span>
                                  ) : (
                                    <span
                                      className="flq-image flq-responsive"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: "20px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      {getInitials(user?.name)}
                                    </span>
                                  )}
                                  <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11 5.00004H6C5.46957 5.00004 4.96086 5.21075 4.58579 5.58583C4.21071 5.9609 4 6.46961 4 7.00004V18C4 18.5305 4.21071 19.0392 4.58579 19.4143C4.96086 19.7893 5.46957 20 6 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4143C18.7893 19.0392 19 18.5305 19 18V13M17.586 3.58604C17.7705 3.39502 17.9912 3.24266 18.2352 3.13784C18.4792 3.03302 18.7416 2.97785 19.0072 2.97554C19.2728 2.97323 19.5361 3.02384 19.7819 3.1244C20.0277 3.22496 20.251 3.37346 20.4388 3.56125C20.6266 3.74904 20.7751 3.97234 20.8756 4.21813C20.9762 4.46392 21.0268 4.72728 21.0245 4.99284C21.0222 5.2584 20.967 5.52084 20.8622 5.76485C20.7574 6.00886 20.605 6.22955 20.414 6.41404L11.828 15H9V12.172L17.586 3.58604Z"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </label>
                                <input
                                  type="file"
                                  accept="image/*"
                                  id="avatar"
                                  onChange={(e: any) => {
                                    setFileUploaded(true);
                                    setImage(e.target.files[0]);
                                    setChangesMade(true);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gy-3 gx-0">
                    <div className="col-12">
                      <div className="row gy-1 gx-4 align-items-center">
                        <div className="col-12 col-md-4 col-xl-3 flq-color-title">
                          <label htmlFor="name">Full Name</label>
                        </div>
                        <div className="col-12 col-sm">
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameFieldEmpty(false);
                              setChangesMade(true);
                            }}
                          />
                          {nameFieldEmpty && (
                            <p
                              className="text-danger mt-1 text-center"
                              style={{ fontSize: "15px" }}
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row gy-1 gx-4 align-items-center">
                        <div className="col-12 col-md-4 col-xl-3 flq-color-title">
                          <label htmlFor="phone">Phone</label>
                        </div>
                        <div className="col-12 col-sm">
                          <input
                            name="phone"
                            className="form-control"
                            type="tel"
                            id="phone"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                              setChangesMade(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-5">
                      <div className="row g-3 justify-content-end">
                        <div className="col-12 col-sm-auto">
                          <button
                            className="btn btn-dark btn-block"
                            type="button"
                            onClick={() => {
                              setName(user?.name);
                              setPhone(user?.phone);
                              setImage(null);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-12 col-sm-auto">
                          {loading ? (
                            <button className="btn btn-block" disabled>
                              Saving...
                            </button>
                          ) : (
                            <button className="btn btn-block">Save</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-xl-auto">
              <div className="flq-sidebar flq-sidebar-lg">
                <div className="flq-account-navbar">
                  <nav>
                    <ul className="nav nav-dots flex-column gy-3">
                      <li className="nav-item">
                        <Link to="/account-profile" className="nav-link">
                          <span className="nav-link-name">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-favorites" className="nav-link">
                          <span className="nav-link-name">Favorites</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-questions" className="nav-link">
                          <span className="nav-link-name">
                            Questions &amp; Reviews
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link to="/account-edit" className="nav-link">
                          <span className="nav-link-name">Account Edit</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-subscription" className="nav-link">
                          <span className="nav-link-name">Subscription</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="#/" className="nav-link" onClick={logout}>
                          <span className="nav-link-name">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Account Edit" />
      <NavbarUser page="Account Edit" />
    </div>
  );
};

export default EditAccout;
