import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCzfSzLjeoxkF6YRJxTw15mbFKQOxs9b40",
  authDomain: "tlap-ab68c.firebaseapp.com",
  projectId: "tlap-ab68c",
  storageBucket: "tlap-ab68c.appspot.com",
  messagingSenderId: "815454472556",
  appId: "1:815454472556:web:fe5253a9f2613d25f30b49",
};

const app = initializeApp(firebaseConfig);

export default app;
