import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavbarIcons from "../../components/NavbarIcons";
import NavbarMobile from "../../components/NavbarMobile";
import NavbarTop from "../../components/NavbarTop";
import NavbarUser from "../../components/NavbarUser";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/redux/store";
import { logoutUser } from "../../store/redux/userRedux";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import { useState } from "react";
import Preloader from "../../components/Preloader";
import { toast } from "react-toastify";
import { api } from "../../utils/apiClient";

const Subscription = () => {
  const [cancelSubModalOpen, setCancelSubModalOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const user: any = useSelector((state: RootState) => state.user.currentUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  const cancelPaystackSubscription = async () => {
    setPageLoading(true);

    try {
      await api({
        method: "POST",
        url: `/api/paystack/cancel/${user?._id}`,
        data: { email: user?.email },
      });

      setPageLoading(false);
      setCancelSubModalOpen(false);
      navigate(0);
    } catch (error) {
      setPageLoading(false);
      toast.error("Unable to cancel subscription");
    }
  };

  const cancelPaypalSubscription = async () => {
    setPageLoading(true);

    try {
      await api({
        method: "POST",
        url: `/api/paypal/cancel/${user?._id}`,
        data: { subscriptionId: user?.subscriptionId, email: user?.email },
      });

      setPageLoading(false);
      setCancelSubModalOpen(false);
      navigate(0);
    } catch (error) {
      setPageLoading(false);
      toast.error("Unable to cancel subscription");
    }
  };

  return (
    <div className="flq-navbar-icons-existence">
      {pageLoading && <Preloader />}
      <NavbarTop page="Subscription" />
      <div className="content-wrap" style={{ paddingTop: "80px" }}>
        <div className="container pt-5 pb-7">
          <div className="row gy-6 gx-6">
            <div className="col-12 col-xl order-5">
              <div className="flq-account-content">
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="flq-color-meta">Your Plan</td>
                      <td>{capitalizeFirstLetter(user?.subscription)}</td>
                    </tr>
                    {user?.subscription !== "free" &&
                      user?.subscriptionType && (
                        <tr>
                          <td className="flq-color-meta">Interval</td>
                          <td>
                            {capitalizeFirstLetter(user?.subscriptionType)}
                          </td>
                        </tr>
                      )}
                    {user?.subscription !== "free" &&
                      user?.subscriptionStatus && (
                        <tr>
                          <td className="flq-color-meta">Status</td>
                          <td>
                            {capitalizeFirstLetter(user?.subscriptionStatus)}
                          </td>
                        </tr>
                      )}
                    {user?.subscription !== "free" && (
                      <tr>
                        <td className="flq-color-meta">Subscribed On:</td>
                        <td>
                          {new Date(user?.subscribedOn).toLocaleDateString()}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="col-12 mt-5">
                  <div className="row g-3 justify-content-end">
                    {user?.subscription !== "free" &&
                      user?.subscriptionStatus !== "cancelled" &&
                      (user?.subscriptionMethod === "paystack" ||
                        user?.subscriptionMethod === "paypal") && (
                        <div className="col-12 col-sm-auto">
                          <button
                            onClick={() => setCancelSubModalOpen(true)}
                            className="btn btn-dark btn-block"
                          >
                            Cancel Subscription
                          </button>
                        </div>
                      )}
                    <div className="col-12 col-sm-auto">
                      {user?.subscription === "free" ? (
                        <Link to="/plans" className="btn btn-block">
                          Subscribe
                        </Link>
                      ) : (
                        <Link to="/plans" className="btn btn-block">
                          Change Plan
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-auto">
              <div className="flq-sidebar flq-sidebar-lg">
                <div className="flq-account-navbar">
                  <nav>
                    <ul className="nav nav-dots flex-column gy-3">
                      <li className="nav-item">
                        <Link to="/account-profile" className="nav-link">
                          <span className="nav-link-name">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-favorites" className="nav-link">
                          <span className="nav-link-name">Favorites</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-questions" className="nav-link">
                          <span className="nav-link-name">
                            Questions &amp; Reviews
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-edit" className="nav-link">
                          <span className="nav-link-name">Account Edit</span>
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link to="/account-subscription" className="nav-link">
                          <span className="nav-link-name">Subscription</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="#/" className="nav-link" onClick={logout}>
                          <span className="nav-link-name">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Subscription" />
      <NavbarUser page="Subscription" />

      {cancelSubModalOpen && (
        <div
          className="flq-account-content modal"
          data-sr
          data-sr-delay={300}
          data-sr-duration={1200}
          data-sr-distance={10}
          style={{
            visibility: "visible",
            opacity: 1,
            transform:
              "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
            transition:
              "opacity 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s, transform 1.2s cubic-bezier(0.5, 0, 0, 1) 0.3s",
          }}
        >
          <div>
            <h2 className="h5 mb-2 text-center" style={{ paddingTop: "120px" }}>
              Are you sure you want to cancel your subscription?
            </h2>
            <p className="text-center">
              You would still have access to courses for the duration of your
              subscription.
            </p>

            <div className="col-12 mt-5">
              <div className="row g-3 justify-content-center">
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => {
                      if (user?.subscriptionMethod === "paypal") {
                        cancelPaypalSubscription();
                      } else if (user?.subscriptionMethod === "paystack") {
                        cancelPaystackSubscription();
                      }
                    }}
                  >
                    Yes, Continue
                  </button>
                </div>
                <div className="col-12 col-sm-auto">
                  <button
                    className="btn btn-dark btn-block"
                    onClick={() => setCancelSubModalOpen(false)}
                  >
                    No, Exit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
