import { formatDistanceToNow } from "date-fns";

export const formatDistanceToNowShort = (date: Date): string => {
  const distance = formatDistanceToNow(date, { addSuffix: true });
  return distance
    .replace(/\sseconds/, "s")
    .replace(/\ssecond/, "s")
    .replace(/\sminutes/, "m")
    .replace(/\sminute/, "m")
    .replace(/\shours/, "h")
    .replace(/\shour/, "h")
    .replace(/\sdays/, "d")
    .replace(/\sday/, "d")
    .replace(/\sweeks/, "w")
    .replace(/\sweek/, "w")
    .replace(/\smonths/, "mo")
    .replace(/\smonth/, "mo")
    .replace(/\syears/, "y")
    .replace(/\syear/, "y");
};
