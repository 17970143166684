import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Footer from "../../components/Footer";
import NavbarIcons from "../../components/NavbarIcons";
import NavbarMobile from "../../components/NavbarMobile";
import NavbarTop from "../../components/NavbarTop";
import NavbarUser from "../../components/NavbarUser";
import { getUserFavorites, removeFavorite } from "../../utils/queries";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/redux/store";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../store/redux/userRedux";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import { toast } from "react-toastify";
import { useState } from "react";

const Favorites = () => {
  const [id, setId] = useState("");
  const [videoId, setVideoId] = useState("");

  const user: any = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: favoritesData } = useQuery({
    queryKey: ["user_favorites"],
    queryFn: () => getUserFavorites(user._id),
    enabled: user !== null,
  });

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  const { mutate: removeVideoFromFavorites } = useMutation({
    mutationFn: () => {
      return removeFavorite({
        id: id,
        userId: user?._id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user_favorites"],
      });
      queryClient.invalidateQueries({
        queryKey: ["videos", { videoId }],
      });
    },
    onError: () => {
      toast.error("Unable to remove from favorites.");
    },
  });

  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Favorites" />
      <div className="content-wrap" style={{ paddingTop: "80px" }}>
        <div className="container pt-5 pb-7">
          <div className="row gy-6 gx-6">
            <div
              className="col-12 col-xl order-5"
              style={{ maxHeight: "1000px", overflow: "scroll" }}
            >
              {favoritesData && (
                <div
                  className="row gx-0 gy-3"
                  data-sr="account-favorites"
                  data-sr-delay={300}
                  data-sr-interval={100}
                  data-sr-duration={1000}
                  data-sr-distance={10}
                >
                  {favoritesData.length > 0 ? (
                    <>
                      {favoritesData.map((favorite) => (
                        <div
                          className="col-12"
                          data-sr-item="account-favorites"
                          key={favorite._id}
                        >
                          <div className="flq-account-favorite">
                            <div className="flq-media">
                              <Link
                                to={`/video/${favorite.video.videoId}`}
                                className="flq-media-image"
                              >
                                <span className="flq-image">
                                  <img
                                    src={favorite.video.poster}
                                    alt="poster"
                                    style={{
                                      objectFit: "contain",
                                    }}
                                  />
                                </span>
                              </Link>
                              <div className="flq-media-meta">
                                <h5 className="flq-media-title">
                                  <Link to={`/video/${favorite.video.videoId}`}>
                                    {favorite.video.title}
                                  </Link>
                                </h5>
                                <div className="flq-meta">
                                  <ul>
                                    <li>
                                      {capitalizeFirstLetter(
                                        favorite.video.category
                                      )}
                                    </li>
                                    <li>{favorite.video.duration}m</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <a
                              href="#/"
                              className="btn btn-dark btn-xs btn-square btn-icon-sm align-self-center active"
                              data-bs-toggle="button"
                              onClick={() => {
                                setId(favorite._id);
                                setVideoId(favorite.video.videoId);
                                removeVideoFromFavorites();
                              }}
                            >
                              <span className="btn-icon">
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <svg
                                  width={20}
                                  height={20}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.3671 3.84166C16.9415 3.41583 16.4361 3.07803 15.8799 2.84757C15.3237 2.6171 14.7275 2.49847 14.1254 2.49847C13.5234 2.49847 12.9272 2.6171 12.371 2.84757C11.8147 3.07803 11.3094 3.41583 10.8838 3.84166L10.0004 4.725L9.11709 3.84166C8.25735 2.98192 7.09128 2.49892 5.87542 2.49892C4.65956 2.49892 3.4935 2.98192 2.63376 3.84166C1.77401 4.70141 1.29102 5.86747 1.29102 7.08333C1.29102 8.29919 1.77401 9.46525 2.63376 10.325L3.51709 11.2083L10.0004 17.6917L16.4838 11.2083L17.3671 10.325C17.7929 9.89937 18.1307 9.39401 18.3612 8.83779C18.5917 8.28158 18.7103 7.6854 18.7103 7.08333C18.7103 6.48126 18.5917 5.88508 18.3612 5.32887C18.1307 4.77265 17.7929 4.26729 17.3671 3.84166V3.84166Z"
                                    fill="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </a>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="flq-account-favorite">
                      <p style={{ margin: "auto" }}>No favorites yet</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="col-12 col-xl-auto">
              <div className="flq-sidebar flq-sidebar-lg">
                <div className="flq-account-navbar">
                  <nav>
                    <ul className="nav nav-dots flex-column gy-3">
                      <li className="nav-item">
                        <Link to="/account-profile" className="nav-link">
                          <span className="nav-link-name">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item active">
                        <Link to="/account-favorites" className="nav-link">
                          <span className="nav-link-name">Favorites</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-questions" className="nav-link">
                          <span className="nav-link-name">
                            Questions &amp; Reviews
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-edit" className="nav-link">
                          <span className="nav-link-name">Account Edit</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account-subscription" className="nav-link">
                          <span className="nav-link-name">Subscription</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a href="#/" className="nav-link" onClick={logout}>
                          <span className="nav-link-name">Logout</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Favorites" />
      <NavbarUser page="Favorites" />
    </div>
  );
};

export default Favorites;
