import NavbarTop from "../components/NavbarTop";
import NavbarIcons from "../components/NavbarIcons";
import NavbarMobile from "../components/NavbarMobile";
import NavbarUser from "../components/NavbarUser";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const Faq = () => {
  return (
    <div className="flq-navbar-icons-existence">
      <NavbarTop page="Faq" />
      <Banner page="Faq" />
      <div className="content-wrap">
        <div className="py-7">
          <div className="container">
            <div
              className="accordion"
              data-sr="accordion"
              data-sr-interval={100}
              data-sr-duration={1000}
              data-sr-distance={10}
            >
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-1"
                    aria-expanded="true"
                    aria-controls="faq-1"
                  >
                    Will there be changes to courses?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-1" className="accordion-collapse collapse  show">
                  <div className="accordion-body">
                    <p>
                      Yes, every course section would be updated very often.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-2"
                    aria-expanded="false"
                    aria-controls="faq-2"
                  >
                    How would I know when a new course is uploaded?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-2" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      You would be notified via email whenever we upload new
                      courses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-3"
                    aria-expanded="false"
                    aria-controls="faq-3"
                  >
                    Video not playing on my device/browser
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-3" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      Certain security measures have been put in place to
                      protect our content. Our videos are compatible with all
                      major browsers/devices. In some cases you may need to
                      update your browser or device OS.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-4"
                    aria-expanded="false"
                    aria-controls="faq-4"
                  >
                    Are my payment details safe?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-4" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      Yes, your payment details are secure as we do not save any
                      user’s payment information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-5"
                    aria-expanded="false"
                    aria-controls="faq-5"
                  >
                    Can I change my subscription package at any time?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-5" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      Yes you can upgrade or downgrade your plan on the 'Our
                      Plans' page or 'Subscription' tab on your profile page,
                      but note that we would cancel your current subscription
                      and charge you for your new plan right away and on
                      subsequent renewals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-6"
                    aria-expanded="false"
                    aria-controls="faq-6"
                  >
                    What payment methods are supported?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-6" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      We support card payments and Paypal. However if you
                      encounter problems trying to make payments, please reach
                      out to us.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-7"
                    aria-expanded="false"
                    aria-controls="faq-7"
                  >
                    How can I cancel my subscription plan?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-7" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      You can cancel your subscription plan in the
                      'Subscription' tab on your profile page or the 'Cancel
                      Subscription' button on email reminders. Why would you
                      want to do that though?
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-8"
                    aria-expanded="false"
                    aria-controls="faq-8"
                  >
                    Can I get a refund after I subscribe?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-8" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      We believe that these courses are valuable and will be
                      beneficial should you choose to subscribe to a plan so we
                      don't offer refunds at this time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item" data-sr-item="accordion">
                <h2 className="accordion-header h5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-9"
                    aria-expanded="false"
                    aria-controls="faq-9"
                  >
                    Can I change the speed of the videos?
                    <span className="accordion-button-icon" />
                  </button>
                </h2>
                <div id="faq-9" className="accordion-collapse collapse">
                  <div className="accordion-body">
                    <p>
                      Yes you can set your playback speed on every video so you
                      can slow down or speed up as you desire.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <NavbarIcons />
      <NavbarMobile page="Faq" />
      <NavbarUser page="Faq" />
    </div>
  );
};

export default Faq;
